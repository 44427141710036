import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { TextField } from '@mui/material';

export const SelectStyled = styled(TextField)`
  ${({ theme }) => css`
    width: 250px;

    .MuiOutlinedInput-root {
      height: 38px;
      font-size: 15px;
      font-weight: 500;
      border-radius: 10px;
      box-shadow: ${theme.shadowCA(3)};
    }

    .MuiSelect-select {
      padding-left: 0;
    }

    .MuiInputAdornment-root {
      .MuiSvgIcon-root {
        height: 18px;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-radius: 10px;
    }
  `}
`;
