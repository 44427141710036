import React, { useEffect, useRef, useState } from 'react';
import { Localization } from 'connex-cds';
import { Button } from '@mui/material';

import { useUserContext } from '../../../../providers/UserProvider';
import DialogContainer from '../../../../commons/custom-dialog/dialog-container/DialogContainer';
import { DialogActions, DialogContent, DialogTitle } from '../../../../commons';
import EditImageComponent from './components/edit-image-view';
import LoadImageDialog from './components/load-image-view';
import CurrentImageActions from './components/current-image-view';
import {
  useUpdateImageProfile,
  useProfile,
  useCreateImageProfile,
  useDeleteImageProfile,
} from '../../../../queries/user-profile';
import CropOriginalIcon from '@mui/icons-material/CropOriginal';
import { useToast } from '../../../../components/Toast';
import sendMessage from '../../../../util/sendMessage';

const UPLOAD_PROFILE_IMAGE_DISPLAY = {
  CURRENT: 'curent_image',
  PICK: 'pick_image',
  EDIT: 'edit_image',
};

const UploadImageProfileDialog = ({ open, onClose, alt, avatarInfo }) => {
  const { user, setUser } = useUserContext();
  const { data: profileData } = useProfile();
  const [screen, setScreen] = useState(UPLOAD_PROFILE_IMAGE_DISPLAY.CURRENT);
  const notify = useToast();
  const translateMessage = Localization.useTranslateMessage();

  const [newImage, setNewImage] = useState(null);
  const fileInputRef = useRef();
  const { mutateAsync: uploadImageProfile, isLoading: isLoadingCreateImg } = useCreateImageProfile();
  const { mutateAsync: deleteImageProfile, isLoading: isLoadingDeleteImg } = useDeleteImageProfile();
  const { mutateAsync: updateImageProfile, isLoading: isLoadingUpdateImg } = useUpdateImageProfile();

  const editImageComponentRef = useRef();

  const handleOnClose = () => {
    onClose();
  };

  useEffect(() => {
    if (newImage) {
      setScreen(UPLOAD_PROFILE_IMAGE_DISPLAY.EDIT);
    }
  }, [newImage]);

  const handleAddClick = () => {
    fileInputRef.current.click();
  };

  const handleUploadImage = async ({ image, width, height }) => {
    const changeHandler = profileData?.profileImage?.data ? updateImageProfile : uploadImageProfile;
    try {
      changeHandler({ data: image, width, height })
        .then(() => {
          setUser({ ...user, profileImage: image });
          notify.success(translateMessage('profileImage_update_sucess_msg'));
          setNewImage(null);
          onClose();
          sendMessage({ type: 'save-profile-clicked' });
        })
        .catch(() => {
          notify.error(translateMessage('profileImage_update_error_msg'));
          setScreen(UPLOAD_PROFILE_IMAGE_DISPLAY.CURRENT);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnDeleteImage = async () => {
    await deleteImageProfile()
      .then(() => {
        setUser({ ...user, profileImage: null });
        notify.success(translateMessage('profileImage_delete_sucess_msg'));
        sendMessage({ type: 'save-profile-clicked' });
      })
      .catch(() => {
        notify.error(translateMessage('profileImage_delete_error_msg'));
      })
      .finally(() => {
        handleOnClose();
        setNewImage(null);
      });
  };

  const handleOnBack = () => {
    switch (screen) {
      case UPLOAD_PROFILE_IMAGE_DISPLAY.CURRENT:
        handleOnClose();
        break;
      case UPLOAD_PROFILE_IMAGE_DISPLAY.PICK:
        setScreen(UPLOAD_PROFILE_IMAGE_DISPLAY.CURRENT);
        break;
      case UPLOAD_PROFILE_IMAGE_DISPLAY.EDIT:
        setNewImage(null);
        setScreen(UPLOAD_PROFILE_IMAGE_DISPLAY.PICK);
        break;

      default:
        break;
    }
    setScreen(UPLOAD_PROFILE_IMAGE_DISPLAY.CURRENT);
  };

  const renderView = screen => {
    switch (screen) {
      case UPLOAD_PROFILE_IMAGE_DISPLAY.CURRENT:
        return (
          <CurrentImageActions
            initialImage={profileData?.profileImage?.data}
            onCreate={() => setScreen(UPLOAD_PROFILE_IMAGE_DISPLAY.PICK)}
            onDelete={handleOnDeleteImage}
            isLoadingDeleteImg={isLoadingDeleteImg}
            alt={alt}
            avatarInfo={avatarInfo}
          />
        );
      case UPLOAD_PROFILE_IMAGE_DISPLAY.PICK:
        return (
          <LoadImageDialog handleAddClick={handleAddClick} setNewImage={setNewImage} fileInputRef={fileInputRef} />
        );

      case UPLOAD_PROFILE_IMAGE_DISPLAY.EDIT:
        return (
          <EditImageComponent
            isLoading={isLoadingUpdateImg || isLoadingCreateImg}
            ref={editImageComponentRef}
            onComplete={handleUploadImage}
            url={newImage}
          />
        );

      default:
        break;
    }
  };

  return (
    <DialogContainer open={open} maxWidth="md" fullWidth onClose={handleOnClose}>
      <DialogTitle onClose={handleOnClose}>
        <CropOriginalIcon fontSize="inherit" />
        <Localization.Translate stringId="profileImage_title" />
      </DialogTitle>
      <DialogContent>{renderView(screen)}</DialogContent>
      <DialogActions
        lefSide={
          <Button disabled={isLoadingUpdateImg || isLoadingCreateImg} onClick={handleOnBack}>
            <Localization.Translate stringId="back" />
          </Button>
        }
        rightSide={
          screen === UPLOAD_PROFILE_IMAGE_DISPLAY.EDIT && (
            <Button
              variant="contained"
              disabled={isLoadingUpdateImg || isLoadingCreateImg}
              onClick={() => editImageComponentRef?.current?.showCroppedImage()}
            >
              <Localization.Translate stringId="save" />
            </Button>
          )
        }
      />
    </DialogContainer>
  );
};

export default UploadImageProfileDialog;
