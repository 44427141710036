import React from 'react';
import { Localization } from 'connex-cds';
import CustomButton from '../../../../commons/custom-button';
import { Styled } from './styles';
import { CustomIcon, Heading1, Paragraph1 } from '../../../../commons';

const CookiesConfirmation = ({ onLearnMoreClick, onRefuseAllClick, onAcceptAllClick }) => {
  return (
    <Styled className="default-landing">
      <Heading1 className="landing-title">
        <Localization.Translate stringId="cookies_main_title" />
      </Heading1>
      <Paragraph1 className="landing-description">
        <Localization.Translate stringId="cookies_main_description" />
      </Paragraph1>
      <div className="action-buttons">
        <CustomButton variant="outlined" onClick={onLearnMoreClick}>
          <Localization.Translate stringId="cookies_learn_more_btn" />
        </CustomButton>
        <CustomButton
          customcolor="red"
          startIcon={<CustomIcon iconName="doNotDisturbAltOutlined" />}
          onClick={onRefuseAllClick}
        >
          <Localization.Translate stringId="cookies_refuse_all_btn" />
        </CustomButton>
        <CustomButton
          customcolor="blue"
          startIcon={<CustomIcon iconName="approvalOutlined" />}
          onClick={onAcceptAllClick}
        >
          <Localization.Translate stringId="cookies_accept_all_btn" />
        </CustomButton>
      </div>
    </Styled>
  );
};

export default CookiesConfirmation;
