import common from '../common';
import errors from './errors';
import themes from './theme';

export default {
  ...common,
  'save-password': 'Save Password',
  requestReset: 'Request Reset',
  'forgot-password-instructions':
    'A verification code has been sent to you.  Enter it below and select a new password.',
  'password-requirements':
    'Your password must be at least 8 characters, contain at least 1 uppercase, 1 lowercase, 1 number and 1  of the following special characters ! @ # $ % ^',

  success: 'Success',
  'password-reset-success': 'Your password was reset successfully.',
  selectCompany: 'Please select a company',
  button: 'Button Text',
  save: 'Save',
  text: 'ISO 3166-1 (Codes for the representation of names of countries and their subdivisions – Part 1: Country codes) is a standard defining codes for the names of countries, dependent territories, and special areas of geographical interest. It is the first part of the ISO 3166 standard published by the International Organization for Standardization.',

  username: 'Username',
  password: 'Password',
  login: 'Login',
  ssoLogin: 'Secure login with SSO',
  required: 'Required',

  light: 'Light',
  dark: 'Dark',

  rememberMe: 'Remember Me',

  eula: 'EULA',
  copyright: '© {year} - Command Alkon, Inc.',
  privacyPolicy: 'Privacy Policy',

  customer: 'Customer',
  project: 'Project',

  isBillable: 'Billable',
  dispatchDateTime: 'Date',
  orderId: 'Order',
  ticketId: 'Ticket',
  location: 'Location',
  vehicleTypeName: 'Vehicle',
  product: 'Product',
  salesTax: 'Sales Tax',
  totalPrice: 'Total',
  extendedPrice: 'Ext',
  destination: 'Destination',
  productId: 'Product ID',
  statusCode: 'Status',
  vehicleTypeId: 'Vehicle Type ID',
  locationId: 'Location ID',
  customerId: 'Customer ID',
  projectId: 'Project ID',
  dispatchTime: 'Time',
  'forgot-mail': 'Forgot email',
  showAll: 'Show All',
  hideAll: 'Hide All',
  undoChanges: 'Undo Changes',
  resetColumns: 'Reset Columns',

  displayRowCount: `Displaying {count, plural, 
    =0 {0 rows} 
    one {# row} 
    other {# rows} 
  }`,

  columnFilters: 'Column Filters',

  visible: 'Visible',
  hidden: 'Hidden',

  topSection: 'Top Section',

  itemDetail: 'Item Detail',

  quantity: 'Quantity',

  unloadingMethod: 'Unloading Method',

  time: 'Time',
  eventStatus: 'Status',

  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    one {1 m\u00B3}
    other {# m\u00B3}
  }`,

  LTR: `{value, plural,
    =0 {0 Liters}
    one {1 Liter}
    other {# Liters}
  }`,

  TO: `{value, plural,
    =0 {0 Tons}
    one {1 Ton}
    other {# Tons}
  }`,

  ITEM: `{value, plural,
    =0 {0 Items}
    one {1 Item}
    other {# Items}
  }`,

  YDQ_short: `{value, plural,
    =0 {0 CY}
    one {1 CY}
    other {# CY}
  }`,

  YDQ: `{value, plural,
    =0 {0 Cubic Yards}
    one {1 Cubic Yard}
    other {# Cubic Yards}
  }`,

  mixIdLabel: 'Mix ID/Description',
  orderedQtyLabel: 'Ordered QTY',
  loadedQtyLabel: 'Loaded QTY',

  END_UNLOADING: 'Finish Unload',
  IN_YARD: 'In Yard',
  TO_JOB: 'Left Plant',
  LEAVE_JOB: 'Leave Job',
  CREATED: 'Ticketed',
  UNLOADING: 'Start Unload',
  ARRIVE_JOB: 'Arrive Site',

  plantName: 'Plant Name',
  plantAddress: 'Plant Address',
  loadingScheduleStart: 'Loading Scheduled Start Date and Time',

  deliveryAddress: 'Delivery Address',
  phoneNumber: 'Phone Number',
  unloadingScheduleStart: 'Unloading Scheduled Start Date and Time',
  startToFinishUnloadingTime: 'Unloading Start - Finish Date and Time',

  deliveryInformation: 'Delivery Information',
  next: 'Next',

  material: 'Material',
  loadingPoint: 'Loading Point',
  unloadingPoint: 'Unloading Point',

  camera: 'Camera',
  comment: 'Comment',

  sapDeliveryNumber: 'SAP Delivery number (BCC Number)',
  salesOrderNumber: 'Sales order number and item line',
  wcDateTime: 'Date and time W/C contact',
  po: 'PO Number',
  orderedByName: 'Order placed by name',
  orderedByPhone: 'Order placed by phone number',
  resourceId: 'Resource ID',
  totalLoadedQty: 'Total loaded qty including this load',
  spacing: 'Load space',
  deliveryFlow: 'Delivery flow',
  timeOnSite: 'Total on site time/ordered unloading time',
  salesText: 'Sales text (material ID)',
  strengthClass: 'Strength class',
  environmentExposure: 'Environment exposure',
  metExposureClasses: 'Met exposure classes',
  dmax: 'Dmax',
  standardCert: 'Standard (certification)',
  workabilityTarget: 'Customer workability target',
  stoneQuality: 'Stone Quality',
  sandQuality: 'Sand Quality',
  targetWC: 'Target W/C ratio from recipe',
  controlClass: 'Control Class',
  chlorideClass: 'Chloride Class',
  typeAndStrength1: 'Type of cement and strengthclass 1',
  typeAndStrength2: 'Type of cement and strengthclass 2',
  typeOfAddition: 'Type of addition',
  typeOfAdmixture: 'Type of admixture',
  orderedTemp: 'Ordered Temperature',
  fibers1: 'Type and content of fibers 1',
  fibers2: 'Type and content of fibers 2',
  calculatedMaturityTime: 'Calculated maturity time from W/C contact time',

  generalDelivery: 'General Delivery Information',
  materialInfo: 'Material Information',

  cardTitle: 'Card Title',
  cardSubtitle: 'Card Subtitle',
  cardBody: 'Card Body',

  alertTitle: 'The Old Man and the Sea',
  alertMessage:
    'He was an old man who fished alone in a skiff in the Gulf Stream and he had gone eighty-four days now without taking a fish.',

  water: 'Water',
  materialService: 'Material / Service',
  returnedMaterial: 'Returned Material',

  waterAdded: 'Water Added',
  concreteOnTruck: 'Concrete on Truck',
  reason: 'Reason',
  customerAddedWater: 'Customer Added Water',
  addWater: 'Add Water',
  addedBy: 'Added By',

  addMaterialService: 'Add Material/Service',
  customerAddedProduct: 'Customer Added Product',

  addReturnedMaterial: 'Add Returned Material',
  leftover: 'Leftover',

  submitAcceptance: 'Submit Acceptance',
  customerAcceptance: 'Customer Acceptance',

  materialSummary: 'Material Summary',
  mix: 'Mix',
  ordered: 'Ordered',
  loaded: 'Loaded',

  driverComment: 'Driver Comment',
  customerComment: 'Customer Comment',
  editComment: 'Edit Comment',
  addComment: 'Add Comment',
  additionalInfo: 'Additional Info',
  onBoard: 'On Board',
  driver: 'Driver',
  commentInstruction: 'Comment Instruction',
  signatureInstruction: 'Signature Instruction',
  customerSignature: 'Customer Signature',

  general: 'General',
  tabTwoStringId: 'Tab Two',
  tabThreeStringId: 'Tab Three',

  status: 'Status',
  updateStatusTime: 'Update Status Time',
  delete: 'Delete',
  cancel: 'Cancel',
  back: 'Back',
  add: 'Add',

  id: 'ID',
  name: 'Name',
  invoiceable: 'Invoiceable',
  provideSignature: 'Provide Signature',
  ticket: 'Ticket',
  details: 'Details',
  qrCode: 'QR',

  driverAcceptanceReason: 'Reason',
  driverAcceptance: 'Driver Acceptance',

  'payment-method': 'Payment Methods',
  'add-payment-method': 'Add Payment Method',

  'cancel-reason-code': 'Cancel Reasons',
  'add-cancel-reason-code': 'Add Reason',

  companies: 'Companies',
  profiles: 'Profiles',
  logout: 'Logout',

  online: 'Online',
  offline: 'Offline',

  mt: 'Mobile Ticket',
  admin: 'Platform Admin',

  profileEditor: 'Profile Editor',

  firstname: 'First Name',
  lastname: 'Last Name',
  email: 'Email Address',
  mobilePhone: 'Mobile Phone',

  currentPassword: 'Current Password',
  oldPassword: 'Current Password',
  newPassword: 'New Password',
  newPasswordVerify: 'New Password (Verify)',
  passwordsCantMatch: 'New password cannot be the same as current password!',
  passwordsDontMatch: "Passwords don't match!",
  passwordRequirements:
    'New password must:\n' +
    '\n' +
    'Be between 8 and 99 characters long.\n' +
    'Include 1 or more numeric characters.\n' +
    'Include 1 or more lower case letters.\n' +
    'Include 1 or more upper case letters.\n' +
    'Include 1 or more of the following special characters ! @ # $ % ^',

  usernameInvalid: 'Invalid Username',
  passwordMinCharacters: 'Password must contain 8 or more characters.',
  passwordMaxCharacters: 'Password cannot contain more than 99 characters.',
  passwordLowerCase: 'Password must include 1 or more lower case letter.',
  passwordUpperCase: 'Password must include 1 or more upper case letter.',
  passwordNumber: 'Password must include 1 or more numeric character.',
  passwordSpecialCharacter: 'Password must include 1 or more of the following special characters ! @ # $ % ^',

  forgotPassword: 'Forgot Password',
  forgotPasswordInstructions: 'Instructions',
  code: 'Verification Code',

  changePassword: 'Change Password',
  'change-password-success': 'Your password was successfully changed.',
  'change-password-error':
    'Your password could not be changed.  Please make sure your current password is correct and try again. ',
  'forgetMe-success': 'You have been forgotten.',
  'forgetMe-error': 'An error has occurred.  Please make sure your password is correct and try again.',
  'forget-me-warning':
    '<p>This action will remove all your personally identifiable information from our systems after which, you will be logged out and unable to log back in. </p>  If you wish to proceed, please enter your password below.',
  apps: 'Apps',
  settings: 'Profile',
  lastName: 'Last Name',
  firstName: 'First Name',
  forgetMe: 'Forget Me',

  manageProfile_title: 'Manage Profile',
  manageProfile_general: 'General',
  manageProfile_credentials: 'Credentials',
  manageProfile_preferences: 'Preferences',
  manageProfile_notifications: 'Notifications',
  manageProfile_general_firstName: 'First Name',
  manageProfile_general_lastName: 'Last Name',
  manageProfile_general_phone: 'Mobile Phone',
  manageProfile_general_email: 'Email Address',
  manageProfile_general_phoneError: 'Invalid phone.',
  manageProfile_general_emailError: 'Invalid email format.',
  profileImage_title: 'Profile Image',
  profileImage_description:
    'A picture helps people recognize you and lets you know when you’re signed in to your account',
  profileImage_change_btn: 'Change',
  profileImage_delete_btn: 'Remove',
  profileImage_drag_photo_description: 'Drag photo here',
  profileImage_drag_photo_or: '- or -',
  profileImage_upload_from_computer_btn: 'Upload from computer',
  profileImage_rotate_btn: 'Rotate',
  profileImage_file_size_error: 'File is too large. Please select a file smaller than 5MB.',
  profileImage_update_sucess_msg: 'Profile image updated successfully',
  profileImage_update_error_msg: 'Error updating profile image',
  profileImage_delete_sucess_msg: 'Profile image deleted successfully',
  profileImage_delete_error_msg: 'Error deleting profile image',

  manageProfile_credentials_oldPassword: 'Current Password',
  manageProfile_credentials_newPassword: 'New Password',
  manageProfile_credentials_confirmNewPassword: 'Confirm New Password',
  manageProfile_credentials_description: 'New password must:',
  manageProfile_credentials_reqLength: 'Be between 8 and 99 characters long.',
  manageProfile_credentials_reqNumber: 'Include 1 or more numeric characters.',
  manageProfile_credentials_reqLowerCase: 'Include 1 or more lower case letters.',
  manageProfile_credentials_reqUpperCase: 'Include 1 or more upper case letters.',
  manageProfile_credentials_reqSpecialCharacter:
    'Include 1 or more special characters from the following set: ^ $ * . [] () ? - " ! @ # % & /  , > ; | _ ~ `',
  manageProfile_credentials_wrongOldPassword: 'Wrong password. Please, try again.',

  manageProfile_cancel: 'Cancel',
  manageProfile_save: 'Save',
  manageProfile_forget: 'Forget',

  forgetMe_title: 'Forget this account?',
  forgetMe_description: 'This will remove your credentials from the login prefill',
  login_header: 'Login with your credentials below',

  cookies_main_title: 'Cookie policy',
  cookies_main_description:
    'We use cookies to improve your experience. By clicking “Accept all,” you agree to the use of all cookies on your device. You can manage your cookie preferences by clicking “Learn more.”',
  cookies_learn_more_btn: 'Learn more',
  cookies_refuse_all_btn: 'Refuse all',
  cookies_accept_all_btn: 'Accept all',
  cookies_back_btn: 'Go back',

  cookies_details_title: 'Cookie preferences',
  cookies_details_notifications:
    '<strong>Critical notifications option</strong>: Critical notifications, online help, and documentation permit us to deliver users with essential alerts, provide online assistance, and share news and updates. This provides users with in-app notifications, service interruptions, and access to new feature announcements.',
  cookies_details_technical_support:
    '<strong>Technical support option</strong>: Technical support permits us to track and monitor the features you access. This assists us in proactively identifying and resolving issues, often before they’re even reported. ',

  cookies_confirmation_msj: 'Cookie policy accepted',

  cookies_reject_description:
    'Sorry, you will not be able to use the app without the required cookies. You can manage your cookie preferences by clicking “Learn more.”',

  defaultLanding_header: 'Choose an app to begin',
  defaultLanding_header_noLicenses: 'You currently do not have access to any Command Alkon software products',
  defaultLanding_description_noLicenses:
    'Please contact your site administrator if you believe there has been an error.',
  enforceEntity_selectCompany: 'Please select a company',
  enforceEntity_noMatchCompany:
    'No companies match with match your search criteria. Please refine your search, or contact your site administrator if you believe there has been an error.',

  search: 'Search',
  noResultsFound: 'No results found',

  accountRecovery: 'Account Recovery',
  accountRecovery_content:
    'Forgot your password? Enter the email address assigned to your account in the space provided, and then select <strong>Send Code</strong>. You should receive an email soon that includes a code to help you reset your password.',
  accountRecovery_usernameInvalid: 'Please enter a valid email address.',
  accountRecovery_sendCode: 'Send code',
  accountRecovery_backToLogin: 'Back to login',
  accountRecovery_verification: 'Verification',
  accountRecovery_verification_content:
    "To help keep your account safe, Command Alkon wants to make sure it's really you. Please enter the six-digit verification code sent to your email to verify your account.",
  accountRecovery_verification_code: 'Enter the code',
  accountRecovery_code_sent_message: '{icon} Verification code sent to email',
  accountRecovery_errorCode: 'Invalid verification code. Please check your email and try again.',
  accountRecovery_createNewPassword: 'Create new password',
  accountRecovery_password: 'Password',
  accountRecovery_errorPassword: 'Invalid password format. Please make sure your password meets the required criteria.',
  accountRecovery_passwordHint:
    'Use 8-99 characters and include at least one number, capital letter, lowercase letter and one special character from the following set:   ^ $ * . [] () ? - " ! @ # % & /  , > ; | _ ~ `',
  accountRecovery_resetPasswordButton: 'Reset password',
  accountRecovery_passwordChanged: 'Password reset successfully',
  accountRecovery_invalidVerificationCode: 'Invalid verification code',

  general_notification_copy: 'Copied to clipboard',
  general_serverError: 'Server error',
  general_email: 'Email',
  general_code: 'Code',
  general_submit: 'Submit',

  errors,
  themes,
};
