import React from 'react';
import { Localization } from 'connex-cds';
import CustomButton from '../../../../commons/custom-button';
import { Styled } from './styles';
import { CustomIcon, Heading1, Paragraph1 } from '../../../../commons';

const CookiesDetails = ({ onBack }) => {
  return (
    <Styled className="default-landing">
      <Heading1 className="landing-title">
        <Localization.Translate stringId="cookies_details_title" />
      </Heading1>
      <Paragraph1 className="landing-description">
        <Localization.Translate
          stringId="cookies_details_notifications"
          values={{
            strong: chunks => <strong>{chunks}</strong>,
          }}
        />
      </Paragraph1>
      <Paragraph1 className="landing-description">
        <Localization.Translate
          stringId="cookies_details_technical_support"
          values={{
            strong: chunks => <strong>{chunks}</strong>,
          }}
        />
      </Paragraph1>
      <div className="action-buttons">
        <CustomButton
          customcolor="blue"
          onClick={onBack}
          startIcon={<CustomIcon iconName="arrowBackOutlined" className="login-icon" />}
        >
          <Localization.Translate stringId="cookies_back_btn" />
        </CustomButton>
      </div>
    </Styled>
  );
};

export default CookiesDetails;
