import React, { useState } from 'react';
import { Localization, Core } from 'connex-cds';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from 'formik';
import { Button, IconButton, InputAdornment } from '@mui/material';
import styled from '@emotion/styled';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Visibility from '@mui/icons-material/VisibilityOutlined';
import VisibilityOff from '@mui/icons-material/VisibilityOffOutlined';
import LoginIcon from '@mui/icons-material/Login';

import { validationSchema, initialValues } from './form-config';
import { ReactComponent as CaiLogo } from '../../../../assets/cai_logo.svg';
import { InputTextBaseWithFormikField } from '../../../../commons';

import { LoginContainerStyles } from './styles';

const Styled = styled.div`
  ${LoginContainerStyles}
`;

const NewLoginForm = ({ handleLogin, hasFormError }) => {
  const [hiddenPassword, isHiddenPassword] = useState(true);
  const navigate = useNavigate();

  const handleNavClick = React.useCallback(
    to => {
      navigate(to);
    },
    [navigate]
  );

  return (
    <Styled className={'login-container'}>
      <hgroup className="login-heading">
        <CaiLogo className="cai-logo" />
        <h1>
          <Localization.Translate stringId="login_header" />
        </h1>
      </hgroup>
      {hasFormError && <span className="form-error">Invalid Username or Password</span>}
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleLogin}>
        {({ values, errors, handleSubmit, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit} className="login-form">
              <Field
                required
                variant="filled"
                className="form-field"
                name="username"
                component={InputTextBaseWithFormikField}
                testId="login-username"
                labelStringId="username"
                getInputProps={({ name }) => ({
                  endAdornment: values.username && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle"
                        onClick={() => {
                          setFieldValue(name, '');
                        }}
                        onMouseDown={null}
                        edge="end"
                      >
                        <HighlightOffIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                })}
              />
              <Field
                required
                variant="filled"
                sx={{
                  '& .MuiInputBase-root.MuiFilledInput-root:before': {
                    borderBottomColor: 'green',
                  },
                }}
                className="form-field"
                name="password"
                component={InputTextBaseWithFormikField}
                labelStringId="password"
                testId="login-password"
                type={hiddenPassword ? 'password' : 'text'}
                getInputProps={({ name }) => ({
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => isHiddenPassword(!hiddenPassword)}
                          edge="end"
                        >
                          {hiddenPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                      {values.password && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle"
                            onClick={() => {
                              setFieldValue(name, '');
                            }}
                            onMouseDown={null}
                            edge="end"
                          >
                            <HighlightOffIcon />
                          </IconButton>
                        </InputAdornment>
                      )}
                    </>
                  ),
                })}
              />
              <div className="login-account-help">
                <Core.Link
                  onClick={handleNavClick}
                  to={'/forgot-password'}
                  testId="forgot-password"
                  stringId="forgotPassword"
                />
              </div>
              <Button
                className="login-button"
                type="submit"
                data-testid="login-button"
                variant="contained"
                disabled={!values.username || !values.password}
                startIcon={<LoginIcon className="login-icon" />}
              >
                <Localization.Translate stringId="login" />
              </Button>
            </Form>
          );
        }}
      </Formik>
      <div className="login-links">
        <Core.Link onClick={handleNavClick} to={'/privacy'} testId="privacy-policy-link" stringId="privacyPolicy" />
      </div>
    </Styled>
  );
};

export default NewLoginForm;
