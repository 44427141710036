import React, { useState } from 'react';
import { BaseDarkContainer } from '../../commons';
import { CookiesConfirmation, CookiesDetails, CookiesRestriction } from './components';

const COOKIES_VIEW = {
  CONFIRMATION: 'confirmation',
  DETAILS: 'details',
  RESTRICTION: 'restriction',
};

const CookiesPage = ({ onAcceptance }) => {
  const [view, setView] = useState(COOKIES_VIEW.CONFIRMATION);

  const handleOnChangeView = view => {
    setView(view);
  };

  const renderView = view => {
    switch (view) {
      case COOKIES_VIEW.CONFIRMATION:
        return (
          <CookiesConfirmation
            onLearnMoreClick={() => handleOnChangeView(COOKIES_VIEW.DETAILS)}
            onRefuseAllClick={() => handleOnChangeView(COOKIES_VIEW.RESTRICTION)}
            onAcceptAllClick={onAcceptance}
          />
        );
      case COOKIES_VIEW.DETAILS:
        return <CookiesDetails onBack={() => handleOnChangeView(COOKIES_VIEW.CONFIRMATION)} />;
      case COOKIES_VIEW.RESTRICTION:
        return (
          <CookiesRestriction
            onBack={() => handleOnChangeView(COOKIES_VIEW.CONFIRMATION)}
            onLearnMoreClick={() => handleOnChangeView(COOKIES_VIEW.DETAILS)}
          />
        );

      default:
        return null;
    }
  };

  return <BaseDarkContainer>{renderView(view)}</BaseDarkContainer>;
};

export default CookiesPage;
