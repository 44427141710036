import { useCallback } from 'react';
import { toast } from 'react-toastify';

import Toast from './Toast';

const useToast = () => {
  const toastSuccess = useCallback((message, options) => {
    toast(<Toast type="SUCCESS" message={message} />, {
      autoClose: options?.duration,
    });
  }, []);

  const toastError = useCallback((message, options) => {
    toast(<Toast type="ERROR" message={message} />, {
      autoClose: options?.duration,
    });
  }, []);

  const toastInfo = useCallback((message, options) => {
    toast(<Toast type="INFO" message={message} materialIconName={options?.materialIconName} />, {
      autoClose: options?.duration,
    });
  }, []);

  return { success: toastSuccess, error: toastError, info: toastInfo };
};

export default useToast;
