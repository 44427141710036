import { string, node, bool } from 'prop-types';
import cn from 'classnames';

import { ReactComponent as CaiLogo } from '../../assets/cai_logo.svg';

import { MainContainer, Styled, LinearLoader } from './styles';

const BaseDarkContainer = ({ mainClassName, className, children, showLoader }) => {
  return (
    <MainContainer className={mainClassName}>
      <Styled className={cn({ 'show-loading-bar': showLoader }, className)}>
        {showLoader && <LinearLoader />}
        <CaiLogo className="cai-logo" />
        {children}
      </Styled>
    </MainContainer>
  );
};

BaseDarkContainer.propTypes = {
  children: node.isRequired,
  className: string,
  mainClassName: string,
  showLoader: bool,
};

BaseDarkContainer.defaultProps = {
  className: '',
  mainClassName: '',
  showLoader: false,
};

export default BaseDarkContainer;
