import common from '../common';
import errors from './errors';
import themes from './theme';

export default {
  ...common,
  'save-password': 'Salva la password',
  requestReset: 'Richiesta di ripristino',
  'forgot-password-instructions':
    'Ti è stato inviato un codice di verifica. Inseriscilo di seguito e seleziona una nuova password.',
  'password-requirements':
    'La password deve essere almeno 8 caratteri, contenere almeno 1 maiuscolo, 1 minuscolo, 1 numero e 1 dei seguenti caratteri speciali! @ # $ % ^',
  success: 'Successo',
  'password-reset-success': 'La tua password è stata ripristinata correttamente.',
  selectCompany: "Seleziona un'azienda",
  button: 'Testo del pulsante',
  save: 'Salva',
  text: "ISO 3166-1 (Codici per la rappresentazione dei nomi dei paesi e delle loro suddivisioni-Parte 1: Codici di paese) è uno standard che definisce i nomi dei paesi, territori dipendenti e aree speciali di interesse geografico. È la prima parte dello standard ISO 3166 pubblicato dall'Organizzazione internazionale per la standardizzazione.",
  username: 'Nome utente',
  password: "Parola d'ordine",
  login: 'Login',
  ssoLogin: 'Accesso sicuro con SSO',
  required: 'Necessario',
  light: 'Leggero',
  dark: 'Buio',
  rememberMe: 'Ricordati di me',
  eula: 'Eula',
  copyright: '© {Year} - Command Alkon, Inc.',
  privacyPolicy: 'politica sulla riservatezza',
  customer: 'Cliente',
  project: 'Progetto',
  isBillable: 'Fatturabile',
  dispatchDateTime: 'Data',
  orderId: 'Ordine',
  ticketId: 'Biglietto',
  location: 'Posizione',
  vehicleTypeName: 'Veicolo',
  product: 'Prodotto',
  salesTax: 'Imposta sulle vendite',
  totalPrice: 'Totale',
  extendedPrice: 'Ext',
  destination: 'Destinazione',
  productId: 'Codice prodotto',
  statusCode: 'Stato',
  vehicleTypeId: 'ID tipo di veicolo',
  locationId: 'Posizione ID',
  customerId: 'identificativo del cliente',
  projectId: 'ID progetto',
  dispatchTime: 'Tempo',
  'forgot-mail': 'Email dimenticata',
  showAll: 'Mostra tutto',
  hideAll: 'Nascondi tutto',
  undoChanges: 'Cancella i cambiamenti',
  resetColumns: 'Ripristina le colonne',
  displayRowCount: `Visualizzazione {count, plural,
    = 0 {0 rows} 
    uno {# row} 
    altri {# rows}
  }`,
  columnFilters: 'Filtri della colonna',
  visible: 'Visibile',
  hidden: 'Nascosto',
  topSection: 'Sezione superiore',
  itemDetail: "Dettaglio dell'articolo",
  quantity: 'Quantità',
  unloadingMethod: 'Metodo di scarico',
  time: 'Tempo',
  eventStatus: 'Stato',
  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    uno {1 m\u00B3}
    altri {# m\u00B3}
  }`,
  LTR: `{value, plural,
    =0 {0 Liters}
    uno {1 Liter}
    altri {# Liters}
  }`,
  TO: `{value, plural,
    =0 {0 Tons}
    uno {1 Ton}
    altri {# Tons}
  }`,
  ITEM: `{value, plural,
    =0 {0 Items}
    uno {1 Item}
    altri {# Items}
  }`,
  YDQ_short: `{value, plural,
    =0 {0 CY}
    uno {1 CY}
    altri {# CY}
  }`,
  YDQ: `{value, plural,
    =0 {0 Cubic Yards}
    uno {1 Cubic Yard}
    altri {# Cubic Yards}
  }`,
  mixIdLabel: 'Miscela id/descrizione',
  orderedQtyLabel: 'QTY ordinato',
  loadedQtyLabel: 'Qtà caricata',
  END_UNLOADING: 'Finire lo scarico',
  IN_YARD: 'Nel cortile',
  TO_JOB: 'Pianta sinistra',
  LEAVE_JOB: 'Lascia lavoro',
  CREATED: 'Ticket',
  UNLOADING: 'Inizia a scaricare',
  ARRIVE_JOB: 'Arrivo sito',
  plantName: 'Nome della pianta',
  plantAddress: "Indirizzo dell'impianto",
  loadingScheduleStart: 'Caricamento della data e ora di inizio pianificati',
  deliveryAddress: 'Indirizzo di consegna',
  phoneNumber: 'Numero di telefono',
  unloadingScheduleStart: 'Scaricamento della data e ora di inizio pianificati',
  startToFinishUnloadingTime: 'Scarico di inizio - data e ora',
  deliveryInformation: 'Informazioni di consegna',
  next: 'Prossimo',
  material: 'Materiale',
  loadingPoint: 'Punto di caricamento',
  unloadingPoint: 'Punto di scarico',
  camera: 'Telecamera',
  comment: 'Commento',
  sapDeliveryNumber: 'Numero di consegna SAP (numero BCC)',
  salesOrderNumber: "Numero dell'ordine di vendita e riga degli articoli",
  wcDateTime: 'Data e ora W/C Contatto',
  po: 'Numero PO',
  orderedByName: 'Ordine effettuato per nome',
  orderedByPhone: 'Ordine effettuato per numero di telefono',
  resourceId: 'ID risorsa',
  totalLoadedQty: 'Qty caricato totale incluso questo carico',
  spacing: 'Spazio di carico',
  deliveryFlow: 'Flusso di consegna',
  timeOnSite: 'Tempo totale in loco/tempo di scarico ordinato',
  salesText: 'Testo di vendita (ID materiale)',
  strengthClass: 'Classe di forza',
  environmentExposure: "Esposizione all'ambiente",
  metExposureClasses: 'Ha incontrato le classi di esposizione',
  dmax: 'Dmax',
  standardCert: 'Standard (certificazione)',
  workabilityTarget: 'Obiettivo di lavorabilità del cliente',
  stoneQuality: 'Qualità delle pietre',
  sandQuality: 'Qualità della sabbia',
  targetWC: 'Target W/C Ratio dalla ricetta',
  controlClass: 'Classe di controllo',
  chlorideClass: 'Classe di cloruro',
  typeAndStrength1: 'Tipo di cemento e classe di forza 1',
  typeAndStrength2: 'Tipo di cemento e classe di forza 2',
  typeOfAddition: 'Tipo di aggiunta',
  typeOfAdmixture: 'Tipo di miscela',
  orderedTemp: 'Temperatura ordinata',
  fibers1: 'Tipo e contenuto delle fibre 1',
  fibers2: 'Tipo e contenuto di fibre 2',
  calculatedMaturityTime: 'Tempo di maturità calcolato dal tempo di contatto W/C',
  generalDelivery: 'Informazioni di consegna generale',
  materialInfo: 'Informazioni sui materiali',
  cardTitle: 'Titolo della carta',
  cardSubtitle: 'Sottotitolo Card',
  cardBody: 'Corpo di carta',
  alertTitle: 'Il vecchio e il mare',
  alertMessage:
    'Era un vecchio che pescava da solo in uno skiff nella torrente del Golfo ed era passato ottantaquattro giorni senza prendere un pesce.',
  water: 'Acqua',
  materialService: 'Materiale / servizio',
  returnedMaterial: 'Materiale restituito',
  waterAdded: 'Acqua aggiunta',
  concreteOnTruck: 'Cemento su camion',
  reason: 'Motivo',
  customerAddedWater: 'Cliente ha aggiunto acqua',
  addWater: 'Aggiungere acqua',
  addedBy: 'Aggiunto da',
  addMaterialService: 'Aggiungi materiale/servizio',
  customerAddedProduct: 'Prodotto aggiunto dal cliente',
  addReturnedMaterial: 'Aggiungi materiale restituito',
  leftover: 'Rimanente',
  submitAcceptance: 'Invia accettazione',
  customerAcceptance: 'Accettazione del cliente',
  materialSummary: 'Riepilogo del materiale',
  mix: 'Mescolare',
  ordered: 'Ordinato',
  loaded: 'Caricato',
  driverComment: 'Commento del conducente',
  customerComment: 'Commento del cliente',
  editComment: 'Modifica commento',
  addComment: 'Aggiungi un commento',
  additionalInfo: 'Informazioni addizionali',
  onBoard: 'A bordo',
  driver: 'Autista',
  commentInstruction: 'Istruzione commento',
  signatureInstruction: 'Istruzione di firma',
  customerSignature: 'Firma del cliente',
  general: 'Generale',
  tabTwoStringId: 'Scheda due',
  tabThreeStringId: 'Tab tre',
  status: 'Stato',
  updateStatusTime: 'Aggiorna il tempo di stato',
  delete: 'Eliminare',
  cancel: 'Annulla',
  back: 'Indietro',
  add: 'Aggiungere',
  id: 'ID',
  name: 'Nome',
  invoiceable: 'Invocabile',
  provideSignature: 'Fornire firma',
  ticket: 'Biglietto',
  details: 'Dettagli',
  qrCode: 'Qr',
  driverAcceptanceReason: 'Motivo',
  driverAcceptance: 'Accettazione del conducente',
  'payment-method': 'Modalità di pagamento',
  'add-payment-method': 'Aggiungi metodo di pagamento',
  'cancel-reason-code': 'Annulla i motivi',
  'add-cancel-reason-code': 'Aggiungi la ragione',
  companies: 'Aziende',
  profiles: 'Profili',
  logout: 'Disconnettersi',
  online: 'in linea',
  offline: 'disconnesso',
  mt: 'Biglietto mobile',
  admin: 'Amministratore della piattaforma',
  profileEditor: 'Editor del profilo',
  firstname: 'Nome di battesimo',
  lastname: 'Cognome',
  email: 'Indirizzo e-mail',
  mobilePhone: 'Cellulare',
  currentPassword: 'password attuale',
  oldPassword: 'password attuale',
  newPassword: 'nuova password',
  newPasswordVerify: 'Nuova password (verifica)',
  passwordsCantMatch: 'La nuova password non può essere la stessa della password corrente!',
  passwordsDontMatch: 'Le password non corrispondono!',
  passwordRequirements:
    'La nuova password deve:\n\nessere lunga da 8 e 99 caratteri.\nIncludi 1 o più caratteri numerici.\nIncludi 1 o più lettere minuscole.\nIncludi 1 o più lettere maiuscole.\nIncludi 1 o più dei seguenti caratteri speciali! @ # $ % | ^',
  usernameInvalid: 'Nome utente non valido',
  passwordMinCharacters: 'La password deve contenere 8 o più caratteri.',
  passwordMaxCharacters: 'La password non può contenere più di 99 caratteri.',
  passwordLowerCase: 'La password deve includere 1 o più lettera minuscola.',
  passwordUpperCase: 'La password deve includere 1 o più lettera maiuscolo.',
  passwordNumber: 'La password deve includere 1 o più carattere numerico.',
  passwordSpecialCharacter: 'La password deve includere 1 o più dei seguenti caratteri speciali! @ # $ % | ^',
  forgotPassword: 'Ha dimenticato la password',
  forgotPasswordInstructions: 'Istruzioni',
  code: 'Codice di verifica',
  changePassword: 'Cambiare la password',
  'change-password-success': 'La tua password è stata modificata con successo.',
  'change-password-error':
    'La tua password non può essere modificata. Assicurati che la password corrente sia corretta e riprova.',
  'forgetMe-success': 'Sei stato dimenticato.',
  'forgetMe-error': "C'è stato un errore. Assicurati che la password sia corretta e riprova.",
  'forget-me-warning':
    '<p> Questa azione rimuoverà tutte le informazioni di identificazione personale dai nostri sistemi, dopodiché, verrai disconnesso e incapace di accedere. </p> Se si desidera procedere, inserisci la password di seguito.',
  apps: 'App',
  settings: 'Profilo',
  lastName: 'Cognome',
  firstName: 'Nome di battesimo',
  forgetMe: 'Dimenticami',
  manageProfile_title: 'Gestisci il profilo',
  manageProfile_general: 'Generale',
  manageProfile_credentials: 'Credenziali',
  manageProfile_preferences: 'Preferenze',
  manageProfile_notifications: 'Notifiche',
  manageProfile_general_firstName: 'Nome di battesimo',
  manageProfile_general_lastName: 'Cognome',
  manageProfile_general_phone: 'Cellulare',
  manageProfile_general_email: 'Indirizzo e-mail',
  manageProfile_general_phoneError: 'Telefono non valido.',
  manageProfile_general_emailError: 'Formato email non valido.',
  profileImage_title: 'Immagine del profilo',
  profileImage_description:
    "Un'immagine aiuta le persone a riconoscerti e ti fa sapere quando sei firmato sul tuo account",
  profileImage_change_btn: 'Modifica',
  profileImage_delete_btn: 'Rimuovere',
  profileImage_drag_photo_description: 'Trascina foto qui',
  profileImage_drag_photo_or: '- O -',
  profileImage_upload_from_computer_btn: 'Caricare dal computer',
  profileImage_rotate_btn: 'Ruotare',
  profileImage_file_size_error: 'Il file è troppo grande. Seleziona un file inferiore a 5 MB.',
  profileImage_update_sucess_msg: 'Immagine del profilo aggiornato correttamente',
  profileImage_update_error_msg: "Errore aggiornamento dell'immagine del profilo",
  profileImage_delete_sucess_msg: 'Profilo immagine eliminata correttamente',
  profileImage_delete_error_msg: "Errore Eliminazione dell'immagine del profilo",
  manageProfile_credentials_oldPassword: 'password attuale',
  manageProfile_credentials_newPassword: 'nuova password',
  manageProfile_credentials_confirmNewPassword: 'Conferma la nuova password',
  manageProfile_credentials_description: 'Nuova password deve:',
  manageProfile_credentials_reqLength: 'Essere lungo tra 8 e 99 caratteri.',
  manageProfile_credentials_reqNumber: 'Includi 1 o più caratteri numerici.',
  manageProfile_credentials_reqLowerCase: 'Includi 1 o più lettere minuscole.',
  manageProfile_credentials_reqUpperCase: 'Includi 1 o più lettere maiuscole.',
  manageProfile_credentials_reqSpecialCharacter:
    'Includi 1 o più caratteri speciali dal seguente set: ^ | $ *. [] ()? - "! @ # % & /,>; _ ~`',
  manageProfile_credentials_wrongOldPassword: 'Password errata. Per favore riprova.',
  manageProfile_cancel: 'Annulla',
  manageProfile_save: 'Salva',
  manageProfile_forget: 'Dimenticare',
  forgetMe_title: 'Dimentica questo account?',
  forgetMe_description: 'Ciò rimuoverà le credenziali dal preffitto di accesso',
  login_header: 'Accedi con le tue credenziali di seguito',
  cookies_main_title: 'Gestione dei Cookie',
  cookies_main_description:
    'Usiamo i cookie per migliorare la tua esperienza. Facendo clic su "Accetta tutto", accetta l\'uso di tutti i cookie sul dispositivo. Puoi gestire le preferenze dei cookie facendo clic su "Ulteriori informazioni".',
  cookies_learn_more_btn: 'Saperne di più',
  cookies_refuse_all_btn: 'Rifiutare tutto',
  cookies_accept_all_btn: 'Accettare tutti',
  cookies_back_btn: 'Torna indietro',
  cookies_details_title: 'Preferenze dei cookie',
  cookies_details_notifications:
    '<strong> Opzione di notifiche critiche </strong>: notifiche critiche, aiuto online e documentazione ci consentono di fornire agli utenti avvisi essenziali, fornire assistenza online e condividere notizie e aggiornamenti. Ciò fornisce agli utenti notifiche in-app, interruzioni dei servizi e accesso a nuovi annunci.',
  cookies_details_technical_support:
    '<strong> Opzione di supporto tecnico </strong>: il supporto tecnico ci consente di tracciare e monitorare le funzionalità che accedi. Questo ci aiuta a identificare e risolvere in modo proattivo i problemi, spesso prima ancora che vengano segnalati.',
  cookies_confirmation_msj: 'Politica dei cookie accettata',
  cookies_reject_description:
    'Mi dispiace, non sarai in grado di utilizzare l\'app senza i cookie richiesti. Puoi gestire le preferenze dei cookie facendo clic su "Ulteriori informazioni".',
  defaultLanding_header: "Scegli un'app per iniziare",
  defaultLanding_header_noLicenses: 'Attualmente non hai accesso a prodotti software Alkon di comando',
  defaultLanding_description_noLicenses:
    "Si prega di contattare l'amministratore del sito se si ritiene che ci sia stato un errore.",
  enforceEntity_selectCompany: "Seleziona un'azienda",
  enforceEntity_noMatchCompany:
    "Nessuna compagnia corrisponde con i tuoi criteri di ricerca. Si prega di perfezionare la ricerca o contattare l'amministratore del sito se si ritiene che ci sia stato un errore.",
  search: 'Ricerca',
  noResultsFound: 'nessun risultato trovato',
  accountRecovery: "Recupero dell'account",
  accountRecovery_content:
    "Hai dimenticato la password? Immettere l'indirizzo e -mail assegnato al tuo account nello spazio fornito, quindi selezionare <strong> Invia codice </strong>. Dovresti ricevere presto un'e -mail che includa un codice per aiutarti a reimpostare la password.",
  accountRecovery_usernameInvalid: 'Si prega di inserire un indirizzo email valido.',
  accountRecovery_sendCode: 'Manda il codice',
  accountRecovery_backToLogin: 'Torna al login',
  accountRecovery_verification: 'Verifica',
  accountRecovery_verification_content:
    'Per aiutare a mantenere il tuo account al sicuro, Command Alkon vuole assicurarsi che tu sia davvero tu. Inserisci il codice di verifica a sei cifre inviato alla tua e-mail per verificare il tuo account.',
  accountRecovery_verification_code: 'Immettere il codice',
  accountRecovery_code_sent_message: "{icona} Codice di verifica inviato all'e -mail",
  accountRecovery_errorCode: 'Codice di verifica non valido. Controlla la tua email e riprova.',
  accountRecovery_createNewPassword: 'Crea nuova password',
  accountRecovery_password: "Parola d'ordine",
  accountRecovery_errorPassword:
    'Formato della password invalido. Assicurati che la password soddisfi i criteri richiesti.',
  accountRecovery_passwordHint:
    'Usa 8-99 caratteri e includi almeno un numero, la lettera di capitale, la lettera minuscola e un carattere speciale dal seguente set: ^ $ *. [] ()? - "! @ # % & /,>; _ ~`',
  accountRecovery_resetPasswordButton: 'Resetta la password',
  accountRecovery_passwordChanged: 'Reimpostazione della password correttamente',
  accountRecovery_invalidVerificationCode: 'Codice di verifica non valido',
  general_notification_copy: 'Copiato negli appunti',
  general_serverError: 'Errore del server',
  general_email: 'E-mail',
  general_code: 'Codice',
  general_submit: 'Invia',
  errors,
  themes,
};
