import * as yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(yup);

export const validationSchema = yup.object().shape({
  oldPassword: yup.string().required('required'),
  newPassword: yup
    .string()
    .password()
    .min(8, 'passwordMinCharacters')
    .max(99, 'passwordMaxCharacters')
    .minLowercase(1, 'passwordLowerCase')
    .minUppercase(1, 'passwordUpperCase')
    .minNumbers(1, 'passwordNumber')
    .minSymbols(1, 'passwordSpecialCharacter')
    .required('required'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), null], 'passwordsDontMatch')
    .required('required'),
});

export const initialValues = {
  oldPassword: '',
  newPassword: '',
  confirmNewPassword: '',
};
