import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Layout } from 'connex-cds';

export const PublicLayoutContainer = styled(Layout.Column)`
  ${({ theme }) => css`
    background-image: url(/assets/login_bg.png);
    background-color: lightgray;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: center;
    gap: 0;

    .logout {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 300px;
    }

    .password-requirements {
      font-family: 'Work Sans';
    }
  `}
`;
