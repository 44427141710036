import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import colors from '../../../../mui-theme/colors';

export const StyledDialog = styled(Dialog)`
  ${css`
    font-family: 'Work Sans';

    .MuiDialog-paper {
      max-height: 603px;
      width: 852px;
      height: 100%;
      border-radius: 20px;
      box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3);
    }

    .dialog-title {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      font-size: 32px;

      img {
        height: 38px;
        width: 32px;
      }
    }

    .forget-form {
      display: flex;
      flex-direction: column;
      height: inherit;
    }

    .submit-button {
      &.MuiButton-root {
        background-color: ${colors.red[30]};
      }

      &.Mui-disabled {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }

    .dialog-actions {
      display: flex;
      justify-content: flex-end;
      gap: 22px;
      padding: 16px 27px 20px;

      .action-button {
        font-size: 20px;
        padding: 14px 24px;
        line-height: 20px;
      }
    }
  `}
`;

export const StyledDialogContent = styled(DialogContent)`
  ${css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h3 {
      font-size: 30px;
      margin-bottom: 20px;
    }

    .description {
      width: 388px;
      font-size: 24px;
      margin-bottom: 45px;
      font-weight: 400;
    }

    .password-field {
      width: 350px;
    }
  `}
`;
