import React from 'react';
import { string } from 'prop-types';

import { getInitials } from '../../util/string';

import { AvatarStyled } from './styles';

const Avatar = ({ src, fontSize, alt, firstName, lastName, ...otherProps }) => {
  return (
    <AvatarStyled alt={alt || 'Profile Picture'} src={src} fontSize={fontSize} {...otherProps}>
      {getInitials(firstName, lastName)}
    </AvatarStyled>
  );
};

Avatar.propTypes = {
  alt: string.isRequired,
  firstName: string.isRequired,
  fontSize: string,
  lastName: string.isRequired,
  src: string,
};

Avatar.defaultProps = {
  fontSize: 'medium',
  src: '',
  firstName: '',
  lastName: '',
};

export default Avatar;
