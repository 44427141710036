import React from 'react';
import { Localization } from 'connex-cds';
import CustomButton from '../../../../commons/custom-button';
import { Styled } from './styles';
import { ReactComponent as InternetCookies } from '../../../../assets/internet-cookies.svg';
import { CustomIcon } from '../../../../commons';
import { Heading1, Paragraph1 } from '../../../../commons';

const CookiesRestriction = ({ onBack, onLearnMoreClick }) => {
  return (
    <Styled className="default-landing">
      <Heading1 className="landing-title">
        <Localization.Translate stringId="cookies_main_title" />
      </Heading1>
      <InternetCookies className="graph" />
      <Paragraph1 className="landing-description">
        <Localization.Translate stringId="cookies_reject_description" />
      </Paragraph1>
      <div className="action-buttons">
        <CustomButton customcolor="blue" onClick={onBack} startIcon={<CustomIcon iconName="arrowBackOutlined" />}>
          <Localization.Translate stringId="cookies_back_btn" />
        </CustomButton>
        <CustomButton onClick={onLearnMoreClick} variant="outlined">
          <Localization.Translate stringId="cookies_learn_more_btn" />
        </CustomButton>
      </div>
    </Styled>
  );
};

export default CookiesRestriction;
