import React from 'react';
import { DeepLinkSupport } from './DeepLinkSupport';
import { EnforceEntityRequirement } from './EnforceEntityRequirement';
import { ServiceIframe } from './ServiceIframe';

export const Service = () => {
  return (
    <DeepLinkSupport>
      <EnforceEntityRequirement>
        <ServiceIframe />
      </EnforceEntityRequirement>
    </DeepLinkSupport>
  );
};
