import { useMemo } from 'react';
import { Localization } from 'connex-cds';
import MuiMenuItem from '@mui/material/MenuItem';
import cn from 'classnames';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import Icon from '../Icon';

import { MenuItemStyle } from './style';

const Styled = styled(MuiMenuItem)`
  ${MenuItemStyle}
`;

const MenuItem = ({ translation, isSelected, onClick }) => {
  const labelTranslationId = useMemo(() => {
    const isStr = typeof translation === 'string';

    if (isStr) {
      return translation;
    }

    return translation.id;
  }, [translation]);

  return (
    <Styled className={cn({ selected: isSelected })} onClick={onClick}>
      {isSelected && <Icon iconWeight="fa" iconType="check" />}
      <Localization.Translate stringId={labelTranslationId} />
    </Styled>
  );
};

MenuItem.propTypes = {
  translation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      id: PropTypes.string,
      onSelectedId: PropTypes.string,
    }),
  ]),
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
};

export default MenuItem;
