import { useState } from 'react';
import { func, bool, string } from 'prop-types';

import PasswordVisibility from '../input-adornments/PasswordVisibility';
import TextInput from '../text-input/TextInput';

const PasswordInput = ({ withClearAdornment, ...props }) => {
  const [type, setType] = useState(props.type);

  return (
    <TextInput
      {...props}
      withClearAdornment={withClearAdornment}
      type={type}
      getInputProps={() => ({ endAdornment: <PasswordVisibility inputType={type} setInputType={setType} /> })}
    />
  );
};

PasswordInput.propTypes = {
  withClearAdornment: bool,
  getInputProps: func,
  type: string,
};

PasswordInput.defaultProps = {
  withClearAdornment: false,
  getInputProps: () => null,
  type: 'password',
};

export default PasswordInput;
