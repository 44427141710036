import { API } from 'connex-cds';
import decode from 'jwt-decode';
import React from 'react';
import { useQuery } from 'react-query';
import AppEntities from '../../api/app-entities';
import { useAppContext } from '../AppProvider';
import { useUserContext } from '../UserProvider';

export const usePermissions = ({ entityRef }) => {
  const { appRef } = useAppContext();
  const { user, setRole } = useUserContext();
  const [token, setToken] = React.useState();

  const query = useQuery({
    queryKey: ['app-entity-settings', { profileRef: user?.profileRef, appRef, entityRef }],
    queryFn: AppEntities.getAppEntitySettings,
    enabled: !!(user?.profileRef && appRef && entityRef),
  });

  React.useEffect(() => {
    const token = query?.data?.access_token;
    if (token) {
      const decoded = decode(token);

      API.setAppEntityToken({ entityRef: decoded.sub, value: token });
      setToken({ entityRef: decoded.sub, value: token });

      const permissions = decoded?.cai?.permissions?.map?.(p => p.replace(`${decoded?.aud?.[0]}:`, ''));

      setRole({ permissions });
    }
  }, [entityRef, query?.data, setRole, setToken]);

  return { permissions: token, query };
};
