import { useCallback, useMemo, useState } from 'react';
import Menu from '@mui/material/Menu';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import DefaultTrigger from './DefaultTrigger';
import MenuItem from './MenuItem';

import { MenuStyle } from './style';

const Styled = styled(Menu)`
  ${MenuStyle}
`;

const SelectorMenu = ({
  triggerComponent: TriggerComponentProp,
  options,
  labelStartIcon,
  disabled,
  value,
  onChange,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isOpen = useMemo(() => !!anchorEl, [anchorEl]);

  const handleClickTrigger = useCallback(e => {
    setAnchorEl(e.currentTarget);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSelectorChange = useCallback(
    opt => {
      handleCloseMenu();
      onChange(opt);
    },
    [onChange, handleCloseMenu]
  );

  const labelTranslationId = useMemo(() => {
    const selectedOption = options.find(opt => opt.value === value);

    const translation = selectedOption.translation;
    const isStr = typeof translation === 'string';

    if (isStr) {
      return translation;
    }

    return translation.onSelectedId || translation.id;
  }, [options, value]);

  const Trigger = useCallback(
    props => {
      if (TriggerComponentProp) {
        return <TriggerComponentProp {...props} />;
      }

      return <DefaultTrigger startIcon={labelStartIcon} {...props} />;
    },
    [TriggerComponentProp, labelStartIcon]
  );

  return (
    <>
      <Trigger isOpen={isOpen} onClick={handleClickTrigger} disabled={disabled} translationId={labelTranslationId} />
      <Styled anchorEl={anchorEl} open={isOpen} onClose={handleCloseMenu}>
        {options?.map(opt => (
          <MenuItem
            key={opt.value}
            translation={opt.translation}
            isSelected={opt.value === value}
            onClick={() => handleSelectorChange(opt)}
          />
        ))}
      </Styled>
    </>
  );
};

SelectorMenu.propTypes = {
  disabled: PropTypes.bool,
  triggerComponent: PropTypes.func,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      translation: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          id: PropTypes.string,
          onSelectedId: PropTypes.string,
        }),
      ]),
    })
  ),
  labelStartIcon: PropTypes.element,
  onChange: PropTypes.func,
};

SelectorMenu.defaultProps = {};

export default SelectorMenu;
