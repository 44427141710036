import { useMemo } from 'react';
import styled from '@emotion/styled';
import cn from 'classnames';
import PropTypes from 'prop-types';
import * as MaterialIcons from '@mui/icons-material';

import { Icon } from '../../common';

import { ToastStyle } from './style';

const Styled = styled.div`
  ${ToastStyle}
`;

const Toast = ({ type, message, materialIconName }) => {
  const iconProps = useMemo(() => {
    let iconWeight = '';
    let iconType = '';

    if (type === 'SUCCESS') {
      iconWeight = 'fa-regular';
      iconType = 'circleCheck';
    } else if (type === 'ERROR') {
      iconWeight = 'fa-solid';
      iconType = 'circleExclamation';
    } else if (type === 'INFO') {
      iconWeight = 'fa-solid';
      iconType = 'circleInfo';
    }

    return { iconWeight, iconType };
  }, [type]);

  const MaterialIcon = MaterialIcons[materialIconName];

  return (
    <Styled
      className={cn({
        'toast-success': type === 'SUCCESS',
        'toast-error': type === 'ERROR',
        'toast-info': type === 'INFO',
      })}
    >
      <div className="content-wrapper">
        <p>{message}</p>
      </div>
      <div className="icon-wrapper">
        {MaterialIcon ? (
          <MaterialIcon className={cn('material-icon')} />
        ) : (
          <Icon className={cn('icon')} {...iconProps} />
        )}
      </div>
    </Styled>
  );
};

Toast.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  materialIconName: PropTypes.string,
};

export default Toast;
