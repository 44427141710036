import { css } from '@emotion/react';

export const LoginContainerStyles = ({ theme }) => css`
  width: 781px;
  height: 669px;
  border-radius: 20px;
  border: 1px solid ${theme.colorsCA.neutral[60]};
  background: linear-gradient(0deg, rgba(208, 188, 255, 0.05) 0%, rgba(208, 188, 255, 0.05) 100%), rgba(28, 27, 31, 0.4);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 49px;
  padding-bottom: 14px;

  /* override autofill styles in webkit browser  */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    -webkit-text-fill-color: ${theme.colorsCA.neutral[95]};
    -webkit-box-shadow: 0 0 0px 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    letter-spacing: 0.15px;
    font-size: 16px;
  }

  .login-heading {
    margin-bottom: 20.59px;

    text-align: center;
    .cai-logo {
      width: 105px;
      height: 68px;
      margin-bottom: 47px;
    }

    h1 {
      color: ${theme.colorsCA.neutral[95]};
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin: 0;
    }
  }

  .form-error {
    color: ${theme.colorsCA.red[90]};
    margin-bottom: 16px;
  }

  .login-form {
    display: flex;
    flex-direction: column;
    width: 400px;
    flex: 1;

    .form-field {
      margin-bottom: 19px;

      .MuiInputLabel-root {
        color: ${theme.colorsCA.neutral[95]};
        font-weight: 400;
        letter-spacing: 0.15px;

        &.Mui-error {
          color: ${theme.colorsCA.red[90]};
        }
      }

      .MuiFormLabel-asterisk,
      .MuiFormHelperText-root {
        &.Mui-error {
          color: ${theme.colorsCA.red[90]};
        }
      }

      .MuiInputBase-root.MuiFilledInput-root {
        color: ${theme.colorsCA.neutral[95]};

        &::before {
          border-bottom: 1px solid ${theme.colorsCA.neutral[95]};
        }

        &::after {
          border-bottom: 2px solid ${theme.colorsCA.blue[90]};
        }

        &.Mui-focused::after {
          border-bottom: 2px solid ${theme.colorsCA.blue[90]};
        }

        &.Mui-error {
          &::before {
            border-bottom: 1px solid ${theme.colorsCA.red[90]};
          }

          &::after {
            border-bottom: 2px solid ${theme.colorsCA.red[90]};
          }
        }

        .MuiFilledInput-input {
          letter-spacing: 0.15px;
        }
      }

      .MuiInputAdornment-root {
        .MuiIconButton-root:hover {
          background: none;
        }

        .MuiSvgIcon-root {
          color: ${theme.colorsCA.neutral[95]};
          font-size: 24px;
        }
      }
    }

    .login-account-help {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 24px;

      a {
        color: ${theme.colorsCA.blue[95]};
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;

        &:hover {
          color: ${theme.colorsCA.blue[90]};
        }
      }
    }

    .login-button {
      display: flex;
      height: 48px;
      justify-content: center;
      align-items: center;
      gap: 16px;
      border-radius: 100px;
      background: #ff4f02;
      width: 100%;
      color: ${theme.colorsCA.neutral[95]};
      box-shadow: none;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;

      &:disabled {
        background-color: ${theme.colorsCA.neutral[80]};
      }

      &:hover {
        background: #ff4f02;
        box-shadow: none;
      }

      .login-icon {
        color: ${theme.colorsCA.neutral[95]};
        font-size: 24px;
      }
    }
  }

  .login-links {
    a {
      color: ${theme.colorsCA.blue[95]};
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;

      &:hover {
        color: ${theme.colorsCA.blue[90]};
      }
    }
  }
`;
