import { css } from '@emotion/react';

export const MenuItemStyle = ({ theme }) => css`
  display: flex;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(2.25, 2)};
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.5px;
  font-weight: 400;

  &:hover {
    background: ${theme.validateMode({ light: theme.colorsCA.neutral[95], dark: theme.colorsCA.neutral[40] })};
  }

  &.selected {
    font-weight: 500;
    background-color: ${theme.validateMode({ light: theme.colorsCA.blue[95], dark: theme.colorsCA.blue[90] })};
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[40], dark: theme.colorsCA.neutral[40] })};
  }
`;

export const MenuStyle = ({ theme }) => css`
  .MuiPaper-root {
    margin-top: ${theme.spacing(1.4)};
    border-radius: 10px;
    box-shadow: ${theme.shadowCA(2)};
    background: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.backgroundCA(5) })};
  }
`;
