import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

import colors from '../../mui-theme/colors';

export const AvatarStyled = styled.div`
  ${({ theme }) => css`
    position: relative;
    width: 129px;
    height: 129px;
    user-select: none;
    color: ${colors.neutral[40]};
    flex-shrink: 0;

    .badge {
      width: 129px;
      height: 129px;
    }
  `}
`;

export const IconButtonStyled = styled(IconButton)`
  ${({ theme }) => css`
    width: 30px;
    height: 30px;
    box-shadow: ${theme.shadowCA(3)};
    background-color: ${theme.validateMode({
      light: colors.neutral[100],
      dark: theme.elevationCA[5],
    })};
    color: ${theme.validateMode({
      light: colors.neutral[40],
      dark: theme.colorsCA.neutral[95],
    })};
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.3));

    &:hover {
      background-color: ${theme.validateMode({
        light: colors.neutral[100],
        dark: theme.colorsCA.darkBlue[60],
      })};,
    }

    svg {
      height: 20px;
    }
  `}
`;
