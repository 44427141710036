import { API } from 'connex-cds';
import decode from 'jwt-decode';
import React from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { useAuthContext } from '../providers/AuthProvider';
import { useEntityContext } from '../providers/entity-provider/EntityProvider';
import { useUserContext } from '../providers/UserProvider';
import { useQueryParams } from '../QueryParamContext';
import sendMessage from '../util/sendMessage';

export const DeepLinkSupport = ({ children }) => {
  const { setEntityRef } = useEntityContext();
  const queryParams = useQueryParams();
  const userContext = useUserContext();
  const { setAuthentication } = useAuthContext();
  const location = useLocation();

  const { lt, aet } = queryParams;

  const match = matchPath(`/app/:appRef/:entityRef/*`, location.pathname) || {};
  const params = match.params || {};
  const { entityRef } = params;

  const setUser = React.useMemo(() => {
    return userContext?.setUser || (() => {});
  }, [userContext?.setUser]);

  React.useEffect(() => {
    if (!lt || !aet) {
      return;
    }

    if (entityRef) {
      setEntityRef(entityRef);
    }

    API.setLoginToken(lt);
    API.setAppEntityToken(aet);
    sendMessage({ type: 'cx-login-token', token: lt });

    const decodedToken = decode(lt);

    const userObject = {
      profileRef: decodedToken?.azp,
      email: '',
      firstName: '',
      lastName: '',
      access_token: lt,
      apps: [],
      isPlatformAdmin: !!decodedToken?.cai?.pa,
      isPlatformSupport: !!decodedToken?.cai?.ps,
    };
    setUser(userObject);
    setAuthentication(userObject);
  }, [aet, entityRef, lt, setAuthentication, setEntityRef, setUser]);

  return children;
};
