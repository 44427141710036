import colors from '../colors';
import { shadow, background } from '../elevation';

const darkOverrides = {
  MuiButton: {
    styleOverrides: {
      root: {
        textTransform: 'none',
        borderRadius: '100px',
      },
    },
    variants: [
      {
        props: { variant: 'outlined' },
        style: {
          border: `1px solid ${colors.blue[95]}`,
        },
      },
    ],
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        background: background.dark[5],
        shadow: shadow.dark[5],
        borderRadius: '10px',
        marginTop: 2,
      },
    },
  },
  MuiItem: {
    styleOverrides: {
      root: {
        color: colors.neutral[95],
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        background: background.dark[5],
        boxShadow: shadow.dark[4],
        borderRadius: '20px',
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: '10px;',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
    },
  },
  MuiIconButton: {
    variants: [
      {
        props: { variant: 'outlined' },
        style: {
          border: `1px solid ${colors.blue[95]}`,
        },
      },
    ],
  },
  MuiInputLabel: {
    defaultProps: {
      sx: {
        fontSize: 16,
        top: -4,
      },
    },
    styleOverrides: {
      shrink: {
        color: colors.neutral[100],
        fontWeight: 600,
        top: '0 !important',
        '&.Mui-focused': {
          color: colors.blue[90],
        },
        '&.Mui-error': {
          color: colors.red[90],
        },
        '&.Mui-disabled': {
          color: colors.neutral[60],
        },
      },
    },
  },
  MuiOutlinedInput: {
    defaultProps: {
      sx: {
        fontSize: 16,
      },
    },
    styleOverrides: {
      root: {
        '&:hover': {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: colors.blue[90],
          },
        },
        '&.Mui-focused': {
          '.MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${colors.blue[90]}`,
          },
        },
        '&.Mui-error': {
          '.MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${colors.red[90]}`,
          },
        },
        '&.Mui-disabled': {
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: colors.neutral[60],
          },
        },
      },
      notchedOutline: {
        border: `1px solid ${colors.neutral[100]}`,
        borderRadius: '4px',
      },
      input: {
        height: 40,
        padding: '4px 16px',
        paddingRight: 0,
      },
    },
  },
};

export default darkOverrides;
