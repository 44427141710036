import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { BaseDarkContainer } from '../../../commons';

export const AccountRecoveryContainer = styled(BaseDarkContainer)`
  ${({ theme }) => css`
    max-width: 781px;
    width: 781px;
    border-radius: 20px;
    border: 1px solid ${theme.colorsCA.neutral[60]};
    background: linear-gradient(0deg, rgba(208, 188, 255, 0.05) 0%, rgba(208, 188, 255, 0.05) 100%),
      rgba(28, 27, 31, 0.4);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(40px);
    padding-top: 49px;
    padding-bottom: 14px;
    min-height: 669px;
    display: block;
    max-height: none;

    .cai-logo {
      width: 105px;
      height: 68px;
      margin-bottom: 47px;
    }

    &.confirmation-size {
      min-height: 574px;
    }
  `}
`;

export const StepContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    /* override autofill styles in webkit browser  */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      -webkit-text-fill-color: ${theme.colorsCA.neutral[95]};
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
      letter-spacing: 0.15px;
      font-size: 16px;
    }

    .login-form {
      display: flex;
      flex-direction: column;
      width: 400px;
      flex: 1;
      padding-bottom: 36px;

      .form-field {
        margin-bottom: 36px;

        .MuiInputLabel-root {
          color: ${theme.colorsCA.neutral[95]};
          font-weight: 400;
          letter-spacing: 0.15px;

          &.Mui-error {
            color: ${theme.colorsCA.red[90]};
          }
        }

        .MuiFormLabel-asterisk,
        .MuiFormHelperText-root {
          &.Mui-error {
            color: ${theme.colorsCA.red[90]};
          }
        }

        .MuiInputBase-root.MuiFilledInput-root {
          color: ${theme.colorsCA.neutral[95]};

          &::before {
            border-bottom: 1px solid ${theme.colorsCA.neutral[95]};
          }

          &::after {
            border-bottom: 2px solid ${theme.colorsCA.blue[90]};
          }

          &.Mui-focused::after {
            border-bottom: 2px solid ${theme.colorsCA.blue[90]};
          }

          &.Mui-error {
            &::before {
              border-bottom: 1px solid ${theme.colorsCA.red[90]};
            }

            &::after {
              border-bottom: 2px solid ${theme.colorsCA.red[90]};
            }
          }

          .MuiFilledInput-input {
            letter-spacing: 0.15px;
          }
        }

        .MuiInputAdornment-root {
          .MuiIconButton-root:hover {
            background: none;
          }

          .MuiSvgIcon-root {
            color: ${theme.colorsCA.neutral[95]};
            font-size: 24px;
          }
        }
      }

      .submit-button {
        display: flex;
        height: 48px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 100px;
        background: #ff4f02;
        width: 100%;
        color: ${theme.colorsCA.neutral[95]};
        box-shadow: none;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 17px;

        &:disabled {
          background-color: ${theme.colorsCA.neutral[80]};
        }

        &:hover {
          background: #ff4f02;
          box-shadow: none;
        }

        .button-icon {
          color: ${theme.colorsCA.neutral[95]};
          font-size: 24px;
        }
      }

      .back-button {
        max-width: 100%;
        font-weight: 500;
        font-size: 20px;
        gap: 10px;

        .button-icon {
          color: ${theme.colorsCA.neutral[95]};
          font-size: 24px;
        }
      }
    }

    &.custom-margin-step {
      .login-form {
        .form-field {
          margin-bottom: 47px;
        }
      }
    }
  `}
`;

export const Heading1 = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colorsCA.neutral[100]};
    font-size: 40px;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 16px;
  `}
`;

export const Paragraph = styled.p`
  ${({ theme }) => css`
    color: ${theme.colorsCA.neutral[100]};
    font-size: 20px;
    font-weight: 300;
    line-height: 24px;
    margin: 0 16px 47px;
  `}
`;
