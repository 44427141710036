import { Skeleton } from '@mui/material';

const GeneralSkeleton = () => {
  return (
    <>
      <Skeleton variant="circular" className="skeleton__avatar" />

      <div className="skeleton__title">
        <Skeleton variant="text" className="name" />
        <Skeleton variant="text" className="company" />
      </div>

      <div className="skeleton__form">
        <Skeleton variant="rounded" />
        <Skeleton variant="rounded" />
        <Skeleton variant="rounded" />
        <Skeleton variant="rounded" />
      </div>
    </>
  );
};

export default GeneralSkeleton;
