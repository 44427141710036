import styled from '@emotion/styled';
import { Localization } from 'connex-cds';

import { ReactComponent as SearchEmpty } from '../../../assets/search_empty.svg';

import { EmptySearchStyles } from './styles';

const Styled = styled.div`
  ${EmptySearchStyles}
`;

const EmptySearch = () => (
  <Styled className="no-results-found">
    <SearchEmpty className="search-empty" />
    <h2>
      <Localization.Translate stringId="noResultsFound" />
    </h2>
    <p>
      <Localization.Translate stringId="enforceEntity_noMatchCompany" />
    </p>
  </Styled>
);

export default EmptySearch;
