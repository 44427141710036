import { DateTime } from 'luxon';

const isValid = date => {
  let isValidDate = DateTime.fromISO(date).isValid;
  return isValidDate;
};

const currentDate = () => {
  return DateTime.now();
};

const dateFromISO = date => {
  return DateTime.fromISO(date);
};

const isGreaterDate = (startDate, endDate) => {
  return startDate <= endDate;
};

export default { isValid, currentDate, dateFromISO, isGreaterDate };
