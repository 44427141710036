import { css } from '@emotion/react';

export const AuthenticatedRoutesStyles = () => css`
  background-image: url(/assets/login_bg.png);
  background-color: lightgray;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: center;
  gap: 0;

  .header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-right: 60px;
    padding-left: 60px;
  }
`;
