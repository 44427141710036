import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const StepFourContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    /* override autofill styles in webkit browser  */
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      -webkit-text-fill-color: ${theme.colorsCA.neutral[95]};
      -webkit-box-shadow: 0 0 0px 1000px transparent inset;
      transition: background-color 5000s ease-in-out 0s;
      letter-spacing: 0.15px;
      font-size: 16px;
    }

    .lottie-animation {
      height: 140px;
      margin-top: 85px;
    }
  `}
`;
