import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Username = styled.div`
  ${({ theme }) => css`
    border-radius: 100px;
    border: 1px solid ${theme.colorsCA.neutral[95]};
    background: linear-gradient(0deg, rgba(208, 188, 255, 0.05) 0%, rgba(208, 188, 255, 0.05) 100%),
      rgba(28, 27, 31, 0.4);
    padding: 16px;
    color: ${theme.colorsCA.neutral[95]};
    text-align: center;
    font-size: 20px;
    font-style: normal;
    line-height: 20px;
    display: flex;
    align-items: center;
    .MuiSvgIcon-root {
      margin-right: 16px;
    }
  `}
`;

export const VerificationMessage = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorsCA.neutral[95]};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
    margin: 17px 0 35px;
    .MuiSvgIcon-root {
      color: ${theme.colorsCA.green[60]};
      margin-bottom: -4px;
    }
    .pending-message {
      color: ${theme.colorsCA.yellow[30]};
    }
  `}
`;

export const ResendButtonContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 25px;
    color: ${theme.colorsCA.neutral[95]};

    .resend-timer {
      display: flex;
      > span {
        margin-left: 10px;
        font-size: 16px;
      }
    }

    button {
      color: ${theme.colorsCA.blue[95]};
      text-align: right;
      font-size: 20px;
      line-height: 16px;
      padding: 6px 0;

      &:hover {
        background: transparent;
      }

      &:disabled {
        color: ${theme.colorsCA.neutral[60]};
      }
    }

    &.show-timer {
      justify-content: space-between;
    }
  `}
`;
