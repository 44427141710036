import queryString from 'query-string';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { isEmpty } from 'lodash';
import App from './App';
import { QueryParamContext } from './QueryParamContext';
import { cacheItem } from './util/cache';
import packageJson from '../package.json';

const queryParams = queryString.parse(window.location.search);

console.log(`CONNEX Version: ${packageJson.version}`);

let tokens = {};

if (!isEmpty(queryParams)) {
  const previousConnexId = localStorage.getItem('x-connex-id') || '';

  const connexId = queryParams['token'] || previousConnexId;
  const source = queryParams['source'] || localStorage.getItem('source');
  const language = queryParams['language'] || localStorage.getItem('language');
  const themeName = queryParams['theme'] || localStorage.getItem('themeName') || 'light';
  const entityRef = queryParams['entityRef'] || localStorage.getItem('entityRef');
  const apiKey = queryParams['apiKey'] || '';

  tokens.lt = connexId;
  tokens.aet = queryParams.aet;
  tokens.entityRef = entityRef;

  cacheItem('source', source || '');
  cacheItem('themeName', themeName);
  cacheItem('language', language || '');
  cacheItem('apiKey', apiKey);

  if (entityRef) {
    cacheItem('entityRef', entityRef);
  }

  if (connexId) {
    cacheItem('x-connex-id', connexId);
  }

  // Strip query params off URL
  window?.history?.replaceState?.(
    {},
    null,
    `${window?.location?.pathname?.[0] !== '/' ? '/' : ''}${window.location.pathname}`
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryParamContext value={tokens}>
    <App />
  </QueryParamContext>
);
