import { Badge } from '@mui/material';

import { CustomIcon, CAAvatar } from '..';

import { AvatarStyled, IconButtonStyled } from './styles';

const AvatarButton = ({ profileImage, onUploadProfileImageClick, fullName, avatarInfo }) => {
  return (
    <AvatarStyled>
      <Badge
        className="badge"
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          <IconButtonStyled onClick={onUploadProfileImageClick} className="camera-button">
            <CustomIcon iconName="cameraAltOutlinedIcon" />
          </IconButtonStyled>
        }
      >
        <CAAvatar src={profileImage} alt={fullName} firstName={avatarInfo?.firstName} lastName={avatarInfo?.lastName} />
      </Badge>
    </AvatarStyled>
  );
};

export default AvatarButton;
