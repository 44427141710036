import React from 'react';
import { Grid } from '@mui/material';
import { Localization } from 'connex-cds';

import { ActionButton, CAAvatar } from '../../../../../../commons';

const CurrentImageActions = ({ initialImage, onCreate, onDelete, isLoadingDeleteImg, alt, avatarInfo }) => {
  return (
    <Grid container direction="column" alignItems="center" spacing={4}>
      <Grid container item justifyContent="center">
        <Grid item alignItems="center" justifyContent="center" xs={8} sx={{ textAlign: 'center', mt: 4 }}>
          <Localization.Translate stringId="profileImage_description" />
        </Grid>
      </Grid>
      <Grid item>
        <CAAvatar
          size="large"
          src={initialImage}
          fontSize={'large'}
          alt={alt}
          firstName={avatarInfo?.firstName}
          lastName={avatarInfo?.lastName}
        />
      </Grid>
      <Grid container item justifyContent="center" spacing={2}>
        <Grid item>
          <ActionButton
            icon="createIcon"
            label={<Localization.Translate stringId="profileImage_change_btn" />}
            onClick={onCreate}
          />
        </Grid>
        <Grid item>
          <ActionButton
            icon="deleteOutlineIcon"
            label={<Localization.Translate stringId="profileImage_delete_btn" />}
            onClick={onDelete}
            disabled={isLoadingDeleteImg || !initialImage}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CurrentImageActions;
