import React from 'react';
import { Localization } from 'connex-cds';
import { Button, IconButton, InputAdornment } from '@mui/material';
import { Formik, Form, Field } from 'formik';
import { ArrowBack, HighlightOff, Send } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { LOGIN } from '../../../../constants/routes';
import { InputTextBaseWithFormikField, CustomButton } from '../../../../commons';
import { initialValues, validationSchema } from './form-config';

import { Heading1, Paragraph, StepContainer } from '../styles';

const StepOne = ({ handleSendCode }) => {
  const navigate = useNavigate();
  const translateMessage = Localization.useTranslateMessage();

  return (
    <StepContainer className="custom-margin-step">
      <Heading1>{translateMessage('accountRecovery')}</Heading1>
      <Paragraph>
        <Localization.Translate
          stringId="accountRecovery_content"
          values={{
            strong: chunks => <strong>{chunks}</strong>,
          }}
        />
      </Paragraph>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSendCode}>
        {({ values, errors, handleSubmit, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit} className="login-form">
              <Field
                required
                variant="filled"
                className="form-field"
                name="username"
                component={InputTextBaseWithFormikField}
                testId="login-username"
                labelStringId="general_email"
                getInputProps={({ name }) => ({
                  endAdornment: values.username && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle"
                        onClick={() => {
                          setFieldValue(name, '');
                        }}
                        onMouseDown={null}
                        edge="end"
                      >
                        <HighlightOff />
                      </IconButton>
                    </InputAdornment>
                  ),
                })}
              />
              <Button
                className="submit-button"
                type="submit"
                data-testid="submit-button"
                variant="contained"
                disabled={Boolean(!values.username || Object.keys(errors).length)}
                startIcon={<Send className="button-icon" />}
              >
                <Localization.Translate stringId="accountRecovery_sendCode" />
              </Button>
              <CustomButton
                variant="outlined"
                className="back-button"
                onClick={() => {
                  navigate(LOGIN);
                }}
                startIcon={<ArrowBack className="button-icon" />}
              >
                <Localization.Translate stringId="accountRecovery_backToLogin" />
              </CustomButton>
            </Form>
          );
        }}
      </Formik>
    </StepContainer>
  );
};

export default StepOne;
