import { useMutation, useQuery, useQueryClient } from 'react-query';
import { userProfile } from '../api';
import { useUserContext } from '../providers/UserProvider';

export const useProfile = () => {
  const { user } = useUserContext();

  return useQuery({
    queryKey: ['profile', user?.profileRef],
    queryFn: () => userProfile.getUserProfile(user?.profileRef),
    refetchOnWindowFocus: false,
    enabled: !!user?.profileRef,
  });
};

export const useUpdateProfile = () => {
  const { user } = useUserContext();
  const queryClient = useQueryClient();

  const queryKey = ['profile', user?.profileRef];

  const mutation = useMutation({
    mutationFn: profile => userProfile.updateUserProfile({ profileRef: user.profileRef, profile }),
    onMutate: async newProfile => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, newProfile);
      return { previousItem, newProfile };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation;
};

export const useCreateImageProfile = () => {
  const { user } = useUserContext();
  const queryClient = useQueryClient();

  const queryKey = ['profile', user?.profileRef];

  const mutation = useMutation({
    mutationFn: data => userProfile.uploadProfileImage({ profileRef: user?.profileRef, data: { ...data } }),
    onMutate: async newProfile => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, newProfile);
      return { previousItem, newProfile };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation;
};

export const useUpdateImageProfile = () => {
  const { user } = useUserContext();
  const queryClient = useQueryClient();

  const queryKey = ['profile', user?.profileRef];

  const mutation = useMutation({
    mutationFn: data => userProfile.updateProfileImage({ profileRef: user?.profileRef, data: { ...data } }),
    onMutate: async newProfile => {
      await queryClient.cancelQueries(queryKey);
      const previousItem = queryClient.getQueryData(queryKey);
      queryClient.setQueryData(queryKey, newProfile);
      return { previousItem, newProfile };
    },
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation;
};

export const useDeleteImageProfile = () => {
  const { user } = useUserContext();
  const queryClient = useQueryClient();

  const queryKey = ['profile', user?.profileRef];

  const mutation = useMutation({
    mutationFn: () => userProfile.deleteProfileImage({ profileRef: user?.profileRef }),
    onError: (err, newItem, context) => {
      queryClient.setQueryData(queryKey, context.previousItem);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return mutation;
};
