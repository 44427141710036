import { API } from 'connex-cds';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useToast } from '../components/Toast';
import { APP, EULA_PAGE, FORGOT_PASSWORD, LOGIN, OLD_FORGOT_PASSWORD, PRIVACY, ROOT } from '../constants/routes';
import { Service } from '../service-iframe/Service';
import AuthenticatedRoutes from '../views/authenticated-routes/AuthenticatedRoutes';
import { EnforceCookiePolicy } from '../views/cookies/EnforceCookiePolicy';
import DefaultLandingPage from '../views/default-landing-page';
import PublicLayout from '../views/public-layout/PublicLayout';
import AccountRecovery from '../views/unauthenticated/account-recovery';
import { Eula } from '../views/unauthenticated/Eula';
import { ForgotPassword } from '../views/unauthenticated/forgot-password/ForgotPassword';
import Login from '../views/unauthenticated/login/Login';
import Privacy from '../views/unauthenticated/Privacy';
import { ErrorBoundary } from './ErrorBoundary';

export const BaseRoutes = () => {
  const notify = useToast();

  React.useEffect(() => {
    API.setErrorHandler(msg => notify.error(msg));
  }, [notify]);

  return (
    <Routes>
      <Route element={<PublicLayout />}>
        <Route path={EULA_PAGE} element={<Eula />} />
        <Route path={PRIVACY} element={<Privacy />} />
        <Route path={LOGIN} element={<Login />} />
        <Route path={`${FORGOT_PASSWORD}/*`} element={<AccountRecovery />} />
        <Route path={`${OLD_FORGOT_PASSWORD}/*`} element={<ForgotPassword />} />
      </Route>
      <Route element={<AuthenticatedRoutes />}>
        <Route element={<EnforceCookiePolicy />}>
          <Route path={ROOT} element={<DefaultLandingPage />} exact />
          <Route path={`${APP}/*`} element={<ErrorBoundary />}>
            <Route path="" element={<DefaultLandingPage />} />
            <Route path=":appRef/*" element={<Service />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};
