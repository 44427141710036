import React from 'react';
import styled from '@emotion/styled';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { LoaderStyles } from './style';

const Styled = styled(Box)`
  ${LoaderStyles}
`;

const Loader = () => {
  return (
    <Styled>
      <CircularProgress data-testid="circular-progress" color="inherit" />
    </Styled>
  );
};

export default Loader;
