import { css } from '@emotion/react';

export default ({ theme }) => css`
  display: flex;
  justify-content: flex-end;
  gap: 22px;
  padding: 16px 27px 20px;

  .action-button {
    font-size: 20px;
    padding: 14px 24px;
    line-height: 20px;
  }
`;
