import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Username = styled.div`
  ${({ theme }) => css`
    border-radius: 100px;
    border: 1px solid ${theme.colorsCA.neutral[95]};
    background: linear-gradient(0deg, rgba(208, 188, 255, 0.05) 0%, rgba(208, 188, 255, 0.05) 100%),
      rgba(28, 27, 31, 0.4);
    padding: 16px;
    color: ${theme.colorsCA.neutral[95]};
    text-align: center;
    font-size: 20px;
    font-style: normal;
    line-height: 20px;
    display: flex;
    align-items: center;
    margin-top: 6px;
    .MuiSvgIcon-root {
      margin-right: 16px;
    }
  `}
`;

export const NewPasswordTitle = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorsCA.neutral[95]};
    text-align: center;
    font-size: 24px;
    font-style: normal;
    line-height: normal;
    margin: 35px 0 10px;
    .MuiSvgIcon-root {
      color: ${theme.colorsCA.green[60]};
      margin-bottom: -4px;
    }
  `}
`;

export const Paragraph = styled.div`
  ${({ theme }) => css`
    color: ${theme.colorsCA.neutral[95]};
    text-align: left;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    margin-bottom: 38px;
  `}
`;
