import common from '../common';
import errors from './errors';
import themes from './theme';

export default {
  ...common,
  'save-password': 'Zapisz hasło',
  requestReset: 'Resetuj żądanie',
  'forgot-password-instructions':
    'Kod weryfikacyjny został wysłany do Ciebie. Wprowadź go poniżej i wybierz nowe hasło.',
  'password-requirements':
    'Twoje hasło musi składać się z co najmniej 8 znaków, zawierać co najmniej 1 wielką literę, 1 małą literę, 1 cyfrę i 1 z następujących znaków specjalnych! @ # $ % | ^',
  success: 'Powodzenie',
  'password-reset-success': 'Twoje hasło zostało pomyślnie zresetowane.',
  selectCompany: 'Wybierz firmę',
  button: 'Przycisk tekstowy',
  save: 'Ratować',
  text: 'dependent territories',
  username: 'Nazwa użytkownika',
  password: 'Hasło',
  login: 'Zaloguj sie',
  ssoLogin: 'Bezpieczny login z SSO',
  required: 'Wymagany',
  light: 'Światło',
  dark: 'Ciemny',
  rememberMe: 'Zapamiętaj mnie',
  eula: 'Eula',
  copyright: 'Inc.',
  privacyPolicy: 'Polityka prywatności',
  customer: 'Klient',
  project: 'Projekt',
  isBillable: 'Rozliczalny',
  dispatchDateTime: 'Data',
  orderId: 'Zamówienie',
  ticketId: 'Bilet',
  location: 'Lokalizacja',
  vehicleTypeName: 'Pojazd',
  product: 'Produkt',
  salesTax: 'Podatek od sprzedaży',
  totalPrice: 'Całkowity',
  extendedPrice: 'Ext',
  destination: 'Miejsce docelowe',
  productId: 'ID produktu',
  statusCode: 'Status',
  vehicleTypeId: 'Identyfikator typu pojazdu',
  locationId: 'Identyfikator lokalizacji',
  customerId: 'Identyfikator klienta',
  projectId: 'Identyfikator projektu',
  dispatchTime: 'Czas',
  'forgot-mail': 'Zapomniałem e -maila',
  showAll: 'Pokaż wszystko',
  hideAll: 'Schowaj wszystko',
  undoChanges: 'Cofnij zmiany',
  resetColumns: 'Zresetuj kolumny',
  displayRowCount: `Displaying {count, plural, 
    =0 {0 rows} 
    jeden {# row} 
    inny {# rows} 
  }`,
  columnFilters: 'Filtry kolumn',
  visible: 'Widoczny',
  hidden: 'Ukryty',
  topSection: 'Najwyższa sekcja',
  itemDetail: 'Szczegóły elementu',
  quantity: 'Ilość',
  unloadingMethod: 'Metoda rozładunku',
  time: 'Czas',
  eventStatus: 'Status',
  MTQ: `{value, plural,
    =0 {0 m\u00B3}
    jeden {1 m\u00B3}
    inny {# m\u00B3}
  }`,
  LTR: `{value, plural,
    =0 {0 Liters}
    jeden {1 Liter}
    inny {# Liters}
  }`,
  TO: `{value, plural,
    =0 {0 Tons}
    jeden {1 Ton}
    inny {# Tons}
  }`,
  ITEM: `{value, plural,
    =0 {0 Items}
    jeden {1 Item}
    inny {# Items}
  }`,
  YDQ_short: `{value, plural,
    =0 {0 CY}
    jeden {1 CY}
    inny {# CY}
  }`,
  YDQ: `{value, plural,
    =0 {0 Cubic Yards}
    jeden {1 Cubic Yard}
    inny {# Cubic Yards}
  }`,
  mixIdLabel: 'Mix Id/Opis',
  orderedQtyLabel: 'Zamówiono Ilość',
  loadedQtyLabel: 'Załadowana Ilość',
  END_UNLOADING: 'Zakończ rozładunek',
  IN_YARD: 'Na podwórku',
  TO_JOB: 'Lewa roślina',
  LEAVE_JOB: 'Opuść pracę',
  CREATED: 'Bilet',
  UNLOADING: 'Zacznij rozładować',
  ARRIVE_JOB: 'Przyjeżdża strona',
  plantName: 'Nazwa rośliny',
  plantAddress: 'Adres rośliny',
  loadingScheduleStart: 'Ładowanie zaplanowanej daty i godziny rozpoczęcia',
  deliveryAddress: 'Adres dostawy',
  phoneNumber: 'Numer telefonu',
  unloadingScheduleStart: 'Rozładunek zaplanowanej daty i godziny rozpoczęcia',
  startToFinishUnloadingTime: 'Rozładowanie początkowe - Data zakończenia i godzina',
  deliveryInformation: 'Informacje o dostawie',
  next: 'Następny',
  material: 'Materiał',
  loadingPoint: 'Punkt załadunku',
  unloadingPoint: 'Punkt rozładunku',
  camera: 'Kamera',
  comment: 'Komentarz',
  sapDeliveryNumber: 'Numer dostawy SAP (numer BCC)',
  salesOrderNumber: 'Numer zamówienia sprzedaży i linia przedmiotów',
  wcDateTime: 'Data i godzina w/c kontakt',
  po: 'numer PO',
  orderedByName: 'Zamówienie złożone według nazwy',
  orderedByPhone: 'Zamówienie złożone według numeru telefonu',
  resourceId: 'Identyfikator zasobów',
  totalLoadedQty: 'Całkowite załadowana Ilość',
  spacing: 'Obciążenie',
  deliveryFlow: 'Przepływ dostawy',
  timeOnSite: 'Całkowity czas na witrynie/zamówienie czasu rozładunku',
  salesText: 'Tekst sprzedaży (identyfikator materiału)',
  strengthClass: 'Klasa siły',
  environmentExposure: 'Ekspozycja na środowisko',
  metExposureClasses: 'Met Zajęcia narażenia',
  dmax: 'Dmax',
  standardCert: 'Standard (certyfikacja)',
  workabilityTarget: 'Cel pracy klienta',
  stoneQuality: 'Jakość kamienia',
  sandQuality: 'Jakość piasku',
  targetWC: 'Docelowy stosunek w/c z przepisu',
  controlClass: 'Klasa kontrolna',
  chlorideClass: 'Klasa chlorkowa',
  typeAndStrength1: 'Rodzaj cementu i klasa siły 1',
  typeAndStrength2: 'Rodzaj cementu i klas siły 2',
  typeOfAddition: 'Rodzaj dodawania',
  typeOfAdmixture: 'Rodzaj domieszki',
  orderedTemp: 'Zamówiono temperaturę',
  fibers1: 'Typ i zawartość włókien 1',
  fibers2: 'Typ i zawartość włókien 2',
  calculatedMaturityTime: 'Obliczony czas dojrzałości od czasu kontaktu W/C',
  generalDelivery: 'Informacje o dostawie ogólnej',
  materialInfo: 'Informacje materialne',
  cardTitle: 'Tytuł karty',
  cardSubtitle: 'Podtytuł karty',
  cardBody: 'Korpus karty',
  alertTitle: 'Stary człowiek i morze',
  alertMessage:
    'Był to stary człowiek, który samotnie łowił ryby na łodzi w Prądzie Zatokowym i już osiemdziesiąt cztery dni nie złowił ryby.',
  water: 'Woda',
  materialService: 'Materiał / usługa',
  returnedMaterial: 'Zwrócony materiał',
  waterAdded: 'Dodano wodę',
  concreteOnTruck: 'Beton na ciężarówce',
  reason: 'Powód',
  customerAddedWater: 'Klient dodał wodę',
  addWater: 'Dodaj wodę',
  addedBy: 'Dodane przez',
  addMaterialService: 'Dodaj materiał/usługę',
  customerAddedProduct: 'Klient dodał produkt',
  addReturnedMaterial: 'Dodaj zwrócony materiał',
  leftover: 'Resztki',
  submitAcceptance: 'Prześlij akceptację',
  customerAcceptance: 'Akceptacja klienta',
  materialSummary: 'Podsumowanie materiału',
  mix: 'Mieszać',
  ordered: 'Zamówiono',
  loaded: 'Załadowany',
  driverComment: 'Komentarz kierowcy',
  customerComment: 'Komentarz klienta',
  editComment: 'Edytuj komentarz',
  addComment: 'Dodaj komentarz',
  additionalInfo: 'Dodatkowe informacje',
  onBoard: 'Na pokładzie',
  driver: 'Kierowca',
  commentInstruction: 'Instrukcja komentarza',
  signatureInstruction: 'Instrukcja podpisu',
  customerSignature: 'Podpis klienta',
  general: 'Ogólny',
  tabTwoStringId: 'Tab drugie',
  tabThreeStringId: 'Tab trzeci',
  status: 'Status',
  updateStatusTime: 'Zaktualizuj czas statusu',
  delete: 'Usuwać',
  cancel: 'Anulować',
  back: 'Z powrotem',
  add: 'Dodać',
  id: 'ID',
  name: 'Nazwa',
  invoiceable: 'Faktura',
  provideSignature: 'Zapewnij podpis',
  ticket: 'Bilet',
  details: 'Detale',
  qrCode: 'Qr',
  driverAcceptanceReason: 'Powód',
  driverAcceptance: 'Akceptacja kierowcy',
  'payment-method': 'Metody Płatności',
  'add-payment-method': 'Dodaj metodę płatności',
  'cancel-reason-code': 'Anuluj powody',
  'add-cancel-reason-code': 'Dodaj powód',
  companies: 'Firmy',
  profiles: 'Profile',
  logout: 'Wyloguj',
  online: 'online',
  offline: 'Offline',
  mt: 'Mobilny bilet',
  admin: 'Administrator platformy',
  profileEditor: 'Edytor profilu',
  firstname: 'Imię',
  lastname: 'Nazwisko',
  email: 'Adres e -mail',
  mobilePhone: 'Telefon komórkowy',
  currentPassword: 'Aktualne hasło',
  oldPassword: 'Aktualne hasło',
  newPassword: 'nowe hasło',
  newPasswordVerify: 'Nowe hasło (weryfikacja)',
  passwordsCantMatch: 'Nowe hasło nie może być takie samo jak bieżące hasło!',
  passwordsDontMatch: 'Hasła nie pasują!',
  passwordRequirements:
    'Nowe hasło musi: mieć od 8 do 99 znaków. Dołącz 1 lub więcej znaków numerycznych. Dołącz 1 lub więcej liter o niższych przypadkach. Dołącz 1 lub więcej liter górnych. Dołącz 1 lub więcej z następujących znaków specjalnych! @ # $ % ^',
  usernameInvalid: 'Zła nazwa użytkownika',
  passwordMinCharacters: 'Hasło musi zawierać 8 lub więcej znaków.',
  passwordMaxCharacters: 'Hasło nie może zawierać więcej niż 99 znaków.',
  passwordLowerCase: 'Hasło musi zawierać 1 lub więcej literę o niższej liście.',
  passwordUpperCase: 'Hasło musi zawierać 1 lub więcej litery górnej skrzynki.',
  passwordNumber: 'Hasło musi zawierać 1 lub więcej znaku numerycznego.',
  passwordSpecialCharacter: 'Hasło musi zawierać 1 lub więcej z następujących znaków specjalnych! @ # $ % ^',
  forgotPassword: 'Zapomniałeś hasła',
  forgotPasswordInstructions: 'Instrukcje',
  code: 'Kod weryfikacyjny',
  changePassword: 'Zmień hasło',
  'change-password-success': 'Twoje hasło zostało pomyślnie zmienione.',
  'change-password-error':
    'Nie można zmienić Twojego hasła. Upewnij się, że Twoje obecne hasło jest prawidłowe i spróbuj ponownie.',
  'forgetMe-success': 'Zostałeś zapomniany.',
  'forgetMe-error': 'Wystąpił błąd. Upewnij się',
  'forget-me-warning':
    '<p>Ta czynność spowoduje usunięcie wszystkich Twoich danych osobowych z naszych systemów, po czym nastąpi wylogowanie i niemożliwe będzie ponowne zalogowanie. </p> Jeśli chcesz kontynuować, wprowadź poniżej swoje hasło.',
  apps: 'Aplikacje',
  settings: 'Profil',
  lastName: 'Nazwisko',
  firstName: 'Imię',
  forgetMe: 'Zapomnij o mnie',
  manageProfile_title: 'Zarządzaj profilem',
  manageProfile_general: 'Ogólny',
  manageProfile_credentials: 'Referencje',
  manageProfile_preferences: 'Preferencje',
  manageProfile_notifications: 'Powiadomienia',
  manageProfile_general_firstName: 'Imię',
  manageProfile_general_lastName: 'Nazwisko',
  manageProfile_general_phone: 'Telefon komórkowy',
  manageProfile_general_email: 'Adres e -mail',
  manageProfile_general_phoneError: 'Nieprawidłowy telefon.',
  manageProfile_general_emailError: 'Niewłaściwy format wiadomości email.',
  profileImage_title: 'Zdjęcie profilowe',
  profileImage_description:
    'Zdjęcie pomaga innym Cię rozpoznać i informuje Cię, kiedy jesteś zalogowany na swoje konto',
  profileImage_change_btn: 'Zmiana',
  profileImage_delete_btn: 'Usunąć',
  profileImage_drag_photo_description: 'Przeciągnij zdjęcie tutaj',
  profileImage_drag_photo_or: '- Lub -',
  profileImage_upload_from_computer_btn: 'Prześlij z komputera',
  profileImage_rotate_btn: 'Obracać się',
  profileImage_file_size_error: 'Plik jest za duży. Wybierz plik mniejszy niż 5 MB.',
  profileImage_update_sucess_msg: 'Obraz profilu pomyślnie zaktualizowany',
  profileImage_update_error_msg: 'Błąd aktualizacja obrazu profilu',
  profileImage_delete_sucess_msg: 'Obraz profilowy pomyślnie usunięty',
  profileImage_delete_error_msg: 'Błąd usuwanie obrazu profilu',
  manageProfile_credentials_oldPassword: 'Aktualne hasło',
  manageProfile_credentials_newPassword: 'nowe hasło',
  manageProfile_credentials_confirmNewPassword: 'Potwierdź nowe hasło',
  manageProfile_credentials_description: 'Nowe hasło musi:',
  manageProfile_credentials_reqLength: 'Mieć od 8 do 99 znaków.',
  manageProfile_credentials_reqNumber: 'Dołącz 1 lub więcej znaków numerycznych.',
  manageProfile_credentials_reqLowerCase: 'Dołącz 1 lub więcej liter o niższych przypadkach.',
  manageProfile_credentials_reqUpperCase: 'Dołącz 1 lub więcej liter górnych.',
  manageProfile_credentials_reqSpecialCharacter:
    'Dołącz 1 lub więcej znaków specjalnych z następującego zestawu: ^ $ * . [] () ? - " ! @ # % & / , > ; | _ ~ `',
  manageProfile_credentials_wrongOldPassword: 'Złe hasło. Proszę spróbuj ponownie.',
  manageProfile_cancel: 'Anulować',
  manageProfile_save: 'Ratować',
  manageProfile_forget: 'Zapominać',
  forgetMe_title: 'Zapomnij o tym koncie?',
  forgetMe_description: 'To usunie twoje poświadczenia z prefill logowania',
  login_header: 'Zaloguj się do twoich poświadczeń poniżej',
  cookies_main_title: 'Polityka Cookie',
  cookies_main_description:
    'Używamy plików cookie, aby poprawić Twoje doświadczenia. Klikając „Akceptuj wszystko”, wyrażasz zgodę na używanie wszystkich plików cookie na swoim urządzeniu. Możesz zarządzać swoimi preferencjami dotyczącymi plików cookie, klikając „Dowiedz się więcej”.',
  cookies_learn_more_btn: 'Ucz się więcej',
  cookies_refuse_all_btn: 'Odmów wszystkich',
  cookies_accept_all_btn: 'Akceptuj wszystkie',
  cookies_back_btn: 'Wróć',
  cookies_details_title: 'Preferencje plików cookie',
  cookies_details_notifications:
    '<strong>Opcja powiadomień krytycznych</strong>: powiadomienia krytyczne, pomoc online i dokumentacja pozwalają nam dostarczać użytkownikom niezbędne alerty, zapewniać pomoc online oraz udostępniać wiadomości i aktualizacje. Zapewnia to użytkownikom powiadomienia w aplikacji, przerwy w świadczeniu usług i dostęp do ogłoszeń o nowych funkcjach.',
  cookies_details_technical_support:
    '<strong>Opcja pomocy technicznej</strong>: pomoc techniczna pozwala nam śledzić i monitorować funkcje, do których uzyskujesz dostęp. Pomaga nam to w proaktywnym identyfikowaniu i rozwiązywaniu problemów, często zanim zostaną one zgłoszone.',
  cookies_confirmation_msj: 'Polityka plików cookie',
  cookies_reject_description:
    'Niestety, bez wymaganych plików cookie nie będziesz mógł korzystać z aplikacji. Możesz zarządzać swoimi preferencjami dotyczącymi plików cookie, klikając „Dowiedz się więcej”.',
  defaultLanding_header: 'Wybierz aplikację do rozpoczęcia',
  defaultLanding_header_noLicenses: 'Obecnie nie masz dostępu do żadnych oprogramowania Alkon Command ALKON',
  defaultLanding_description_noLicenses: 'Jeśli uważasz, że wystąpił błąd, skontaktuj się z administratorem witryny.',
  enforceEntity_selectCompany: 'Wybierz firmę',
  enforceEntity_noMatchCompany:
    "Nessuna compagnia corrisponde con i tuoi criteri di ricerca. Si prega di perfezionare la ricerca o contattare l'amministratore del sito se si ritiene che ci sia stato un errore.",
  search: 'Szukaj',
  noResultsFound: 'Nie znaleziono wyników',
  accountRecovery: 'odzyskiwanie konta',
  accountRecovery_content:
    'Zapomniałeś hasła? Wpisz adres e-mail przypisany do Twojego konta w odpowiednim miejscu, a następnie wybierz opcję <strong>Wyślij kod</strong>. Wkrótce powinieneś otrzymać wiadomość e-mail zawierającą kod umożliwiający zresetowanie hasła.',
  accountRecovery_usernameInvalid: 'Proszę wpisać aktualny adres e-mail.',
  accountRecovery_sendCode: 'Wyślij kod',
  accountRecovery_backToLogin: 'Powrót do logowania',
  accountRecovery_verification: 'Weryfikacja',
  accountRecovery_verification_content:
    'Aby chronić Twoje konto, Command Alkon chce się upewnić, że to naprawdę Ty. Wprowadź sześciocyfrowy kod weryfikacyjny wysłany na Twój adres e-mail, aby zweryfikować swoje konto.',
  accountRecovery_verification_code: 'Wpisz kod',
  accountRecovery_code_sent_message: '{ikona} kod weryfikacyjny wysłany na adres e -mail',
  accountRecovery_errorCode: 'Nieprawidłowy kod weryfikacyjny. Sprawdź swój e -mail i spróbuj ponownie.',
  accountRecovery_createNewPassword: 'Utwórz nowe hasło',
  accountRecovery_password: 'Hasło',
  accountRecovery_errorPassword: 'Nieprawidłowy format hasła. Upewnij się, że Twoje hasło spełnia wymagane kryteria.',
  accountRecovery_passwordHint:
    'Użyj 8–99 znaków i umieść co najmniej jedną cyfrę, dużą i małą literę oraz jeden znak specjalny z następującego zestawu: ^ $ * . [] () ? - " ! @ # % & / , > ; | _ ~ `',
  accountRecovery_resetPasswordButton: 'Zresetuj hasło',
  accountRecovery_passwordChanged: 'Reset hasło pomyślnie',
  accountRecovery_invalidVerificationCode: 'nieprawidłowy kod weryfikacyjny',
  general_notification_copy: 'Skopiowane do schowka',
  general_serverError: 'Błąd serwera',
  general_email: 'E-mail',
  general_code: 'Kod',
  general_submit: 'Składać',
  errors,
  themes,
};
