import React from 'react';
import { Outlet } from 'react-router-dom';
import cn from 'classnames';

import { PublicLayoutContainer } from './styles';

const PublicLayout = _props => {
  return (
    <PublicLayoutContainer className={cn('public-layout')}>
      <Outlet />
    </PublicLayoutContainer>
  );
};

export default PublicLayout;
