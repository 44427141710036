import React, { useState, useCallback, useMemo } from 'react';
import { Localization, Header } from 'connex-cds';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { useAppContext } from '../../../providers/AppProvider';
import { useEntityContext } from '../../../providers/entity-provider/EntityProvider';
import { useUserContext } from '../../../providers/UserProvider';
import EntityOption from './EntityOption';
import EmptySearch from './EmptySearch';
import { BaseDarkContainer, SearchFilter } from '../../../commons';
import { getFilteredDataByProperties } from '../../../commons/search-filter';
import { Profile } from '../../../views/authenticated-layout/profile/Profile';

import styles from './styles';

const Styled = styled.div`
  ${styles}
`;

const SelectEntity = ({ appEntities, onChange }) => {
  const [query, setQuery] = useState('');
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const appContext = useAppContext();
  const entityContext = useEntityContext();
  const userContext = useUserContext();

  const filteredEntities = useMemo(
    () => getFilteredDataByProperties({ data: appEntities, query, properties: ['name'] }),
    [appEntities, query]
  );

  const handleProfileClick = useCallback(() => {
    setIsProfileOpen(true);
  }, []);

  const handleEntityClick = entityRef => () => onChange(entityRef);

  return (
    <>
      <Header
        className="connex-header"
        appContext={appContext}
        entityContext={entityContext}
        userContext={userContext}
        onChange={appContext.setAppRef}
        onProfileClick={handleProfileClick}
        disableAppTray
        disableEntityPicker
      />
      <Profile isOpen={isProfileOpen} setIsOpen={setIsProfileOpen} />
      <Styled>
        <BaseDarkContainer className="container">
          <h1 className="title">
            <Localization.Translate stringId="enforceEntity_selectCompany" data-testid="select-company" />
          </h1>
          <SearchFilter onSearchChange={setQuery} placeholderStringId="search" />
          {!!filteredEntities.length ? (
            <ul className="entities-container">
              {filteredEntities.map(({ entityRef, name }) => (
                <EntityOption key={entityRef} entityRef={entityRef} name={name} handleEntityClick={handleEntityClick} />
              ))}
            </ul>
          ) : (
            <EmptySearch />
          )}
        </BaseDarkContainer>
      </Styled>
    </>
  );
};

SelectEntity.propTypes = {
  appEntities: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SelectEntity;
