import { cacheItem, getCachedItem, usePostMessageListener } from 'connex-cds';
import { isEmpty } from 'lodash';
import React, { useContext } from 'react';
import { useQueryParams } from '../../QueryParamContext';
import sendMessage from '../../util/sendMessage';
import { useAppContext } from '../AppProvider';
import { useAuthContext } from '../AuthProvider';
import { usePermissions } from './usePermissions';

const EntityContext = React.createContext();

const sendEntityContext = context => {
  sendMessage({ type: 'entity-context', ...context });
};

export const useEntityContext = () => {
  const context = useContext(EntityContext);
  if (!context) {
    throw new Error(`useEntityContext cannot be rendered outside of the EntityContext context provider. `);
  }
  return context;
};

const EntityContextProvider = ({ children }) => {
  const { logout } = useAuthContext();
  const { appEntities } = useAppContext();
  const [entityRef, _setEntityRef] = React.useState(getCachedItem('entityRef'));

  const { permissions, query } = usePermissions({ entityRef });

  const tokenOverride = useQueryParams();

  const token = React.useMemo(() => {
    if (!isEmpty(tokenOverride)) {
      return { entityRef: tokenOverride.entityRef, value: tokenOverride.aet };
    }
    return permissions;
  }, [permissions, tokenOverride]);

  const setEntityRef = React.useCallback(ref => {
    cacheItem('entityRef', ref);
    _setEntityRef(ref);
  }, []);

  React.useEffect(() => {
    if (appEntities?.length === 1) _setEntityRef(appEntities[0].entityRef);
  }, [appEntities]);

  React.useEffect(() => {
    if (entityRef === token?.entityRef) {
      const entityName = appEntities?.find(e => e.entityRef === entityRef)?.name;
      sendEntityContext({ entityRef, token, entityName });
    }
  }, [appEntities, entityRef, token]);

  const entityContextListener = React.useCallback(
    message => {
      if (message.type === 'req-entity-context') {
        const entityName = appEntities?.find(e => e.entityRef === entityRef)?.name;
        sendEntityContext({ entityRef, token, entityName });
      }

      if (message.type === 'entity-ref') {
        setEntityRef(message.entityRef);
      }
    },
    [appEntities, entityRef, setEntityRef, token]
  );

  usePostMessageListener(entityContextListener);

  if (query.status === 'error') {
    logout();
  }

  return <EntityContext.Provider value={{ setEntityRef, entityRef }}>{children}</EntityContext.Provider>;
};

export default EntityContextProvider;
