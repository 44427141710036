import React, { useState, useEffect, useMemo, useCallback, createContext, useContext } from 'react';
import { cacheItem, getCachedItem, usePostMessageHandler } from 'connex-cds';

import sendMessage from '../util/sendMessage';
import { USER_KEY, USER_APPS_KEY } from '../constants/user';

export const UserContext = createContext();

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error(`useUserContext cannot be rendered outside of the UserContext context provider`);
  }
  return context;
};

export const UserProvider = ({ children }) => {
  const cachedUser = getCachedItem(USER_KEY);
  const cachedUserApps = getCachedItem(USER_APPS_KEY);

  const [user, _setUser] = useState(cachedUser ? JSON.parse(cachedUser) : null);
  const [userApps, _setUserApps] = useState(cachedUserApps ? JSON.parse(cachedUserApps) : []);
  const [userAppsIsLoading, setUserAppsIsLoading] = useState(false);
  const [role, setRole] = useState();

  useEffect(() => {
    sendMessage({ type: 'user-context', user, role, userApps });
  }, [role, user, userApps]);

  const userContextListener = useCallback(
    event => {
      const message = event?.data;

      if (message?.type === 'req-user-context') {
        sendMessage({ type: 'user-context', user, role, userApps });
      }
    },
    [role, user, userApps]
  );

  usePostMessageHandler(userContextListener);

  const setUser = useCallback(user => {
    if (user) {
      cacheItem(USER_KEY, JSON.stringify(user));
      _setUser(user);
    }
  }, []);

  const setUserApps = useCallback((apps = []) => {
    cacheItem(USER_APPS_KEY, JSON.stringify(apps));
    _setUserApps(apps);
  }, []);

  const hasPermission = useCallback(
    permission => {
      return role?.permissions?.[permission] || false;
    },
    [role?.permissions]
  );

  const permissions = useMemo(
    () => Object.keys(role?.permissions || {}).filter(key => !!role?.permissions?.[key]),
    [role?.permissions]
  );

  useEffect(() => {
    if (user) {
      const displayName = `${user?.firstName || ''} ${user?.lastName || ''}`.trim() || undefined;
      window?.FS?.identify?.(user?.profileRef, { displayName });
    } else {
      window?.FS?.anonymize?.();
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        userApps,
        setUserApps,
        userAppsIsLoading,
        setUserAppsIsLoading,
        role,
        setRole,
        hasPermission,
        permissions,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
