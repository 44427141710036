import { IconButton } from '@mui/material';
import { func, bool } from 'prop-types';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import InputTextBase from '../input-text-base/InputTextBase';

const TextInput = ({ withClearAdornment, getInputProps, ...props }) => {
  return (
    <InputTextBase
      {...props}
      getInputProps={({ name, setFieldValue }) => ({
        ...getInputProps({ name, setFieldValue }),
        endAdornment: (
          <>
            {getInputProps({ name, setFieldValue })?.endAdornment}
            {withClearAdornment && (
              <IconButton
                aria-label="toggle"
                onClick={() => {
                  setFieldValue(name, '');
                }}
                onMouseDown={null}
                edge="end"
              >
                <HighlightOffIcon />
              </IconButton>
            )}
          </>
        ),
      })}
    />
  );
};

TextInput.propTypes = {
  withClearAdornment: bool,
  getInputProps: func,
};

TextInput.defaultProps = {
  withClearAdornment: false,
  getInputProps: () => {},
  type: 'text',
};

export default TextInput;
