import HelpOutlineOutlined from '@mui/icons-material/HelpOutlined';
import Business from '@mui/icons-material/Business';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined';
import ApprovalOutlined from '@mui/icons-material/ApprovalOutlined';
import DoNotDisturbAltOutlined from '@mui/icons-material/DoNotDisturbAltOutlined';
import LogoutOutlined from '@mui/icons-material/LogoutOutlined';
import SupportAgentOutlined from '@mui/icons-material/SupportAgentOutlined';
import PermIdentityOutlined from '@mui/icons-material/PermIdentityOutlined';
import LanguageOutlined from '@mui/icons-material/LanguageOutlined';
import CreateIcon from '@mui/icons-material/Create';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import FilterIcon from '@mui/icons-material/Filter';
import ComputerIcon from '@mui/icons-material/Computer';
import CropRotateIcon from '@mui/icons-material/CropRotate';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';

const iconList = {
  default: props => <HelpOutlineOutlined {...props} />,
  business: props => <Business {...props} />,
  keyboardArrowRight: props => <KeyboardArrowRight {...props} />,
  arrowBackOutlined: props => <ArrowBackOutlined {...props} />,
  approvalOutlined: props => <ApprovalOutlined {...props} />,
  doNotDisturbAltOutlined: props => <DoNotDisturbAltOutlined {...props} />,
  logoutOutlined: props => <LogoutOutlined {...props} />,
  supportAgentOutlined: props => <SupportAgentOutlined {...props} />,
  permIdentityOutlined: props => <PermIdentityOutlined {...props} />,
  languageOutlined: props => <LanguageOutlined {...props} />,
  createIcon: props => <CreateIcon {...props} />,
  deleteOutlineIcon: props => <DeleteOutlineIcon {...props} />,
  filterIcon: props => <FilterIcon {...props} />,
  computerIcon: props => <ComputerIcon {...props} />,
  cropRotateIcon: props => <CropRotateIcon {...props} />,
  zoomInIcon: props => <ZoomInIcon {...props} />,
  zoomOutIcon: props => <ZoomOutIcon {...props} />,
  cameraAltOutlinedIcon: props => <CameraAltOutlinedIcon {...props} />,
};
export default iconList;
