import { useState } from 'react';
import { Localization } from 'connex-cds';
import Dialog from '@mui/material/Dialog';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PersonIcon from '@mui/icons-material/Person';
import KeyIcon from '@mui/icons-material/Key';
import RoomPreferencesOutlinedIcon from '@mui/icons-material/RoomPreferencesOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import styled from '@emotion/styled';

import { DialogTitle } from '../../../../commons';
import General from './components/General';
import Credentials from './components/Credentials/Credentials';

import styles from './style';

const Styled = styled(Dialog)`
  ${styles}
`;

const ManageProfileDialog = ({ onClose, open, onForgetMeClick, onUploadProfileImageClick }) => {
  const [currentTab, setCurrentTab] = useState(0);

  const handleChange = (_, newValue) => {
    setCurrentTab(newValue);
  };

  const handleClose = () => {
    onClose();
    setCurrentTab(0);
  };

  const handleCloseDialog = (e, reason) => {
    if (reason === 'escapeKeyDown') {
      handleClose();
    }
  };

  const MANAGE_PROFILE_TABS = [
    {
      stringId: 'manageProfile_general',
      component: <General onUploadProfileImageClick={onUploadProfileImageClick} closeModal={handleClose} />,
      icon: <PersonIcon />,
    },
    {
      stringId: 'manageProfile_credentials',
      component: <Credentials closeModal={handleClose} onForgetMeClick={onForgetMeClick} />,
      icon: <KeyIcon />,
    },
    {
      stringId: 'manageProfile_preferences',
      component: <></>,
      icon: <RoomPreferencesOutlinedIcon />,
    },
    {
      stringId: 'manageProfile_notifications',
      component: <></>,
      icon: <NotificationsNoneOutlinedIcon />,
    },
  ];

  return (
    <Styled className="profile-dialog" open={open} maxWidth="md" fullWidth scroll="paper" onClose={handleCloseDialog}>
      <DialogTitle onClose={handleClose}>
        <PersonOutlineOutlinedIcon fontSize="inherit" />
        <Localization.Translate stringId="manageProfile_title" />
      </DialogTitle>
      <Tabs className="profile-dialog__tabs" value={currentTab} onChange={handleChange} variant="fullWidth">
        {MANAGE_PROFILE_TABS.map(({ stringId, icon }) => (
          <Tab key={stringId} icon={icon} label={<Localization.Translate stringId={stringId} />} />
        ))}
      </Tabs>
      {MANAGE_PROFILE_TABS[currentTab].component}
    </Styled>
  );
};
export default ManageProfileDialog;
