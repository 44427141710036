import cn from 'classnames';
import { Core, Form } from 'connex-cds';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import auth from '../../../../api/auth';
import { useAuthContext } from '../../../../providers/AuthProvider';
import { useEntityContext } from '../../../../providers/entity-provider/EntityProvider';
import { useUserContext } from '../../../../providers/UserProvider';
import { ForgetMeForm } from './ForgetMeForm';
import config from './form-config';

const Styled = styled.div`
  .ant-alert {
    margin-bottom: 20px;
  }
`;

export const ForgetMe = () => {
  const { logout } = useAuthContext();
  const { reset } = useEntityContext();
  const { user } = useUserContext();
  const navigate = useNavigate();

  const [error, setError] = React.useState(false);

  const handleSubmit = React.useCallback(
    data => {
      setError(false);

      return auth
        .forgetMe({ profileRef: user?.profileRef, data })
        .then(() => {
          navigate('/login', {
            state: { message: { stringId: 'forgetMe-success', severity: 'success', noTitle: true } },
          });
          logout();
          reset();
        })
        .catch(() => {
          setError(true);
        });
    },
    [logout, navigate, reset, user?.profileRef]
  );

  return (
    <Styled className={cn('forget-me')}>
      <Form.FormProvider config={config} onSubmit={handleSubmit}>
        {error && <Core.Alert severity={Core.SEVERITY.ERROR} message="forgetMe-error" closable showIcon />}
        <ForgetMeForm />
      </Form.FormProvider>
    </Styled>
  );
};
