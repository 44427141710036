import React from 'react';
import { Localization } from 'connex-cds';
import styled from '@emotion/styled';

import { useAppContext } from '../../providers/AppProvider';
import { useUserContext } from '../../providers/UserProvider';
import { BaseDarkContainer, LoadingRedLineLoader } from '../../commons';

import styles from './styles';

const Styled = styled.div`
  ${styles}
`;

const appRefToIconMap = {
  'dispatch-exp': 'app-icon-dispatch.svg',
  'company-admin': 'app-icon-admin.svg',
  assurance: 'app-icon-assurance.svg',
  mt: 'app-icon-deliveries.svg',
  admin: 'app-icon-platform-admin.svg',
};

const DefaultLandingPage = () => {
  const { userApps, userAppsIsLoading } = useUserContext();
  const { setAppRef, allApps } = useAppContext();
  // TODO: Determine where to send the user.  Perhaps the user's preferred starting location, their last known position, etc.

  const filteredApps = React.useMemo(() => {
    if (userAppsIsLoading || !userApps?.length) return [];

    const caiApps = allApps?.filter(app => app.type === 'CX_APP_CAI').map(app => app.crn);

    return (
      userApps
        .filter(app => caiApps?.includes?.(app.appRef))
        .map(app => ({
          ...app,
          appIconImageRef: appRefToIconMap[app.appRef] || 'app-icon-default.svg',
        }))
        .sort(app => (appRefToIconMap[app.appRef] ? -1 : 1)) || []
    );
  }, [userAppsIsLoading, userApps, allApps]);

  React.useEffect(() => {
    setAppRef(undefined);
  }, [setAppRef]);

  React.useEffect(() => {
    if (filteredApps?.length === 1) {
      setAppRef(filteredApps[0].appRef);
    }
  }, [filteredApps, setAppRef]);

  const handleAppChange = appRef => () => {
    setAppRef(appRef);
  };

  return (
    <Styled className="default-landing">
      <BaseDarkContainer className="container">
        {!userAppsIsLoading ? (
          <>
            <h1 className="landing-title">
              {!!filteredApps.length ? (
                <Localization.Translate stringId="defaultLanding_header" />
              ) : (
                <Localization.Translate stringId="defaultLanding_header_noLicenses" />
              )}
            </h1>
            {!!filteredApps.length ? (
              <ul className="apps-container">
                {filteredApps?.map?.(app => (
                  <li className="app-icon" onClick={handleAppChange(app.appRef)} key={app.appRef}>
                    <img src={require(`../../assets/${app.appIconImageRef}`)} alt={app.name} />
                    {app.appIconImageRef === 'app-icon-default.svg' ? (
                      <span className="app-name-default">{app.name}</span>
                    ) : (
                      <></>
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="landing-description">
                <Localization.Translate stringId="defaultLanding_description_noLicenses" />
              </p>
            )}
          </>
        ) : (
          <LoadingRedLineLoader className="lottie-animation" />
        )}
      </BaseDarkContainer>
    </Styled>
  );
};

export default DefaultLandingPage;
