import React, { useState, useCallback } from 'react';
import { Localization } from 'connex-cds';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import cn from 'classnames';

import StepOne from './step-one';
import StepTwo from './step-two';
import StepThree from './step-three';
import StepFour from './step-four';
import api from '../../../api/auth';
import { useToast } from '../../../components/Toast';
import { BAD_REQUEST } from '../../../constants/status-codes';
import { useCountdownTimer } from '../../../hooks';
import { LOADER_DURATION } from './constants';

import { AccountRecoveryContainer } from './styles';

const AccountRecovery = () => {
  const [verificationKey, setVerificationKey] = useState(null);
  const [step, setStep] = useState(1);
  const [user, setUser] = useState('');
  const [code, setCode] = useState('');
  const [message, setMessage] = useState({});
  const [codeIsLoading, setCodeIsLoading] = useState(false);

  const { seconds, isActive: showResendTimer, startTimer } = useCountdownTimer(15);

  const notify = useToast();
  const translateMessage = Localization.useTranslateMessage();

  const handleSendCode = useCallback(
    async (values, _actions) => {
      const { username } = values;
      startTimer();

      try {
        const response = await api.sendCode(values);

        if (response?.access_token) {
          setVerificationKey(response.access_token);
        }

        setStep(2);
        setUser(username);
        setMessage({ text: 'accountRecovery_code_sent_message', icon: <CheckCircleOutline /> });
      } catch (error) {
        notify.error(translateMessage('general_serverError'));
      }
    },
    [setStep, translateMessage, notify, setVerificationKey, startTimer]
  );

  const handleSubmitCode = useCallback(
    values => {
      setStep(3);
      setCode(values.code);
    },
    [setStep]
  );

  const handleChangePassword = useCallback(
    async values => {
      const { password } = values;
      try {
        await api.changePassword({ username: user, password, code, token: verificationKey });
        setStep(4);
      } catch (error) {
        if (error.status === BAD_REQUEST) {
          notify.error(translateMessage('accountRecovery_invalidVerificationCode'));
          setStep(2);
        } else {
          notify.error(translateMessage('general_serverError'));
        }
      }
    },
    [user, code, verificationKey, notify, translateMessage]
  );

  const handleResendCode = useCallback(async () => {
    setCodeIsLoading(true);
    setMessage({
      text: 'accountRecovery_code_resent_message',
      icon: <PendingOutlinedIcon className="pending-message" />,
    });
    startTimer();
    setVerificationKey(null);

    try {
      const response = await api.sendCode({ username: user });

      if (response.access_token) {
        setVerificationKey(response.access_token);

        setTimeout(() => {
          setMessage({ text: 'accountRecovery_code_sent_message', icon: <CheckCircleOutline /> });
        }, LOADER_DURATION);
      }
    } catch (error) {
      notify.error(translateMessage('general_serverError'));
    }

    setTimeout(() => {
      setCodeIsLoading(false);
    }, LOADER_DURATION);
  }, [setCodeIsLoading, notify, translateMessage, user, startTimer]);

  return (
    <AccountRecoveryContainer className={cn({ 'confirmation-size': step === 4 })} showLoader={codeIsLoading}>
      {step === 1 && <StepOne handleSendCode={handleSendCode} />}
      {step === 2 && (
        <StepTwo
          user={user}
          message={message}
          handleSubmitCode={handleSubmitCode}
          handleResendCode={handleResendCode}
          timer={seconds}
          showResendTimer={showResendTimer}
        />
      )}
      {step === 3 && <StepThree user={user} handleChangePassword={handleChangePassword} />}
      {step === 4 && <StepFour />}
    </AccountRecoveryContainer>
  );
};

export default AccountRecovery;
