import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { Localization } from 'connex-cds';
import { Field, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import auth from '../../../../api/auth';
import Left from '../../../../assets/Left.svg';
import Right from '../../../../assets/Right.svg';

import { DialogTitle, PasswordInputWithFormikField } from '../../../../commons';
import { useAuthContext } from '../../../../providers/AuthProvider';
import { useEntityContext } from '../../../../providers/entity-provider/EntityProvider';
import { useUserContext } from '../../../../providers/UserProvider';
import { initialValues, validationSchema } from './form-config';

import { StyledDialog, StyledDialogContent } from './styles';

const ForgetAccountDialog = ({ open, onCancel }) => {
  const { logout } = useAuthContext();
  const { reset } = useEntityContext();
  const { user } = useUserContext();
  const translateMessage = Localization.useTranslateMessage();
  const navigate = useNavigate();

  const handleOnSubmit = useCallback(
    async (values, actions) => {
      await auth
        .forgetMe({ profileRef: user?.profileRef, values })
        .then(() => {
          navigate('/login', {
            state: { message: { stringId: 'forgetMe-success', severity: 'success', noTitle: true } },
          });
          logout();
          reset();
          actions.resetForm();
        })
        .catch(() => {
          actions.setFieldError('password', translateMessage('forgetMe-error'));
        });
    },
    [logout, navigate, reset, user?.profileRef, translateMessage]
  );

  const handleCloseDialog = (e, reason) => {
    if (reason === 'escapeKeyDown') {
      onCancel();
    }
  };

  return (
    <StyledDialog
      className="profile-dialog"
      open={open}
      maxWidth="md"
      fullWidth
      scroll="paper"
      onClose={handleCloseDialog}
    >
      <DialogTitle className="dialog-title" onClose={onCancel}>
        <div className="login-logo">
          <img src={Left} alt="Command Alkon logo" />
          <img src={Right} alt="Command Alkon logo" />
        </div>
      </DialogTitle>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleOnSubmit}>
        {({ handleSubmit, errors, dirty, isSubmitting }) => {
          return (
            <Form className="forget-form" onSubmit={handleSubmit}>
              <StyledDialogContent className="forget-account" dividers>
                <h3>
                  <Localization.Translate stringId="forgetMe_title" />
                </h3>

                <p className="description">
                  <Localization.Translate stringId="forgetMe_description" />
                </p>

                <Field
                  required
                  component={PasswordInputWithFormikField}
                  testId="forget-password"
                  labelStringId="manageProfile_credentials_oldPassword"
                  variant="outlined"
                  name="password"
                  className="password-field"
                />
              </StyledDialogContent>
              <DialogActions className="dialog-actions">
                {/* <Button className="action-button" onClick={onCancel} variant="outlined">
                  <Localization.Translate stringId="manageProfile_cancel" />
                </Button> */}
                <Button
                  type="submit"
                  className="submit-button action-button"
                  variant="contained"
                  disabled={!!errors.password || isSubmitting || !dirty}
                >
                  <Localization.Translate stringId="manageProfile_forget" />
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </StyledDialog>
  );
};

export default ForgetAccountDialog;
