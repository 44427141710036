import cn from 'classnames';
import { Typography } from 'connex-cds';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const Styled = styled.div``;

export const Eula = () => {
  const location = useLocation();

  return (
    <Styled>
    </Styled>
  );
};
