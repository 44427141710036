import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordVisibility = ({ inputType, setInputType }) => {
  const handleClick = () => {
    setInputType(inputType === 'password' ? 'text' : 'password');
  };

  return (
    <InputAdornment position="end">
      <IconButton aria-label="toggle password visibility" onClick={handleClick} edge="end">
        {inputType === 'password' ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </InputAdornment>
  );
};

export default PasswordVisibility;
