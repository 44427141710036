import { css } from '@emotion/react';

export const ToastStyle = ({ theme }) => css`
  width: 300px;
  min-height: 80px;
  display: grid;
  grid-template-columns: 220px 80px;

  background-color: ${theme.validateMode({ light: theme.colorsCA.neutral[100], dark: theme.colorsCA.neutral[30] })};
  color: ${theme.validateMode({ dark: theme.colorsCA.neutral[100] })};

  border: 1px solid ${theme.validateMode({ light: '#CAC4D0', dark: '#49454F' })};
  border-radius: 12px;

  overflow: hidden;

  box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);

  .content-wrapper {
    display: flex;
    justify-content: start;
    align-items: center;

    padding: 16px;

    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
      text-align: left;
    }
  }

  .icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      font-size: 30px;
      color: ${theme.validateMode(theme.colorsCA.neutral[100])};
    }

    .material-icon {
      font-size: 40px;
      color: ${theme.validateMode(theme.colorsCA.neutral[100])};
    }
  }

  &.toast-success {
    .icon-wrapper {
      background-color: ${theme.validateMode(theme.colorsCA.green[40])};
    }
  }

  &.toast-error {
    .icon-wrapper {
      background-color: ${theme.validateMode(theme.colorsCA.red[60])};
    }
  }

  &.toast-info {
    .icon-wrapper {
      background-color: ${theme.validateMode(theme.colorsCA.blue[50])};
    }
  }
`;
