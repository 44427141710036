import React from 'react';
import { useHref } from 'react-router';
import { useLocation, useParams } from 'react-router-dom';
import { Core, getCachedItem, Layout, Localization } from 'connex-cds';
import cn from 'classnames';
import styled from 'styled-components';

import { useAppContext } from '../providers/AppProvider';
import { useEntityContext } from '../providers/entity-provider/EntityProvider';
import { usePermissions } from '../providers/entity-provider/usePermissions';
import { useThemeContext } from '../providers/ThemeProvider';
import { usePostMessageListener } from '../util/usePostMessageListener';
import { Profile } from '../views/core-apps/profile/Profile';
import { useAppConfig } from './util';

const Styled = styled(Layout.Column)`
  z-index: 1;
  position: relative;
  overflow: auto;

  .overlay {
    pointer-events: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .busy {
    display: none;
  }

  .spinner-text-color {
    color: #fff;
  }

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    background-color: var(--primary-background-color);
    display: flex;
    flex-direction: column;
    &.busy {
      display: none;
    }
  }
`;

export const ServiceIframe = () => {
  const { themeName } = useThemeContext();
  const { userLocale } = Localization.useLocalizationContext();
  const { appRef } = useParams();
  const { setAppRef, appPath } = useAppContext();
  const { entityRef } = useEntityContext();
  const location = useLocation();
  const href = useHref('');
  const [key, setKey] = React.useState(0);
  const config = useAppConfig({ appRef, location, href });
  const themeRef = React.useRef(themeName);
  const localeRef = React.useRef(userLocale);
  const [iframeLoading, setIframeLoading] = React.useState();
  const appContext = useAppContext();
  const permissions = usePermissions({ entityRef });

  const featurePath = React.useMemo(() => {
    if (!appPath) {
      return '';
    }

    const [ref, path] = appPath.split('::');

    if (ref === appRef) {
      // setAppPath(null);
      return `/${path || ''}`;
    }
    return '';
  }, [appRef, appPath]);

  React.useEffect(() => {
    setAppRef(appRef);
  }, [appRef, setAppRef]);

  React.useEffect(() => {
    themeRef.current = themeName;
  }, [themeName]);

  const xConnexId = React.useMemo(() => {
    return getCachedItem('x-connex-id');
  }, []);

  const _src = React.useMemo(() => {
    if (!config) {
      return 'data:text/html, <div style={{backgroundColor: var(--primary-background-color}}></div>';
    }

    const ms = new Date().getTime();

    let srcUrl = config.hostname;
    let localFeaturePath = featurePath;
    if (srcUrl[srcUrl.length - 1] === '/' && localFeaturePath[0] === '/') {
      localFeaturePath = localFeaturePath.substring(1);
    }

    const apiKeys = permissions?.query?.data?.apiKeys
      ? `&apiKeys=${encodeURIComponent(JSON.stringify(permissions?.query?.data?.apiKeys))}`
      : '';

    const apiKey = permissions?.query?.data?.apiKey ? `&apiKey=${permissions?.query?.data?.apiKey}` : '';

    return `${srcUrl}${localFeaturePath}?theme=${themeRef.current}&language=${localeRef.current}${apiKey}${apiKeys}&x-connex-id=${xConnexId}&cb=${ms}`;
  }, [config, featurePath, permissions?.query?.data?.apiKey, permissions?.query?.data?.apiKeys, xConnexId]);

  // can't use _src directly because we need to manually set it after ticket acceptance.
  const [src, setSrc] = React.useState(_src);

  React.useEffect(() => {
    appContext?.setIframeLoading?.(iframeLoading);
  }, [appContext, iframeLoading]);

  const iframeRef = React.useRef();

  React.useEffect(() => {
    const iframe = iframeRef.current;
    const fn = () => {
      setIframeLoading(false);
    };
    if (appContext?.appConfig?.type !== 'CX_APP_CORE') {
      setIframeLoading(true);
      iframe?.addEventListener?.('load', fn);
    }

    return () => {
      iframe?.removeListener?.('load', fn);
    };
  }, [_src, appContext?.appConfig?.type]);

  const [iframeLocation, setIframeLocation] = React.useState(null);

  React.useEffect(() => {
    setSrc(`${_src}`);
  }, [_src]);

  const simulateTrackIt = React.useMemo(() => {
    return appContext?.appRef === 'mt' && iframeLocation?.startsWith?.(`/${entityRef}/driver`);
  }, [appContext?.appRef, entityRef, iframeLocation]);

  const listener = React.useCallback(
    event => {
      const message = event?.data;

      if (simulateTrackIt && message?.type === 'submit') {
        setSrc(`${config?.hostname}/driver`);
        setKey(s => s + 1);
      }

      if (message?.locationChange) {
        setIframeLocation(message.locationChange);
      }
    },
    [config?.hostname, simulateTrackIt]
  );

  usePostMessageListener(listener);

  // TODO: Refactor to support internal (type = CX_APP_CORE) apps in a general way.

  return (
    <Styled className={cn('iframe-wrapper')}>
      <div className="overlay" />
      <Core.NewSpinner
        className={cn({ busy: !iframeLoading }, 'spinner-text-color')}
        title={`Loading ${appContext?.appConfig?.name}...`}
      />
      {appContext?.appConfig?.crn === 'settings' ? (
        <Profile />
      ) : (
        <iframe
          className={cn({ busy: iframeLoading, trackIt: simulateTrackIt })}
          src={src}
          key={`${key}`}
          title="service-iframe"
          id="service-iframe"
          allow="clipboard-read; clipboard-write"
          ref={iframeRef}
        />
      )}
    </Styled>
  );
};
