import { css } from '@emotion/react';
import colors from '../../../../../../mui-theme/colors';

export default ({ theme }) => css`
  padding: 40px 5rem;

  h3 {
    font-size: 28px;
    margin-bottom: 20px;
    line-height: 24px;
    letter-spacing: 0.15px;
  }

  .credentials-description,
  ul {
    font-size: 16px;
  }

  .credentials-description {
    font-weight: bold;
    margin: 0;
  }

  ul {
    margin-bottom: 32px;
    padding-left: 2rem;
  }

  .credentials-form {
    display: grid;
    gap: 32px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 32px;

    div:nth-of-type(n + 2) {
      grid-row: 2;
    }
  }

  .forget-me {
    display: flex;
    gap: 8px;
    color: ${colors.red[40]};
    padding: 14px 24px 14px 16px;
    align-items: center;
    font-size: 20px;
    height: 48px;
  }
`;
