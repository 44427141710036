import { usePostMessageListener } from 'connex-cds';
import React from 'react';
import { useToast } from '../../components/Toast';

const DEFAULT_TOAST_DURATION = 4000;

export const MonitorNotifications = () => {
  const toast = useToast();

  const notificationTypes = React.useMemo(() => ({ toast }), [toast]);

  const listener = React.useCallback(
    message => {
      if (message.type === 'notification') {
        const {
          severity,
          messageText,
          notificationType,
          options = { duration: DEFAULT_TOAST_DURATION },
        } = message.payload;

        notificationTypes?.[notificationType]?.[severity]?.(messageText, options);
      }
    },
    [notificationTypes]
  );

  usePostMessageListener(listener);

  return null;
};
