import React, { useCallback, useMemo } from 'react';
import { Localization } from 'connex-cds';
import { MenuItem, InputAdornment } from '@mui/material';

import { CustomIcon } from '../../../../commons';

import { SelectStyled } from './styles';

const { useTranslateMessage, useLocalizationContext } = Localization;

const LanguageSelector = () => {
  const translateMessage = useTranslateMessage();
  const { userLocale, setUserLocale, supportedLanguages } = useLocalizationContext();

  const options = useMemo(() => {
    return supportedLanguages.map(language => ({ value: language, label: `languages.${language}` }));
  }, [supportedLanguages]);

  const handleOnChange = useCallback(
    e => {
      setUserLocale(e.target.value, true);
    },
    [setUserLocale]
  );

  return (
    <SelectStyled
      select
      value={userLocale}
      onChange={handleOnChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CustomIcon iconName="languageOutlined" />
          </InputAdornment>
        ),
      }}
    >
      {options.map((option, index) => (
        <MenuItem key={index} value={option.value}>
          {translateMessage(option.label)}
        </MenuItem>
      ))}
    </SelectStyled>
  );
};

export default LanguageSelector;
