import { css } from 'styled-components';

export default css`
  position: relative;
  & > .icon {
    border: 2px solid red;
    width: 69px;
    height: 69px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    opacity: 0.5;
    cursor: pointer;
  }

  .overlay {
    &:not(.open) {
      display: none;
    }
    z-index: 1000;
    opacity: 0;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
`;
