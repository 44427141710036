import React from 'react';

import { useAppContext } from '../providers/AppProvider';
import { useEntityContext } from '../providers/entity-provider/EntityProvider';
import SelectEntity from './components/select-entity';

export const EnforceEntityRequirement = ({ children }) => {
  const { entityRef, setEntityRef } = useEntityContext();
  const { isEntityPickerRequired, appEntities, appEntitiesIsLoading } = useAppContext();

  if (!entityRef && isEntityPickerRequired && appEntities?.length && !appEntitiesIsLoading) {
    return <SelectEntity appEntities={appEntities} onChange={setEntityRef} />;
  }

  return children;
};
