import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Theme, usePostMessageListener } from 'connex-cds';
import { useQueryClient } from 'react-query';
import cn from 'classnames';

import { useAuthContext } from '../../../../providers/AuthProvider';
import { useEntityContext } from '../../../../providers/entity-provider/EntityProvider';
import { useUserContext } from '../../../../providers/UserProvider';
import { useAppContext } from '../../../../providers/AppProvider';
import ForgetAccountDialog from '../../../core-apps/profile/ForgetAccountDialog/ForgetAccountDialog';
import ManageProfileDialog from '../../../core-apps/profile/ManageProfileDialog/ManageProfileDialog';
import { CONTACT_SUPPORT_URL } from '../../../../constants/link';
import { AvatarButton, CustomIcon } from '../../../../commons';
import LanguageSelector from '../language-selector/LanguageSelector';

import { ProfileDropdownStyled, SwitchStyled } from './styles';
import UploadImageProfileDialog from '../../../core-apps/profile/UploadImageProfileDialog';
import { useProfile } from '../../../../queries/user-profile';

export const ProfileSlideOut = ({ className, onClose }) => {
  const [openManageProfileDialog, setOpenManageProfileDialog] = useState(false);
  const [openForgetAccountDialog, setOpenForgetAccountDialog] = useState(false);
  const [openUploadImageDialog, setOpenUploadImageDialog] = useState(false);
  const { data: profileData, refetch } = useProfile();
  const { logout } = useAuthContext();
  const { user, setUser, setUserApps } = useUserContext();
  const { entityRef, setEntityRef } = useEntityContext();
  const queryClient = useQueryClient();
  const { setThemeName, themeName } = Theme.useThemeContext();
  const { appEntities } = useAppContext();

  useEffect(() => {
    setUser({
      ...user,
      email: profileData?.email,
      firstName: profileData?.firstName,
      lastName: profileData?.lastName,
      phone: profileData?.phone,
      profileImage: profileData?.profileImage?.data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileData]);

  // This message is beign sent by cds-company-admin when the user list is updated (save-profile-from-list)
  const listener = React.useCallback(
    async message => {
      if (message.type === 'save-profile-from-list') {
        refetch();
      }
    },
    [refetch]
  );

  usePostMessageListener(listener);

  const handleLogout = useCallback(() => {
    onClose();
    queryClient.invalidateQueries();
    setEntityRef(undefined);
    setUser(undefined);
    setUserApps(undefined);
    logout();
  }, [logout, onClose, queryClient, setEntityRef, setUser, setUserApps]);

  const handleProfileClick = useCallback(() => {
    onClose();
    setOpenManageProfileDialog(true);
  }, [onClose]);

  const handleToggleTheme = useCallback(() => {
    setThemeName(themeName === 'dark' ? 'light' : 'dark');
  }, [setThemeName, themeName]);

  const selectedEntityName = useMemo(() => {
    return entityRef && appEntities ? appEntities.filter(entity => entity.entityRef === entityRef)[0]?.name : '';
  }, [entityRef, appEntities]);

  const handleForgetAccountDialogOnCancel = () => {
    setOpenForgetAccountDialog(false);
    setOpenManageProfileDialog(true);
  };

  const onForgetMeClick = () => {
    setOpenManageProfileDialog(false);
    setOpenForgetAccountDialog(true);
  };

  const goToSupport = () => {
    onClose();
    window.open(CONTACT_SUPPORT_URL, '_blank');
  };

  const fullUsername = useMemo(() => `${user?.firstName} ${user?.lastName}`, [user]);

  return (
    <>
      <ProfileDropdownStyled className={cn('profile-slide-out', className)}>
        <div className="first-section">
          <div className="avatar">
            <AvatarButton
              profileImage={profileData?.profileImage?.data}
              onUploadProfileImageClick={() => setOpenUploadImageDialog(true)}
              fullName={fullUsername}
              avatarInfo={{ firstName: user?.firstName, lastName: user?.lastName }}
            />
          </div>
          <div className="user-name">{fullUsername}</div>
          <div className="entity-name">{selectedEntityName}</div>
          <div className="manage-profile-button" onClick={handleProfileClick}>
            Manage Profile
          </div>
        </div>
        <hr />
        <div className="switch-container">
          Mode
          <SwitchStyled
            checked={themeName === 'dark'}
            onChange={handleToggleTheme}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </div>
        <hr />
        <div className="language-section">
          <LanguageSelector />
        </div>
        <hr />
        <div className="action" onClick={goToSupport}>
          <CustomIcon iconName="supportAgentOutlined" />
          <div className="action-label">Support</div>
        </div>
        <div className="action" onClick={handleLogout}>
          <CustomIcon iconName="logoutOutlined" />
          <div className="action-label">Logout</div>
        </div>
      </ProfileDropdownStyled>
      <ManageProfileDialog
        open={openManageProfileDialog}
        onClose={() => setOpenManageProfileDialog(false)}
        onForgetMeClick={onForgetMeClick}
        onUploadProfileImageClick={() => setOpenUploadImageDialog(true)}
      />
      <ForgetAccountDialog open={openForgetAccountDialog} onCancel={handleForgetAccountDialogOnCancel} />
      {openUploadImageDialog && (
        <UploadImageProfileDialog
          open={openUploadImageDialog}
          onClose={() => setOpenUploadImageDialog(false)}
          alt={fullUsername}
          avatarInfo={{ firstName: user?.firstName, lastName: user?.lastName }}
        />
      )}
    </>
  );
};
