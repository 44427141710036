import { css } from '@emotion/react';
import colors from '../../../../../../mui-theme/colors';

export default ({ theme }) => css`
  padding: 51px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  text-align: center;

  hgroup {
    display: flex;
    flex-direction: column;
    gap: 11px;
    font-weight: 600;

    h3 {
      font-size: 23px;
      margin: 0;
    }

    p {
      font-size: 20px;
      color: ${colors.neutral[60]};
      margin: 0;
    }
  }

  .profile-form,
  .skeleton__form {
    width: 75%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 32px;
  }

  .skeleton {
    &__form > * {
      height: 3rem;
    }

    &__avatar {
      height: 129px;
      width: 129px;
      flex-shrink: 0;
    }

    &__title {
      width: 50%;

      .name {
        font-size: 23px;
      }

      .company {
        font-size: 20px;
      }
    }
  }

  .copy-id {
    display: flex;
    gap: 10px;
    padding: 10px 12px;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: ${theme.validateMode({ light: theme.colorsCA.neutral[40] })};

    .copy-icon {
      font-size: 24px;
    }

    .id-text {
      min-width: 0;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`;
