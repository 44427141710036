import { Localization } from 'connex-cds';
import React from 'react';
import { Outlet } from 'react-router-dom';
import { useToast } from '../../components/Toast';
import { COOKIES_ACCEPTED_AT_KEY } from '../../constants/cookies';
import { cacheItem, DateManager, getCachedItem, isValidCookieAcceptanceDate } from '../../util';
import CookiesPage from './CookiesPage';

export const EnforceCookiePolicy = () => {
  const notify = useToast();
  const translateMessage = Localization.useTranslateMessage();
  const [cookiesAcceptedDate, setCookiesAcceptedDate] = React.useState(getCachedItem(COOKIES_ACCEPTED_AT_KEY));

  const isPolicyAccepted = React.useMemo(() => {
    return isValidCookieAcceptanceDate(cookiesAcceptedDate);
  }, [cookiesAcceptedDate]);

  const handleAcceptance = React.useCallback(() => {
    const date = DateManager.currentDate();
    cacheItem(COOKIES_ACCEPTED_AT_KEY, date);
    setCookiesAcceptedDate(date);
    notify.success(translateMessage('cookies_confirmation_msj'));
  }, [notify, translateMessage]);

  return isPolicyAccepted ? <Outlet /> : <CookiesPage onAcceptance={handleAcceptance} />;
};
