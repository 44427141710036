import React, { useContext } from 'react';
import { usePostMessageListener } from 'connex-cds';
import { useNavigate } from 'react-router-dom';

import { useAppsEntitiesQuery } from '../api/query-hooks/useAppEntitiesQuery';
import sendMessage from '../util/sendMessage';
import { useUserContext } from './UserProvider';

const AppContext = React.createContext();

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error(`useAppContext cannot be rendered outside of the AppContext context provider`);
  }
  return context;
};

export const AppProvider = ({ children }) => {
  const [appRef, setAppRef] = React.useState(null);
  const [appPath, setAppPath] = React.useState(null);
  const [allApps, setAllApps] = React.useState([]);
  const [appEntities, setAppEntities] = React.useState();
  const [appConfig, setAppConfig] = React.useState();
  const [iframeLoading, setIframeLoading] = React.useState(false);
  const [currentFeature, setCurrentFeature] = React.useState();
  const navigate = useNavigate();

  const { user } = useUserContext();

  React.useEffect(() => {
    const newDestination = `/app/${appRef}`;
    if (appRef && !window?.location?.pathname?.startsWith?.(newDestination)) {
      navigate(newDestination);
    }
  }, [appRef]);

  React.useEffect(() => {
    setCurrentFeature(undefined);
  }, [appRef]);

  const isEntityPickerRequired = React.useMemo(() => {
    return appRef && appConfig?.isEntitySpecific;
  }, [appConfig, appRef]);

  const appEntitiesListener = React.useCallback(
    message => {
      if (message?.type === 'req-app-entities') {
        sendMessage({ type: 'app-entities', appEntities });
      }
    },
    [appEntities]
  );

  usePostMessageListener(appEntitiesListener);

  const appConfigListener = React.useCallback(
    message => {
      if (message?.type === 'req-app-config') {
        sendMessage({ type: 'app-config', appConfig, appEntities, allApps });
      }
    },
    [allApps, appConfig, appEntities]
  );
  usePostMessageListener(appConfigListener);

  const appChangeListener = React.useCallback(message => {
    if (message.type === 'app-change') {
      setAppRef(message.appRef);
      setAppPath(`${message.appRef}::${message.path}`);
    }
  }, []);

  usePostMessageListener(appChangeListener);

  const logoutListener = React.useCallback(message => {
    if (message.type === 'logout') {
      setAppRef(undefined);
      setAppPath(undefined);
    }
  }, []);

  usePostMessageListener(logoutListener);

  React.useEffect(() => {
    sendMessage({ type: 'app-entities', appEntities, appConfig });
  }, [appEntities, appConfig]);

  const appEntitiesQuery = useAppsEntitiesQuery({ profileRef: user?.profileRef, appRef });

  React.useEffect(() => {
    if (['admin', 'settings'].includes(appRef)) {
      setAppEntities(undefined);
    } else {
      setAppEntities(appEntitiesQuery?.data?.entities);
    }
  }, [appEntitiesQuery?.data?.entities, appRef, setAppEntities, user?.entities]);

  return (
    <AppContext.Provider
      value={{
        appRef,
        setAppRef,
        appPath,
        setAppPath,
        appConfig,
        setAppConfig,
        allApps,
        setAllApps,
        appEntities,
        setAppEntities,
        iframeLoading,
        setIframeLoading,
        isEntityPickerRequired,
        currentFeature,
        setCurrentFeature,
        appEntitiesIsLoading: appEntitiesQuery.isLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
