import { useMemo } from 'react';
import { Localization } from 'connex-cds';
import { pick, defaults } from 'lodash';
import { Field, Form, Formik } from 'formik';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import styled from '@emotion/styled';

import { useAppContext } from '../../../../../../providers/AppProvider';
import { useUserContext } from '../../../../../../providers/UserProvider';
import { useEntityContext } from '../../../../../../providers/entity-provider/EntityProvider';
import { useProfile, useUpdateProfile } from '../../../../../../queries/user-profile';
import { sanitizePhoneNumber, maskPhoneNumber } from '../../../../../../util/forms';
import { AvatarButton, TextInputWithFormikField } from '../../../../../../commons';
import { validationSchema } from './form-config';
import SaveDialogActions from '../SaveDialogActions/SaveDialogActions';
import GeneralSkeleton from './GeneralSkeleton';
import copyToClipboard from '../../../../../../util/copyToClipboard';
import { useToast } from '../../../../../../components/Toast';
import sendMessage from '../../../../../../util/sendMessage';

import styles from './style';

const Styled = styled(DialogContent)`
  ${styles}
`;

const General = ({ closeModal, onUploadProfileImageClick }) => {
  const { data: profileData, isLoading } = useProfile();
  const { user, setUser } = useUserContext();

  const { entityRef } = useEntityContext();
  const { appEntities } = useAppContext();
  const { mutateAsync: updateProfile } = useUpdateProfile();
  const translateMessage = Localization.useTranslateMessage();
  const notify = useToast();

  const initialValues = useMemo(() => {
    let values = {};

    if (profileData) {
      values = pick(profileData, ['firstName', 'lastName', 'email', 'phone']);
      values.phone = maskPhoneNumber(values.phone ?? '');
    }

    return defaults(values, {
      firstName: '',
      lastName: '',
      phone: '',
      email: '',
    });
  }, [profileData]);

  const companyName = appEntities?.find(app => app.entityRef === entityRef)?.name || null;

  const handlePhoneChange = setFieldValue => (name, value) => {
    setFieldValue(name, maskPhoneNumber(sanitizePhoneNumber(value)));
  };

  const handleUpdateProfile = async values => {
    try {
      await updateProfile({ ...values, phone: sanitizePhoneNumber(values.phone) });
      setUser({ ...user, firstName: values.firstName, lastName: values.lastName });
      sendMessage({ type: 'save-profile-clicked' });
      closeModal();
    } catch (error) {
      // TODO: user feedback on request error
      console.error('ERROR::', error);
    }
  };

  const copyId = async () => {
    try {
      await copyToClipboard(profileData.crn);
      notify.info(translateMessage('general_notification_copy'), { materialIconName: 'CopyAll' });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleUpdateProfile}
    >
      {({ handleSubmit, dirty, setFieldValue, isSubmitting, isValid }) => (
        <>
          <Styled className="profile-general" dividers>
            {isLoading && <GeneralSkeleton />}
            {!isLoading && (
              <>
                <AvatarButton
                  profileImage={profileData?.profileImage?.data}
                  onUploadProfileImageClick={onUploadProfileImageClick}
                  fullName={`${initialValues.firstName} ${initialValues.lastName}`}
                  avatarInfo={{ firstName: initialValues?.firstName, lastName: initialValues?.lastName }}
                />
                <hgroup>
                  <h3>{`${initialValues.firstName} ${initialValues.lastName}`}</h3>
                  <p>{companyName}</p>
                  <Button onClick={copyId} className="copy-id" disableRipple>
                    <CopyAllIcon className="copy-icon" />
                    <span className="id-text">{profileData?.crn}</span>
                  </Button>
                </hgroup>

                <Form className="profile-form" onSubmit={e => e.preventDefault()}>
                  <Field
                    testId="general-firstName"
                    labelStringId="manageProfile_general_firstName"
                    component={TextInputWithFormikField}
                    type="text"
                    variant="outlined"
                    name="firstName"
                  />
                  <Field
                    testId="general-lastName"
                    labelStringId="manageProfile_general_lastName"
                    component={TextInputWithFormikField}
                    type="text"
                    variant="outlined"
                    name="lastName"
                  />
                  <Field
                    testId="general-email"
                    labelStringId="manageProfile_general_email"
                    component={TextInputWithFormikField}
                    type="email"
                    disabled
                    variant="outlined"
                    name="email"
                  />
                  <Field
                    testId="general-phone"
                    labelStringId="manageProfile_general_phone"
                    component={TextInputWithFormikField}
                    type="text"
                    variant="outlined"
                    name="phone"
                    customOnChange={handlePhoneChange(setFieldValue)}
                  />
                </Form>
              </>
            )}
          </Styled>
          <SaveDialogActions
            closeModal={closeModal}
            handleSubmit={handleSubmit}
            disabledSaveButton={!dirty || isSubmitting || !isValid}
          />
        </>
      )}
    </Formik>
  );
};

export default General;
