import cn from 'classnames';
import { Core, Form } from 'connex-cds';
import { pick } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useUserContext } from '../../../../providers/UserProvider';
import { ChangePasswordForm } from './ChangePasswordForm';
import style from './style';
import config from './form-config';
import auth from '../../../../api/auth';

const Styled = styled.div`
  ${style}
`;
export const ChangePassword = ({ onSuccess }) => {
  const { user } = useUserContext();
  const [error, setError] = React.useState(false);

  const handleSubmit = React.useCallback(
    values => {
      setError(false);
      const data = pick(values, ['oldPassword', 'newPassword']);
      return auth
        .updatePassword({ profileRef: user?.profileRef, data })
        .then(() => {
          onSuccess?.('change-password-success');
        })
        .catch(() => {
          setError(true);
        });
    },
    [onSuccess, user?.profileRef]
  );

  return (
    <Styled className={cn('change-password')}>
      <Form.FormProvider config={config} onSubmit={handleSubmit}>
        {error && (
          <Core.Alert
            severity={Core.SEVERITY.ERROR}
            // title="alertError"
            message="change-password-error"
            closable
            showIcon
          />
        )}
        <ChangePasswordForm />
      </Form.FormProvider>
    </Styled>
  );
};
