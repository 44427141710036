import { find } from 'lodash';
import React from 'react';
import { useQuery } from 'react-query';
import { apps } from '../../api';
import { useProfileAppsQuery } from '../../api/query-hooks/useProfileAppsQuery';
import { useAppContext } from '../../providers/AppProvider';
import { useUserContext } from '../../providers/UserProvider';

export const MonitorApp = () => {
  const { appRef, setAppConfig, setAllApps } = useAppContext();

  const userContext = useUserContext();
  const { user, userApps, setUserApps, setUserAppsIsLoading } = userContext;

  const appsQuery = useQuery({ queryKey: ['apps'], queryFn: apps.listApps, staleTime: Infinity });

  const userAppsQuery = useProfileAppsQuery({
    profileRef: user?.profileRef,
    options: { initialData: { apps: userApps || [] } },
  });

  React.useEffect(() => {
    setUserApps(userAppsQuery?.data?.apps);
    setUserAppsIsLoading(userAppsQuery.isLoading || userAppsQuery.isFetching);
  }, [setUserApps, setUserAppsIsLoading, userAppsQuery?.data, userAppsQuery.isFetching, userAppsQuery.isLoading]);

  React.useEffect(() => {
    setAllApps(appsQuery.data);
    if (!appRef || !appsQuery.isSuccess) return;
    const appConfig = find(appsQuery.data, { crn: appRef });
    setAppConfig(appConfig);
  }, [appRef, appsQuery.data, appsQuery.isSuccess, setAllApps, setAppConfig]);

  return null;
};
