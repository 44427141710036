import Button from '@mui/material/Button';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import { CustomIcon } from '../../../commons';

import { EntityOptionStyles } from './styles';

const Styled = styled.li`
  ${EntityOptionStyles}
`;

const EntityOption = ({ entityRef, name, handleEntityClick }) => (
  <Styled key={entityRef}>
    <Button onClick={handleEntityClick(entityRef)} className="entity-button">
      <CustomIcon iconName="business" size="small" />
      <span className="entity-label">{name}</span>
      <CustomIcon iconName="keyboardArrowRight" size="small" />
    </Button>
  </Styled>
);

EntityOption.propTypes = {
  entityRef: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleEntityClick: PropTypes.func.isRequired,
};

export default EntityOption;
