import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Button } from '@mui/material';

const customColors = theme => ({
  red: {
    background: theme.colorsCA.red[60],
    hover: theme.colorsCA.red[50],
  },
  blue: {
    background: theme.colorsCA.darkBlue[50],
    hover: theme.colorsCA.darkBlue[40],
  },
});

const styles = ({ theme, variant, customcolor }) => css`
  border: ${variant === 'outlined' ? '1px solid #fff' : 'none'};
  display: flex;
  height: 48px;
  justify-content: center;
  align-items: center;
  padding: 14px 25px;
  border-radius: 100px;
  background: ${variant === 'outlined' ? 'inherit' : customColors(theme)[customcolor]?.background};
  width: 100%;
  max-width: 170px;
  color: ${theme.colorsCA.neutral[95]};
  box-shadow: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  
    &:disabled {
      background-color: ${theme.colorsCA.neutral[80]};
    }

    &:focus {
      background-color: ${customColors(theme)[customcolor]?.background};
    }

    &:hover {
      border: ${variant === 'outlined' ? '1px solid #fff' : 'none'};
      background: ${variant === 'outlined' ? theme.colorsCA.neutral[40] : customColors(theme)[customcolor]?.hover} ;
      box-shadow: none;
    }

    .login-icon {
      color: ${theme.colorsCA.neutral[95]};
      font-size: 24px;
    }
  }
`;

export const ButtonStyled = styled(Button)`
  ${styles}
`;
