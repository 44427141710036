import * as yup from 'yup';

yup.addMethod(yup.string, 'email', function validateEmail(message) {
  return this.matches(/^[\w-.]+([\w-.+])+@([\w-]+\.)+[a-zA-Z]{2,4}$/g, {
    message,
    name: 'email',
    excludeEmptyString: true,
  });
});

export const initialValues = {
  username: '',
};

export const validationSchema = yup.object().shape({
  username: yup.string().email('accountRecovery_usernameInvalid').nullable().required('required'),
});
