import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Avatar } from '@mui/material';

export const AvatarStyled = styled(Avatar)`
  ${({ theme, size, fontSize }) => css`
    background: radial-gradient(50% 50% at 50% 50%, #6b88ab 0%, #6b88ab 0%, #a1c0e5 0.01%, #86a3c7 99.48%);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    width: ${SIZES[size] || SIZES.medium};
    height: ${SIZES[size] || SIZES.medium};
    border-radius: 50%;
    border: 2px solid ${theme.colorsCA.darkBlue[60]};
    box-shadow: ${theme.shadowCA(4)};
    color: ${theme.colorsCA.neutral[100]};
    text-align: center;
    font-size: ${FONT_SIZES[fontSize] || FONT_SIZES.medium};
    font-weight: 600;
  `}
`;

const SIZES = {
  small: '40px',
  medium: '130px',
  large: '200px',
};

const FONT_SIZES = {
  small: '23px',
  medium: '53px',
  large: '83px',
};
