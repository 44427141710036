import { css } from '@emotion/react';
import styled from '@emotion/styled';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';

const stylesContainer = ({ theme }) => css`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const styles = ({ theme }) => css`
  border-radius: 20px;
  border: 1px solid ${theme.colorsCA.neutral[60]};
  background: linear-gradient(0deg, rgba(208, 188, 255, 0.05) 0%, rgba(208, 188, 255, 0.05) 100%), rgba(28, 27, 31, 0.4);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(40px);
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 39px;
  padding: 40px;
  overflow: hidden;
  text-align: center;

  max-width: 716px;
  max-height: 665px;
  min-width: 616px;
  min-height: 235px;

  position: relative;

  &.show-loading-bar {
    border-top: none;
  }

  .cai-logo {
    width: 69.11px;
    height: 44.42px;
    flex-shrink: 0;
  }
`;

const stylesLinearLoader = ({ theme }) => css`
  background-color: ${theme.colorsCA.darkBlue[90]};
  height: 7px;
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  & .${linearProgressClasses.bar} {
    background-color: ${theme.colorsCA.darkBlue[40]};
  }
`;

export const MainContainer = styled.div`
  ${stylesContainer}
`;

export const Styled = styled.div`
  ${styles}
`;

export const LinearLoader = styled(LinearProgress)`
  ${stylesLinearLoader}
`;
