import styled from '@emotion/styled';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import { Localization } from 'connex-cds';

import styles from './styles';

const Styled = styled(DialogActions)`
  ${styles}
`;

const SaveDialogActions = ({ closeModal, handleSubmit, disabledSaveButton = false }) => (
  <Styled>
    {/* <Button className="action-button" variant="outlined" onClick={closeModal}>
      <Localization.Translate stringId="manageProfile_cancel" />
    </Button> */}
    <Button className="action-button" variant="contained" disabled={disabledSaveButton} onClick={handleSubmit}>
      <Localization.Translate stringId="manageProfile_save" />
    </Button>
  </Styled>
);

export default SaveDialogActions;
