import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Switch from '@mui/material/Switch';

export const ProfileDropdownStyled = styled.div`
  ${({ theme }) => css`
    z-index: 1000;
    position: fixed;
    top: 100px;
    width: 302px;
    height: 630px;
    padding-top: 20px;
    padding-bottom: 8px;
    border-radius: 20px;
    border: 1px solid
      ${theme.validateMode({
        light: '#cac4d0',
        dark: '#605C66',
      })};

    ${theme.validateMode({
      light: `background: ${theme.colorsCA.neutral[100]}; box-shadow: ${theme.shadowCA(6)};`,
      dark: theme.elevationCA[5],
    })};

    hr {
      border: none;
      border-top: 1px solid
        ${theme.validateMode({
          light: '#cac4d0',
          dark: theme.colorsCA.neutral[60],
        })};
    }

    &:not(.open) {
      -webkit-transition: right 0.3s 0.05s, opacity 0.3s;
      transition: right 0.3s 0.05s, opacity 0.3s;
      right: -360px;
      opacity: 0;
    }

    &.open {
      -webkit-transition: right 0.3s, opacity 0.3s 0.1s;
      transition: right 0.3s, opacity 0.3s 0.1s;
      right: 36px;
      opacity: 1;
    }

    .first-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 34px;
    }

    .avatar {
      margin-bottom: 20px;
    }

    .user-name {
      ${theme.validateMode({
        light: `color: ${theme.colorsCA.neutral[40]}`,
        dark: `color: ${theme.colorsCA.neutral[95]}`,
      })};
      font-weight: 600;
      font-size: 23px;
      line-height: normal;
      text-align: center;
      margin-bottom: 4px;
    }

    .entity-name {
      ${theme.validateMode({
        light: `color: ${theme.colorsCA.neutral[60]}`,
        dark: `color: ${theme.colorsCA.neutral[70]}`,
      })};
      font-weight: 600;
      font-size: 18px;
      line-height: normal;
      text-align: center;
      height: 22px;
    }

    .manage-profile-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 180px;
      height: 50px;
      border: 1px solid ${theme.colorsCA.neutral[60]};
      border-radius: 145px;
      ${theme.validateMode({
        light: `color: ${theme.colorsCA.neutral[40]}`,
        dark: `color: ${theme.colorsCA.neutral[95]}`,
      })};
      font-weight: 500;
      font-size: 16px;
      line-height: normal;
      margin-top: 32px;
      cursor: pointer;
    }

    .switch-container {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin: 16px 0;
      font-size: 18px;
      ${theme.validateMode({
        light: `color: ${theme.colorsCA.neutral[40]}`,
        dark: `color: ${theme.colorsCA.neutral[95]}`,
      })};
    }

    .action {
      display: flex;
      cursor: pointer;
      height: 56px;
      padding: 8px 24px 8px 16px;
      justify-content: flex-start;
      align-items: center;
      gap: 16px;

      &-label {
        font-weight: 400;
        font-size: 16px;
      }
    }

    .language-section {
      text-align: center;
      display: flex;
      justify-content: center;
      font-size: 1.3rem;
      margin: 22px 0;
    }
  `}
`;

export const SwitchStyled = styled(Switch)`
  ${({ theme }) => css`
    padding: 0;
    width: 142px;
    height: 42px;

    .MuiSwitch-switchBase {
      padding: 0;
      transform: translateX(0px);

      .MuiSwitch-input {
        left: -240%;
        width: 244px;
      }
    }

    .MuiSwitch-switchBase.Mui-checked {
      color: ${theme.colorsCA.neutral[100]};
      transform: translateX(100px);

      & + .MuiSwitch-track {
        opacity: 1;
        background-color: ${theme.colorsCA.darkBlue[60]};
        box-shadow: ${theme.shadowCA(4)} inset;
      }

      & + .MuiSwitch-track:before {
        left: -70px;
      }

      & + .MuiSwitch-track:after {
        left: 26px;
      }
    }

    .MuiSwitch-track {
      border-radius: 58px;
      background-color: ${theme.colorsCA.darkBlue[10]};
      opacity: 1;

      &:before,
      &:after {
        position: absolute;
        width: 65px;
        height: 26px;
        padding-left: 24px;
        top: 19%;
        background-repeat: no-repeat;
        font-size: 16;
        background-position-y: center;
      }

      &:before {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none"><path d="M9 7.25C10.2375 7.25 11.25 8.2625 11.25 9.5C11.25 10.7375 10.2375 11.75 9 11.75C7.7625 11.75 6.75 10.7375 6.75 9.5C6.75 8.2625 7.7625 7.25 9 7.25ZM9 5.75C6.93 5.75 5.25 7.43 5.25 9.5C5.25 11.57 6.93 13.25 9 13.25C11.07 13.25 12.75 11.57 12.75 9.5C12.75 7.43 11.07 5.75 9 5.75ZM1.5 10.25H3C3.4125 10.25 3.75 9.9125 3.75 9.5C3.75 9.0875 3.4125 8.75 3 8.75H1.5C1.0875 8.75 0.75 9.0875 0.75 9.5C0.75 9.9125 1.0875 10.25 1.5 10.25ZM15 10.25H16.5C16.9125 10.25 17.25 9.9125 17.25 9.5C17.25 9.0875 16.9125 8.75 16.5 8.75H15C14.5875 8.75 14.25 9.0875 14.25 9.5C14.25 9.9125 14.5875 10.25 15 10.25ZM8.25 2V3.5C8.25 3.9125 8.5875 4.25 9 4.25C9.4125 4.25 9.75 3.9125 9.75 3.5V2C9.75 1.5875 9.4125 1.25 9 1.25C8.5875 1.25 8.25 1.5875 8.25 2ZM8.25 15.5V17C8.25 17.4125 8.5875 17.75 9 17.75C9.4125 17.75 9.75 17.4125 9.75 17V15.5C9.75 15.0875 9.4125 14.75 9 14.75C8.5875 14.75 8.25 15.0875 8.25 15.5ZM4.4925 3.935C4.2 3.6425 3.72 3.6425 3.435 3.935C3.1425 4.2275 3.1425 4.7075 3.435 4.9925L4.23 5.7875C4.5225 6.08 5.0025 6.08 5.2875 5.7875C5.5725 5.495 5.58 5.015 5.2875 4.73L4.4925 3.935ZM13.77 13.2125C13.4775 12.92 12.9975 12.92 12.7125 13.2125C12.42 13.505 12.42 13.985 12.7125 14.27L13.5075 15.065C13.8 15.3575 14.28 15.3575 14.565 15.065C14.8575 14.7725 14.8575 14.2925 14.565 14.0075L13.77 13.2125ZM14.565 4.9925C14.8575 4.7 14.8575 4.22 14.565 3.935C14.2725 3.6425 13.7925 3.6425 13.5075 3.935L12.7125 4.73C12.42 5.0225 12.42 5.5025 12.7125 5.7875C13.005 6.0725 13.485 6.08 13.77 5.7875L14.565 4.9925ZM5.2875 14.27C5.58 13.9775 5.58 13.4975 5.2875 13.2125C4.995 12.92 4.515 12.92 4.23 13.2125L3.435 14.0075C3.1425 14.3 3.1425 14.78 3.435 15.065C3.7275 15.35 4.2075 15.3575 4.4925 15.065L5.2875 14.27Z" fill="white"/></svg>');
        right: 26px;
        content: 'Light';
        color: ${theme.colorsCA.neutral[100]};
      }

      &:after {
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none"><path d="M6.37 3.01C6.19 3.65 6.1 4.32 6.1 5C6.1 9.08 9.42 12.4 13.5 12.4C14.18 12.4 14.85 12.31 15.49 12.13C14.45 14.69 11.93 16.5 9 16.5C5.14 16.5 2 13.36 2 9.5C2 6.57 3.81 4.05 6.37 3.01ZM9 0.5C4.03 0.5 0 4.53 0 9.5C0 14.47 4.03 18.5 9 18.5C13.97 18.5 18 14.47 18 9.5C18 9.04 17.96 8.58 17.9 8.14C16.92 9.51 15.32 10.4 13.5 10.4C10.52 10.4 8.1 7.98 8.1 5C8.1 3.19 8.99 1.58 10.36 0.6C9.92 0.54 9.46 0.5 9 0.5Z" fill="%23F4EFF4"/></svg>');
        right: -70px;
        content: 'Dark';
        color: ${theme.colorsCA.neutral[95]};
      }
    }

    .MuiSwitch-thumb {
      box-shadow: none;
      width: 30px;
      height: 30px;
      padding: 0;
      margin: 6px;
    }
  `}
`;
