import { css } from '@emotion/react';

export default css`
  font-family: 'Work Sans';

  .MuiDialog-paper {
    max-height: 857px;
    height: 80%;
    border-radius: 20px;
    box-shadow: 0px 8px 12px 6px rgba(0, 0, 0, 0.15), 0px 4px 4px rgba(0, 0, 0, 0.3);
  }

  .dialog-title {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .profile-dialog__tabs {
    flex-shrink: 0;
  }
`;
