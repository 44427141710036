import { css } from '@emotion/react';
import styled from '@emotion/styled';

const Heading1 = styled.h1`
  ${({ theme }) => css`
    color: ${theme.colorsCA.neutral[100]};
    font-size: 32px;
    font-weight: 600;
    line-height: 36px;
  `}
`;

const Paragraph1 = styled.p`
  ${({ theme }) => css`
    color: ${theme.colorsCA.neutral[100]};
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
  `}
`;

export { Heading1, Paragraph1 };
