import common from '../common';

export default {
  ...common,

  manageProfile_title: 'Gestionar Perfil',
  manageProfile_general: 'General',
  manageProfile_credentials: 'Credenciales',
  manageProfile_preferences: 'Preferencias',
  manageProfile_notifications: 'Notificaciones',
  manageProfile_general_firstName: 'Nombre',
  manageProfile_general_lastName: 'Apellido',
  manageProfile_general_phone: 'Teléfono Celular',
  manageProfile_general_email: 'Email',
  manageProfile_general_phoneError: 'Número de télefono inválido.',
  manageProfile_general_emailError: 'Formato de email inválido.',
  profileImage_title: 'Imagen de perfil',
  profileImage_description:
    'Una imagen ayuda a las personas a reconocerte y te permite saber cuándo has iniciado sesión en tu cuenta.',
  profileImage_change_btn: 'Cambiar',
  profileImage_delete_btn: 'Eliminar',
  profileImage_drag_photo_description: 'Arrastre una foto aquí',
  profileImage_drag_photo_or: '- o -',
  profileImage_upload_from_computer_btn: 'Subir desde el computador',
  profileImage_rotate_btn: 'Rotar',
  profileImage_file_size_error: 'El tamaño de la imagen no puede ser mayor a 5MB',
  profileImage_update_sucess_msg: 'Imagen actualizada correctamente',
  profileImage_update_error_msg: 'Error al actualizar la imagen de perfil',
  profileImage_delete_sucess_msg: 'Imagén de perfil eliminada exitosamente',
  profileImage_delete_error_msg: 'Hubo un error en la eliminación',

  manageProfile_credentials_oldPassword: 'Contraseña',
  manageProfile_credentials_newPassword: 'Nueva Contraseña',
  manageProfile_credentials_confirmNewPassword: 'Confirmar Contraseña',
  manageProfile_credentials_description: 'La nueva contraseña debe:',
  manageProfile_credentials_reqLength: 'Tener entre 8 a 99 caracteres de largo.',
  manageProfile_credentials_reqNumber: 'Incluir 1 o más caracteres númericos.',
  manageProfile_credentials_reqLowerCase: 'Incluir 1 o más letras minúsculas.',
  manageProfile_credentials_reqUpperCase: 'Incluir 1 o más letras mayúsculas.',
  manageProfile_credentials_reqSpecialCharacter:
    'Incluir 1 o más caracteres especiales del siguiente conjunto: ^ $ * . [] () ? - " ! @ # % & /  , > ; | _ ~ `',
  manageProfile_credentials_wrongOldPassword: 'Contraseña incorrecta. Por favor, intente de nuevo.',

  manageProfile_cancel: 'Cancelar',
  manageProfile_save: 'Guardar',
  manageProfile_forget: 'Olvidar',

  forgetMe: 'Olvidame',
  forgetMe_title: '¿Olvidar esta cuenta?',
  forgetMe_description: 'Esto eliminará sus credenciales del prerrelleno de inicio de sesión',

  cookies_main_title: 'Cookie policy',
  cookies_main_description:
    'We use cookies to improve your experience. By clicking “Accept all,” you agree to the use of all cookies on your device. You can manage your cookie preferences by clicking “Learn more.”',
  cookies_learn_more_btn: 'Leer mas',
  cookies_refuse_all_btn: 'Rechazar todas',
  cookies_accept_all_btn: 'Aceptar todas',
  cookies_back_btn: 'Ir atrás',

  cookies_details_title: 'Cookie preferences',
  cookies_details_notifications:
    'Critical notifications option: Critical notifications, online help, and documentation permit us to deliver users with essential alerts, provide online assistance, and share news and updates. This provides users with in-app notifications, service interruptions, and access to new feature announcements.',
  cookies_details_technical_support:
    'Technical support option: Technical support permits us to track and monitor the features you access. This assists us in proactively identifying and resolving issues, often before they’re even reported. ',

  cookies_reject_description:
    'Sorry, you will not be able to use the app without the required cookies. You can manage your cookie preferences by clicking “Learn more.”',

  cookies_confirmation_msj: 'Cookies aceptadas',

  login_header: 'Inicie sesión con sus credenciales a continuación',
  privacyPolicy: 'Política de privacidad',
  defaultLanding_header: 'Elige una aplicación para comenzar',
  defaultLanding_header_noLicenses: 'Actualmente no tiene acceso a ningún producto de software Command Alkon',
  defaultLanding_description_noLicenses: 'Comuníquese con el administrador de su sitio si cree que ha habido un error.',
  enforceEntity_selectCompany: 'Seleccione una empresa',
  enforceEntity_noMatchCompany:
    'No hay empresas que coincidan con sus criterios de búsqueda. Por favor refine su búsqueda o comuníquese con el administrador de su sitio si cree que ha habido un error.',

  search: 'Buscar',
  noResultsFound: 'No se han encontrado resultados',

  general_notification_copy: 'Copiado al portapapeles',
};
