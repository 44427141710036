import React, { useState } from 'react';
import { Localization } from 'connex-cds';
import { Formik, Form, Field } from 'formik';
import { Button, IconButton, InputAdornment } from '@mui/material';
import {
  ArrowBack,
  HighlightOff,
  AccountCircleOutlined,
  Key,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

import { LOGIN } from '../../../../constants/routes';
import { InputTextBaseWithFormikField, CustomButton } from '../../../../commons';
import { initialValues, validationSchema } from './form-config';

import { Username, NewPasswordTitle, Paragraph } from './styles';
import { Heading1, StepContainer } from '../styles';

const StepThree = ({ user, handleChangePassword }) => {
  const navigate = useNavigate();
  const translateMessage = Localization.useTranslateMessage();
  const [hiddenPassword, isHiddenPassword] = useState(true);

  return (
    <StepContainer>
      <Heading1>{translateMessage('accountRecovery')}</Heading1>
      {user && (
        <Username>
          <AccountCircleOutlined />
          <span>{user}</span>
        </Username>
      )}
      <NewPasswordTitle>{translateMessage('accountRecovery_createNewPassword')}</NewPasswordTitle>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleChangePassword}>
        {({ values, errors, handleSubmit, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit} className="login-form">
              <Field
                required
                variant="filled"
                className="form-field"
                name="password"
                type={hiddenPassword ? 'password' : 'text'}
                component={InputTextBaseWithFormikField}
                testId="login-code"
                labelStringId="accountRecovery_password"
                getInputProps={({ name }) => ({
                  endAdornment: (
                    <>
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => isHiddenPassword(!hiddenPassword)}
                          edge="end"
                        >
                          {hiddenPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                        </IconButton>
                      </InputAdornment>
                      {values.password && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle"
                            onClick={() => {
                              setFieldValue(name, '');
                            }}
                            onMouseDown={null}
                            edge="end"
                          >
                            <HighlightOff />
                          </IconButton>
                        </InputAdornment>
                      )}
                    </>
                  ),
                })}
              />
              <Paragraph>{translateMessage('accountRecovery_passwordHint')}</Paragraph>
              <Button
                className="submit-button"
                type="submit"
                data-testid="submit-button"
                variant="contained"
                disabled={Boolean(!values.password || Object.keys(errors).length)}
                startIcon={<Key className="button-icon" />}
              >
                <Localization.Translate stringId="accountRecovery_resetPasswordButton" />
              </Button>
              <CustomButton
                variant="outlined"
                className="back-button"
                onClick={() => {
                  navigate(LOGIN);
                }}
                startIcon={<ArrowBack className="button-icon" />}
              >
                <Localization.Translate stringId="accountRecovery_backToLogin" />
              </CustomButton>
            </Form>
          );
        }}
      </Formik>
    </StepContainer>
  );
};

export default StepThree;
