const sendMessage = message => {
  const iframe = document.getElementById('service-iframe')?.contentWindow;

  if (iframe) {
    //TODO: specify targetOrigin dynamically
    iframe.postMessage(message, '*');
  }
};

export default sendMessage;
