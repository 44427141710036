import { API, cacheItem, clearCachedItem, getCachedItem, useApiCall, usePostMessageListener } from 'connex-cds';
import decode from 'jwt-decode';
import React from 'react';

import { auth } from '../api';
import { USER_KEY } from '../constants/user';
import { useQueryParams } from '../QueryParamContext';
import sendMessage from '../util/sendMessage';
import { useUserContext } from './UserProvider';

/**
 * Renders children when user is authenticated, otherwise render the given unauthenticatedComponent
 *
 * @param children
 * @param unauthenticatedComponent
 * @param onLogin
 * @param noAuthCheck
 * @returns {*}
 * @constructor
 */

API.setLoginToken(getCachedItem('x-connex-id'));

export const AuthContext = React.createContext();

export const useAuthContext = () => {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error(`useAuthContext cannot be rendered outside of the AuthContext context provider`);
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const { lt } = useQueryParams(); // login token passed as query param

  const [isUserAuthenticated, _setIsUserAuthenticated] = React.useState(!!getCachedItem('x-connex-id') || !!lt);
  const userContext = useUserContext();

  const setAuthentication = React.useCallback(loginResponse => {
    cacheItem('x-connex-id', loginResponse?.access_token);
    _setIsUserAuthenticated(true);
  }, []);

  const onLogin = useApiCall(auth.login);

  const login = React.useCallback(
    async ({ credentials }) => {
      try {
        const rawResponse = await onLogin({ ...credentials });

        API.setLoginToken(rawResponse.access_token);
        sendMessage({ type: 'cx-login-token', token: rawResponse.access_token });

        const userObject = {
          ...rawResponse,
          apps: rawResponse?.apps?.filter?.(x => !!x) || [],
        };

        const decodedToken = decode(rawResponse.access_token);

        userObject.isPlatformAdmin = !!decodedToken?.cai?.pa;
        userObject.isPlatformSupport = !!decodedToken?.cai?.ps;

        userContext?.setUser?.(userObject);

        setAuthentication(userObject);

        return userObject;
      } catch (e) {
        console.log('e', e);
        console.log(e.stack);
      }
    },
    [onLogin, setAuthentication, userContext]
  );

  const logout = React.useCallback(() => {
    clearCachedItem('x-connex-id');
    clearCachedItem(USER_KEY);
    _setIsUserAuthenticated(false);
    window.postMessage({ type: 'logout' });
    window?.FS?.anonymize?.();
  }, []);

  React.useEffect(() => {
    API.setOnUnauthenticated(logout);
  }, [logout]);

  const logoutExperienceListener = React.useCallback(
    message => {
      if (message.type === 'logout-exp') {
        logout();
      }
    },
    [logout]
  );

  usePostMessageListener(logoutExperienceListener);

  return (
    <AuthContext.Provider value={{ login, logout, isUserAuthenticated, setAuthentication }}>
      {children}
    </AuthContext.Provider>
  );
};
