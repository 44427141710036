import React from 'react';
import { Localization } from 'connex-cds';
import { Formik, Form, Field } from 'formik';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ArrowBack from '@mui/icons-material/ArrowBack';
import HighlightOff from '@mui/icons-material/HighlightOff';
import AccountCircleOutlined from '@mui/icons-material/AccountCircleOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { LOGIN } from '../../../../constants/routes';
import { InputTextBaseWithFormikField, CustomButton } from '../../../../commons';
import { initialValues, validationSchema } from './form-config';

import { Username, VerificationMessage, ResendButtonContainer } from './styles';
import { Heading1, Paragraph, StepContainer } from '../styles';

const StepTwo = ({ user, message, handleSubmitCode, handleResendCode, timer, showResendTimer }) => {
  const navigate = useNavigate();
  const translateMessage = Localization.useTranslateMessage();

  return (
    <StepContainer>
      <Heading1>{translateMessage('accountRecovery_verification')}</Heading1>
      <Paragraph>{translateMessage('accountRecovery_verification_content')}</Paragraph>
      {user && (
        <Username>
          <AccountCircleOutlined />
          <span>{user}</span>
        </Username>
      )}
      <VerificationMessage>
        <Localization.Translate
          stringId={message.text}
          values={{
            icon: message.icon,
          }}
        />
      </VerificationMessage>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmitCode}>
        {({ values, errors, handleSubmit, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit} className="login-form">
              <Field
                required
                variant="filled"
                className="form-field"
                name="code"
                component={InputTextBaseWithFormikField}
                testId="login-code"
                labelStringId="accountRecovery_verification_code"
                getInputProps={({ name }) => ({
                  endAdornment: values.code && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle"
                        onClick={() => {
                          setFieldValue(name, '');
                        }}
                        onMouseDown={null}
                        edge="end"
                      >
                        <HighlightOff />
                      </IconButton>
                    </InputAdornment>
                  ),
                })}
              />
              <Button
                className="submit-button"
                type="submit"
                data-testid="submit-button"
                variant="contained"
                disabled={Boolean(!values.code || Object.keys(errors).length)}
              >
                <Localization.Translate stringId="general_submit" />
              </Button>
              <CustomButton
                variant="outlined"
                className="back-button"
                onClick={() => {
                  navigate(LOGIN);
                }}
                startIcon={<ArrowBack className="button-icon" />}
              >
                <Localization.Translate stringId="accountRecovery_backToLogin" />
              </CustomButton>
              <ResendButtonContainer className={cn({ 'show-timer': showResendTimer })}>
                {showResendTimer && (
                  <div className="resend-timer">
                    <TimerOutlinedIcon />
                    <span>
                      <Localization.Translate
                        stringId="accountRecovery_code_resent_timer"
                        values={{
                          timer,
                        }}
                      />
                    </span>
                  </div>
                )}

                <Button disableRipple variant="text" disabled={showResendTimer} onClick={handleResendCode}>
                  <Localization.Translate stringId="accountRecovery_resendCode" />
                </Button>
              </ResendButtonContainer>
            </Form>
          );
        }}
      </Formik>
    </StepContainer>
  );
};

export default StepTwo;
