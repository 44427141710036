import React, { useContext } from 'react';

const QueryParamsContext = React.createContext();

export const useQueryParams = () => {
  const context = useContext(QueryParamsContext);
  if (!context) {
    throw new Error(`useQueryParamContext cannot be rendered outside of the QueryParam context provider`);
  }
  return context;
};

export const QueryParamContext = ({ value, children }) => {
  return <QueryParamsContext.Provider value={value}>{children}</QueryParamsContext.Provider>;
};
