import { Theme, usePostMessageListener } from 'connex-cds';
import React, { useContext } from 'react';

const ThemeContext = React.createContext();

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    console.trace();
    throw new Error(`useThemeContext cannot be rendered outside of the ThemeContext context provider`);
  }
  return context;
};

const ConnexThemeProvider = ({ children }) => {
  const { themeName, setThemeName } = Theme.useThemeContext();

  const listener = React.useCallback(
    message => {
      if (message?.type === 'theme') {
        setThemeName(message.theme);
      }
    },
    [setThemeName]
  );

  usePostMessageListener(listener);

  return <ThemeContext.Provider value={{ themeName }}>{children}</ThemeContext.Provider>;
};

export const ThemeProvider = ({ children }) => {
  return (
    <Theme.ThemeProvider>
      <ConnexThemeProvider>{children}</ConnexThemeProvider>
    </Theme.ThemeProvider>
  );
};
