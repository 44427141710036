import { find } from 'lodash';
import React from 'react';
import { useAppsQuery } from '../api/query-hooks/useAppsQuery';

const parseHostname = hostname => {
  const split = hostname.split('.');
  return {
    appCode: split[0] || 'xxx',
    regionCode: split[1] || 'xx',
  };
};

const endpointString = 'REACT_APP_UI_';

const endpointOverrides = Object.keys(process.env)
  .filter(key => key.startsWith(endpointString))
  .reduce((acc, key) => {
    acc[key.replace(endpointString, '')] = process.env[key];
    return acc;
  }, {});

export const useAppConfig = ({ appRef, location, href }) => {
  const appsQuery = useAppsQuery();

  const { hostname } = window.location;

  const app = React.useMemo(() => {
    if (appsQuery.data) {
      return find(appsQuery.data, { crn: appRef });
    }
    return undefined;
  }, [appsQuery.data, appRef]);

  if (!app && appsQuery.isSuccess) throw new Error(`Unknown app, '${appRef}'`);

  const config = React.useMemo(() => {
    if (!appsQuery.data || !app) return undefined;

    let uiHostname;
    if (hostname === 'localhost') {
      if (location.pathname !== href && location.pathname.startsWith(href)) {
        // TODO: Explain this scenario (possibly related to failed attempt of syncing addresses bar with iframed path)
        uiHostname = `${
          endpointOverrides[appRef] || `http://localhost:${app.port || '3300'}`
        }${location.pathname.replace(href, '')}`;
      } else {
        uiHostname = `${endpointOverrides[appRef] || `http://localhost:${app.port || '3300'}`}${app.path || ''}`;
      }
      return {
        id: appRef,
        hostname: uiHostname,
        apiKey: app.apiKey || '',
      };
    }

    const { appCode, regionCode } = parseHostname(hostname);

    const serviceEnvironment = ['-cool', '-ice'].reduce((acc, value) => {
      if (appCode.endsWith(value)) {
        acc = `${appRef}${value}`;
      }
      return acc;
    }, appRef);

    uiHostname = `https://${serviceEnvironment}.${regionCode}.commandalkon.io/${app.path || ''}`;
    return {
      id: appRef,
      hostname: uiHostname,
      apiKey: app.apiKey,
    };
  }, [app, appsQuery.data, hostname, href, location.pathname, appRef]);

  return config;
};
