import styled from '@emotion/styled';
import { Header, Layout } from 'connex-cds';
import React from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom';
import { LOGIN } from '../../constants/routes';
import { useAppContext } from '../../providers/AppProvider';
import { useAuthContext } from '../../providers/AuthProvider';
import { useEntityContext } from '../../providers/entity-provider/EntityProvider';
import { useUserContext } from '../../providers/UserProvider';
import { ProductionWarning } from '../authenticated-layout/header/ProductionWarning';
import { Profile } from '../authenticated-layout/profile/Profile';
import { MonitorApp } from './MonitorApp';
import { MonitorEntity } from './MonitorEntity';
import { MonitorNotifications } from './MonitorNotifications';

import { AuthenticatedRoutesStyles } from './styles';

const Styled = styled(Layout.Column)`
  ${AuthenticatedRoutesStyles}
`;

const AuthenticatedRoutes = () => {
  const location = useLocation();
  const { appRef } = useParams();
  const { isUserAuthenticated } = useAuthContext();
  const appContext = useAppContext();
  const entityContext = useEntityContext();
  const userContext = useUserContext();

  const [isProfileOpen, setIsProfileOpen] = React.useState(false);

  const handleProfileClick = React.useCallback(() => {
    setIsProfileOpen(true);
  }, []);

  return isUserAuthenticated ? (
    <Styled className="auth-routes">
      <Profile isOpen={isProfileOpen} setIsOpen={setIsProfileOpen} />
      <MonitorApp />
      <MonitorEntity />
      <MonitorNotifications />
      <ProductionWarning />
      {appRef ? null : (
        <Header
          className="connex-header"
          appContext={appContext}
          entityContext={entityContext}
          userContext={userContext}
          onChange={appContext.setAppRef}
          onProfileClick={handleProfileClick}
          disableAppTray
          disableEntityPicker
        />
      )}
      <Outlet />
    </Styled>
  ) : (
    <Navigate to={LOGIN} state={{ from: location }} replace />
  );
};

export default AuthenticatedRoutes;
