import { css } from '@emotion/react';

export const EmptySearchStyles = ({ theme }) => css`
  max-width: 528px;
  text-align: center;
  color: ${theme.colorsCA.neutral[100]};
  font-style: normal;

  .search-empty {
    width: 135px;
    height: 126px;
    margin-bottom: 35px;
  }

  h2 {
    color: ${theme.colorsCA.neutral[100]};
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 19px;
  }

  p {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
  }
`;

export const EntityOptionStyles = ({ theme }) => css`
  .entity-button {
    color: ${theme.colorsCA.neutral[95]};
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    height: 100%;

    display: flex;
    padding: 20px;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    border: 1px solid #938f99;
    background: rgba(28, 27, 31, 0.4);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    text-align: left;

    .entity-label {
      flex: 1;
      text-wrap: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &:hover {
      background: rgba(67, 65, 73, 0.4);
    }
  }
`;

export default ({ theme }) => css`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    max-height: 718px;
    max-width: 1082px;
    width: 100%;

    .title {
      color: ${theme.colorsCA.neutral[100]};
      font-size: 32px;
      font-weight: 600;
      line-height: 36px;
      margin: 0;
    }
  }

  .search-filter {
    .MuiOutlinedInput-root.MuiInputBase-root {
      border-radius: 10px;
      border: 1px solid #938f99;
      background: rgba(28, 27, 31, 0.4);
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3);
      width: 390px;

      .MuiSvgIcon-root {
        color: ${theme.colorsCA.neutral[99]};
      }

      .MuiOutlinedInput-input,
      .MuiOutlinedInput-input::placeholder {
        color: ${theme.colorsCA.neutral[90]};
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        opacity: 1;
      }
    }

    .MuiOutlinedInput-root,
    .MuiOutlinedInput-root.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: none;
      }
    }
  }

  .entities-container {
    margin: 0;
    padding: 4px;
    list-style: none;

    display: grid;
    grid-template-columns: repeat(2, minmax(320px, 491px));
    grid-auto-rows: 81px;
    gap: 20px;
    overflow: auto;

    ::-webkit-scrollbar-track {
      border-radius: 100px;
      background: rgba(28, 27, 31, 0.25);
      backdrop-filter: blur(40px);
    }
    ::-webkit-scrollbar {
      width: 7px;
      border-radius: 100px;
      background: rgba(28, 27, 31, 0.25);
      backdrop-filter: blur(40px);
    }
    ::-webkit-scrollbar-thumb {
      width: 7px;
      border-radius: 100px;
      background: rgba(110, 159, 189, 0.75);
      backdrop-filter: blur(40px);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
  }
`;
