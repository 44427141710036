import React from 'react';
import styled from 'styled-components';
import cn from 'classnames';

const Styled = styled.div`
  padding: 50px 20px;
  height: 100%;
  overflow: auto;
  background-color: white;

  h4 {
    text-align: center;
  }

  ol {
    padding-left: 15px;
    li {
      p {
        text-indent: 15px;
      }
    }
  }
`;

const Privacy = props => {
  return (
    <Styled className={cn('privacy')}>
      <b>
        <h4>
          CONNEX® SUBSCRIBER AGREEMENT (<q>Agreement</q>)
        </h4>
      </b>
      <br />
      <b>
        <p>
          IMPORTANT PLEASE - READ CAREFULLY: This SUBSCRIBER AGREEMENT (this <q>Agreement</q>) is a legal agreement
          between the person or legal entity agreeing to this Agreement (<q>Subscriber</q> or <q>You</q> or <q>Your</q>)
          and Command Alkon Incorporated (<q>Company</q> or <q>We</q> or <q>Us</q> or <q>Our</q>), and governs Your use
          of Company's CONNEX Platform, including all software applications, data and databases, Platform Data (defined
          below) and services provided by or through such platform and all downloadable mobile and other software
          applications associated therewith, and any successor platform(s) to the foregoing (collectively, the{' '}
          <q>Platform</q> or <q>CONNEX</q>). By accepting this Agreement, You agree to comply with and be subject to the
          terms and conditions of this Agreement, including Company's
          <b>
            <a target="_blank" href="https://commandalkon.com/privacy-policy/">
              Privacy Policy
            </a>
          </b>
          , which is incorporated in this Agreement by reference.
        </p>
        <p>
          The individual accepting this Agreement for Subscriber represents to Company that he or she is an authorized
          signatory of Subscriber with authority to bind Subscriber to this Agreement. If such person does not have this
          authority, do not sign up Subscriber to the Platform.
        </p>
        <p>
          Any individual accessing and using the Platform, including on a personal computer, tablet or mobile device (a{' '}
          <q>Device</q>), may only do so in her/his capacity as an employee or other representative of Subscriber that
          has been authorized by Company to use the Platform on Subscriber's behalf (each an <q>Authorized User</q>).
          Any Personal Information (defined below), including if applicable any geolocation information, that is
          submitted by or collected from an Authorized User and/or such Device is deemed to be submitted by and on
          behalf of Subscriber.
        </p>
        <p>
          If Subscriber is an existing customer of Company and has executed a Master License and Services Agreement (
          <q>MLSA</q>) with Company, then this Agreement is subject to such MLSA in accordance with Section 20(b) of
          this Agreement.
        </p>
        <p>
          If You do not agree to the above, or any other terms of this Agreement, You should immediately cease using the
          Platform and the Services.
        </p>
      </b>
      <ol>
        <li>
          <b>
            <p>The Platform; Access to the Services.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                CONNEX allows participants in a construction material supply chain, including suppliers, purchasers and
                transportation providers, to communicate with each other and digitally share business data like and/or
                regarding jobs, orders, tickets, and transactions. The services provided under this Agreement, including
                the collection and processing of Your Subscriber Data (defined below), the exchange of data between You
                and other Participants (defined below), and the information, analytics and reports provided to You
                (collectively, the{' '}
                <q>
                  <b>Services</b>
                </q>
                ), are delivered to You through the Platform in the form of software-as-a-service. You may access the
                Platform and the Services solely through the internet website portal designated by Company, through a
                mobile app supplied by Company, or through another authorized medium that may be specified by Company
                from time to time. This Agreement grants Subscriber a limited, revocable, non-exclusive,
                non-sublicenseable, and non-transferable right to access and use the Platform solely through an
                authorized medium, and solely for Your internal business purposes, strictly in accordance with this
                Agreement.
              </p>
            </li>
            <li>
              <p>
                Subscriber and its Authorized Users are not granted hereunder any license under Company’s software or
                other intellectual property, and other than the limited rights to access and use the Platform and
                Services expressly granted hereunder, no rights are granted hereunder with respect to any intellectual
                property of Company.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <p>Subscriber Eligibility.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                Your eligibility to access and use the Platform and Services is at all times subject to the acceptance
                by Company of Your application for account registration and Your continuing compliance with this
                Agreement. You represent, warrant, and covenant that: (i) You are a business entity duly formed and
                validly existing under the laws of its jurisdiction of formation; (ii) You have the power and capacity
                to enter into and to perform Your obligations under this Agreement; (iii) this Agreement has been duly
                authorized, executed, and delivered by You and constitutes a valid and binding obligation of You,
                enforceable in accordance with its terms; (iv) Your execution, delivery, and performance of this
                Agreement does not violate or result in any default under Your governing and constituent documents,
                bylaws, policies, or any other contract or agreement to which You are a party or by which Your assets
                may be bound, or any law, statute, rule, regulation, or order of any country or political subdivision,
                or any regulatory, self-regulatory, or membership organization that has jurisdiction over You, or of
                which You are a member; (v) You will use the Platform only for Your own business and not for the use or
                benefit of any third party; and (vi) You will designate Authorized Users to access and use the Platform
                on Your behalf, each of whom is at least 18 years of age. We reserve the right to charge You a fee,
                subscription charge or other payment for access and use of the Platform, which fee will be specified in
                a written or electronic agreement between You and Company.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <p>Use Restrictions.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                You agree not to (directly or indirectly): (i) access or attempt to access the Platform or Services
                through any means other than the specific internal portal and other means specified by Company; (ii)
                copy or download any data or content (including aggregated or derivative data, analytics, images,
                photographs, animations, video, audio, music, text, and associated printed materials or online or
                electronic documentation) within or accessible through the Platform (
                <q>
                  <b>Platform Data</b>
                </q>
                ), other than Your Subscriber Data and the subscriber data of other Participants (defined below) that
                you have been authorized to access as described in Section 8 below; (iii) except to the limited extent
                applicable laws specifically prohibit such restriction, adapt, translate, decompile, reverse engineer,
                reprint, transcribe, extract, reproduce, modify, alter, disassemble, create derivative works based on,
                attempt to determine the source code of, in any way ascertain, decipher, or obtain the communications
                protocols for accessing, or the underlying ideas or algorithms of, or otherwise copy, the Platform or
                the software comprised therein; (iv) remove or obscure any notice of proprietary rights from the
                Platform; (v) use any portion of the Platform or Services to create a site or service that is in any way
                competitive with the Platform; (vi) sell, resell, encumber, rent, lease, time-share, distribute,
                transfer or otherwise use or exploit or otherwise provide any third party access to the Platform; (vii)
                copy, reproduce, republish, scan, upload, post, transmit, distribute, or otherwise make available to any
                third party any Platform Data or Services, or any portion of the foregoing, to or for the benefit of any
                third party; (viii) remove, disable, circumvent or otherwise create or implement any workaround to any
                copy protection, rights management or security features in or protecting the Platform; (ix) create any
                database of Platform Data for any purpose other than Your internal use in Your business as permitted
                hereunder, including to create any commercial product or services; (x) use manual or automated software,
                devices, or other processes to "crawl" or "spider" any page of the Platform or "harvest" or "scrape" any
                Platform Data; (xi) take any action that imposes or may impose (as determined by Company in its sole
                discretion) an unreasonable or disproportionately large load on Company's infrastructure, or interfere
                or attempt to interfere with the proper functioning of the Platform or any activities conducted on the
                Platform; (xii) run any form of auto-responder or "spam" on the Platform; (xiii) use any software,
                hardware or other services to modify the number of computers or seats that access or utilize the
                Platform outside of the validly licensed number of each, including for purposes of "multiplexing,"
                "pooling," or “virtualization;” and (xiv) otherwise take any action in violation of this Agreement.
              </p>
            </li>
            <li>
              <p>
                You agree that You will timely respond to Our reasonable inquiries regarding Your compliance with this
                Section 3(a)
              </p>
            </li>
            <li>
              <p>
                You agree that any copies, derivative works or other intellectual property developed by You in violation
                of this Section 3: (i) are the property of Company and You hereby assign, and We hereby accept such
                assignment, any and all associated rights to Company without the need for any other action by a party;
                and (ii) are excluded from any warranty or other obligation of Company hereunder.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <p>Login Credentials.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                Subscriber Credentials. Following approval of Your application for registration of an account, You will
                be assigned a login ID, passwords and other credentials issued to access the Platform (
                <q>
                  <b>Credentials</b>
                </q>
                ). You shall maintain the confidentiality of the Credentials and not permit any third party to use Your
                Credentials to access or use the Platform. Without limiting the foregoing, You are responsible to
                Company for the use of the Platform by any person with whom You have shared Your Credentials or who
                gains access to the Platform as a result of Your failure to use reasonable security precautions, even if
                that use was not authorized by You.
              </p>
            </li>
            <li>
              <p>
                Authorized Users. You may authorize Your Authorized User(s) to access the Platform on Your behalf. You
                may authorize access by such Authorized User(s) via the Platform or by sending Company a request in
                writing. We may deny access for any Authorized User with or without cause. You will share Platform Data
                only with those Authorized Users who are permitted to see such Platform Data. You are responsible for
                each Authorized User’s compliance with this Agreement and any other agreement applicable to such
                Authorized User’s use of the Platform and related services. You are responsible for all access and use
                of the Platform by Your Authorized Users. The individual who signs up for the Platform on Your behalf
                will be designated as the administrator of Your account (
                <q>
                  <b>Account Administrator</b>
                </q>
                ), unless You designate another Authorized User to act as Account Administrator by notifying Company in
                writing. The Account Administrator may be given certain and special access rights with respect to Your
                account that are not given to other Authorized Users, including the ability to authorize and
                de-authorize other Authorized Users to access Your account, and to authorize the sharing of certain
                information as discussed in Section 8 below.
              </p>
            </li>
            <li>
              <p>
                Authorized User Credentials. Your Account Administrator will be responsible for authorizing and
                de-authorizing individuals as Your Authorized Users. Each Authorized User so designated will be assigned
                Credentials. You shall ensure that each Authorized User keeps all Credentials strictly confidential, and
                that Credentials are used only by the applicable Authorized User and not used by or disclosed to any
                other person including any other Authorized User. We may rely on any activity on the Platform conducted
                through use of Credentials as being authorized activity of You. If there is a change in the eligibility
                of any Authorized User to access the Platform on Your behalf (including where the Authorized User has
                ceased to be employed or engaged by You), You must promptly (but no later than within three (3) days)
                de-authorize such individual through the means provided to Your Administrator on the Platform. You must
                immediately notify Us if You become aware of or reasonably suspect any breach of security or
                unauthorized use of Your account or any associated Credentials. Upon receipt of the foregoing notice, We
                may in our sole discretion suspend or disable the applicable Credentials. You shall be responsible for
                any actions taken on Your behalf that occur prior to such notification.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <p>Ownership of Platform.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                You agree that, as between You and the Company, the Platform, including all software, databases, and
                Platform Data contained therein, and all updates, enhancements or derivatives of the foregoing, all
                information and data generated, collected or derived by the Platform other than Subscriber Data (defined
                below) and all intellectual property rights in the foregoing, are owned by Company, and are protected by
                copyright and other intellectual property laws and international treaties. Other than the limited access
                rights granted to You under this Agreement, You do not acquire any rights or ownership interest in the
                Platform (including any Platform Data), or any other rights thereto, including by implication or
                estoppel, other than the rights expressly granted to use the Platform subject to all terms, conditions
                and restrictions of this Agreement. Company and its licensors and service providers reserve and shall
                retain their entire right, title and interest in and to the Platform and Platform Data, including all
                copyrights, trademarks and other intellectual property rights therein or relating thereto.
              </p>
            </li>
            <li>
              <p>
                You acknowledge and agree that, in connection with the provision, operation and improvement of the
                Platform and the associated services, Company will, and You authorize Company to: (i) create aggregated
                or derivative data that is derived from data contained in the Platform, including from Your Subscriber
                Data (defined below); and (ii) collect and create, including through automatic means (including, for
                example, tools, scripts, software, cookies, web beacons and utilities), information about You or Your
                Authorized Users’ use of the Platform (collectively, (i) and (ii), are included in{' '}
                <q>
                  <b>Platform Data</b>
                </q>
                ). You agree that Platform Data is and shall be the exclusive property of Company and is deemed to be
                Company IP, and nothing herein shall limit Company’s use or exploitation thereof for any purpose and
                without limitation. For the avoidance of doubt, Platform Data does not include any Personal Information,
                but may include information that has been de-identified or aggregated.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <p>Operation of Platform.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                Company has the right at all times to continually modify the Platform (including the software
                applications, analytics, Platform Data, or any other Platform components) as Company may determine in
                its sole discretion and without notice to You, including to add or remove functionality or Platform
                Data. Such modifications may be made due to external factors (including changes in law or legal or
                regulatory guidance, changes to industry practice, market factors, and changes to external costs),
                internal factors (including product, research, design, or personnel changes, changes to technology,
                security concerns, or updated cost/benefit analyses) or any combination of the foregoing or other
                factors. Company does not guarantee that any Platform Data will be made available or will continue to be
                available on the Platform. Company reserves the right, but has no obligation, to remove, edit, modify,
                or block any Platform Data, in each case in Company’s sole discretion, at any time, without prior notice
                to You and for any reason (including upon receipt of claims or allegations from third parties or
                authorities relating to such Platform Data, or if Company is concerned that You may have violated this
                Agreement).
              </p>
            </li>
            <li>
              <p>
                You acknowledge that the Platform will be delivered as a service, and that We have no delivery
                obligation and will not deliver copies of any software comprised in or related to the Platform to You,
                provided that, in order to access the Platform on a personal computer, tablet, mobile phone or other
                mobile device, You or Your Authorized User may in some cases be required to download an application,
                applet, browser add-in, mobile application or other program (an{' '}
                <q>
                  <b>Application</b>
                </q>
                ) that is appropriate for the applicable device. You must download an appropriate version of the
                Application for each of Your devices, and each such download is licensed for installation on a single
                device and may not be copied onto any other computer, device or other medium. You may not loan, rent,
                lease or otherwise transfer the Application to another user. You or Your Authorized User may be required
                to accept a mobile application user agreement in connection with the download and installation of the
                Application, pursuant to which the applicable end-user will be granted a limited, revocable,
                non-exclusive, non-sublicenseable, and non-transferable license to use the Application and the Platform
                solely for Your internal business purposes, strictly in accordance with this Agreement and any mobile
                end user agreement. Company (and/or its licensors and service providers) retains title and ownership of
                the Application and any copies thereof, regardless of the form or media in which or on which the
                original and other copies may exist. The Application is licensed as a single product, and its component
                parts may not be separated for use on more than one computer or device.
              </p>
            </li>
            <li>
              <p>
                You are responsible for, and shall provide and install, Your own hardware, telecommunications, and
                computing environment as necessary or designated by Us from time to time for accessing the Platform. It
                is anticipated that technology generally, and the Platform specifically, will evolve over time, and You
                understand that the requirements for such hardware or software may also change from time to time. You
                are responsible for all connection and line charges, including long distance, data plans, and roaming
                charges. For the avoidance of doubt, it is understood that telecommunications systems and connectivity
                by You to the Internet, and otherwise on Your side of the Internet, are complex and outside of Our
                control and responsibility, and therefore Our obligation to make the Platform accessible shall be
                limited to providing an operational system that is accessible via a computer remote from the server via
                the Internet, and shall be deemed satisfied if such a computer is able to access the Platform with the
                Credentials provided to You, regardless of whether You experience connection difficulties.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <p>Subscriber Data.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                In connection with Your use of the Platform, You may provide to the Platform and/or the Platform may
                collect from You, information, including information relating to You and Your transactions and other
                activity (collectively,
                <q>
                  <b>Subscriber Data</b>
                </q>
                ). You agree that, as between You and the Company, You are and will be the sole and exclusive owner of
                all Subscriber Data. You represent that You have sufficient right under applicable law to transmit,
                store, copy, use and transfer into the Platform all Subscriber Data, sent to, from, or stored on the
                Platform using any Credentials, or otherwise provided by You to Us. Subject to Sections 9(c) and 9(d),
                You grant Us authorization to access, use, view, store, copy, create derivative works from and delete
                any Subscriber Data, as necessary to: (i) provide the Services to you; (ii) operate, maintain, improve,
                transmit, and develop the Platform; (iii) develop products and services, including summaries, reports
                and analytics of Subscriber Data; (iv) make Your Shared Data (defined below) available to other
                Participants in accordance with Section 8; (v) use Subscriber Data in other manners as You may authorize
                from time to time; and (vi) create aggregated and/or derived or analytics Platform Data pursuant to
                Section 5(b). You acknowledge that Our own operations and Our use of third parties to operate, maintain,
                improve, and develop the Platform may result in Subscriber Data being stored or processed on Our
                servers, which may be located in various countries around the world. For the avoidance of doubt,
                Subscriber Data does not include any Platform Data. Following termination or expiration of this
                Agreement, Your Shared Data and Subscriber Profile Information (defined below) will no longer be
                available to other Participants, but we will have the right to continue to maintain Your Subscriber Data
                within our databases for use as described above, subject to the terms of this Agreement, including
                Section 9.
              </p>
            </li>
            <li>
              <p>
                You acknowledge that there are risks inherent in Internet connectivity that could result in the loss of
                Your privacy and property or disclosure of Your confidential information. Company will implement
                reasonable and appropriate measures to maintain the security of Subscriber Data. Company’s security
                measures will be consistent with reasonable standards used in the applicable software or information
                technology industry, and will reasonably protect the privacy, confidentiality, integrity, and
                availability of Subscriber Data. Company’s security measures and procedures will include reasonable
                administrative, physical, technical, and procedural safeguards. You will comply with all applicable
                security and other procedures imposed by or applicable to the network or platform that You use to access
                and use, and to submit Subscriber Data to, the Platform.
              </p>
            </li>
            <li>
              <p>
                You agree to maintain copies of all Subscriber Data that You provide to Company, as may be necessary to
                replace Your critical Subscriber Data in the event of loss or damage to such data from any cause.
              </p>
            </li>
            <li>
              <p>
                You acknowledge and agree that, in connection with the provision of the Platform and the associated
                services: (i) Company will access, copy, host, store, analyze, make derivatives and/or transmit the
                Subscriber Data, and You hereby grant to Company the right to do any and all of the foregoing in
                connection with its operation, development, improvement and maintenance of the Platform and the
                performance of its obligations hereunder; and (ii) Company may retain subcontractors, consultants and
                other third parties to assist or support Company in operating, developing, improving or maintaining the
                Platform and/or or providing the associated services, and may disclose Subscriber Data to such third
                parties for use in connection with providing such assistance and support to Company.
              </p>
            </li>
            <li>
              <p>
                Company may use tools, scripts, software, cookies, web beacons and utilities to monitor and administer
                the Platform, including to operate, maintain, improve, and develop the Platform, to ensure service
                quality, to evaluate and maintain the Platform and the security thereof, and to evaluate compliance with
                this Agreement, applicable laws, rules, or regulations. We do not tolerate the piracy of any Company
                software, and we pursue (both civilly and criminally) those who do so using all legal means available,
                including public and private surveillance resources. As part of these efforts, Company reserves the
                right to embed a software security mechanism within the Platform to monitor usage of the Platform to
                detect and deter violations of, and verify Your compliance with, this Agreement. You agree that
                Company’s monitoring activities will not entitle You to any cause of action or other right with respect
                to the manner in which Company monitors Your usage of the Platform and enforces, or fails to enforce,
                this Agreement.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <p>Sharing Principles.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                It is a feature of the Platform to enable a Subscriber to communicate, interact with, and exchange data
                with, other participants in the Platform (each, a{' '}
                <q>
                  <b>Participant</b>
                </q>
                ), including both upstream and downstream Participants within Subscriber’s supply chain. You agree to
                the following principles pursuant to which certain of Your Subscriber Data will be accessible to and
                disclosed to other Participants (the{' '}
                <q>
                  <b>Sharing Principles</b>
                </q>
                ):
              </p>
              <ol type="i">
                <li>
                  <p>
                    Your Subscriber Profile Information will be visible to all Participants.{' '}
                    <q>
                      <b>Subscriber Profile Information</b>
                    </q>{' '}
                    means Your name, Your business mailing address, the names and locations of any projects or jobs You
                    have entered into the Platform, and a general description of Your area of business;
                  </p>
                </li>
                <li>
                  <p>
                    Your Account Administrator will have the ability to set certain parameters within your account to
                    enable additional categories of Your Subscriber Data (including Personal Information, defined below)
                    to be shared with other Participants, and in certain cases limit such sharing only to particular
                    Participants. You will have the ability to make changes to such sharing parameters.
                  </p>
                </li>
              </ol>
              <p>
                (all Subscriber Data shared with other Participants pursuant to the Sharing Principles is the{' '}
                <q>
                  <b>Shared Data</b>
                </q>
                ). Company may modify the Sharing Principles from time to time, and such modified Sharing Principles
                will be binding on You upon notice of such modification. You hereby agree and authorize Company to share
                Your Subscriber Data with other Participants consistent with the Sharing Principles. If at any time You
                do not agree to the sharing of Your Subscriber Data as specified in the Sharing Principles, You should
                cease all use of the Platform and ask Company to close Your account.
              </p>
            </li>
            <li>
              <p>
                You acknowledge that other Participants may share their Subscriber Data with You, and You agree that You
                will use all such Shared Data solely for Your own business and the purposes for which such other
                Participants shared their Subscriber Data with You (including disclosing to third parties if necessary
                for Your business purposes), and will not otherwise sell, resell, disclose, redistribute, transfer or
                otherwise use or exploit or make available such Shared Data for any other purpose.
              </p>
            </li>
            <li>
              <p>
                Any communications and sharing of Subscriber Data between You and other Participants by and through the
                Platform are solely between You and the applicable other Participants, and Company and the Platform are
                only providing a medium for such communications and data sharing. Company has no responsibility for the
                content of such communications and data sharing. Company reserves the right in its discretion to
                terminate any Participant that uses Shared Data in any manner that is in violation of this Agreement.
                Notwithstanding anything to the contrary herein, You acknowledge and agree that Company has no
                responsibility or liability to You for: (i) any use or misuse of any of Your Shared Data by other
                Participants; or (ii) any violation of this Agreement by any other Participant.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <p>Collection and Use of Personal Information.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                Both parties undertake to comply with applicable laws governing the collection, processing and use of
                information about an individual or from which the identity of an individual can reasonably be
                ascertained (
                <q>
                  <b>Personal Information</b>
                </q>
                ) (including applicable U.S. federal and state privacy laws, the E.U. General Data Protection
                Regulation, and applicable data protection laws of other jurisdictions worldwide) (collectively,{' '}
                <q>
                  <b>DP Laws</b>
                </q>
                ).
              </p>
            </li>
            <li>
              <p>
                The parties acknowledge that the Subscriber Data provided by or on behalf of, or collected from, You or
                Your Authorized Users may include Personal Information. You represent that You have sufficient rights
                under applicable law, and have obtained all necessary consents and provided any required notifications
                under applicable law (including all DP laws) to transmit, store, copy, use and transfer to Company for
                use in accordance with this Agreement all Subscriber Data, including any Personal Information, sent to,
                from, or stored on the Platform using Credentials assigned to You and Your Authorized Users, or
                otherwise provided by You to Company as part of Our administration of the Platform and related services.
                You hereby irrevocably consent and agree that the Subscriber Data submitted to the Platform, including
                any Personal Information, may be stored on servers not located within the United States or European
                Union, and accessed remotely by Company from anywhere in the world.
              </p>
            </li>
            <li>
              <p>
                Subject to the terms of this Agreement, including Section 8 and 9(d), any Personal Information that
                Company collects from Subscriber (or its Authorized Users) will be held and maintained in accordance
                with Company’s Privacy Policy (see link above), which is incorporated in this Agreement by reference.
                Our Privacy Policy may be changed from time to time, and in the event of such change we will post a
                notice on the Platform. Any modified Privacy Policy will be applicable to all Personal Information then
                held by Company, including Personal Information collected prior to the modification. If You do not agree
                to any modified Privacy Policy, You should terminate Your use of the Platform and all associated
                services and Applications.
              </p>
            </li>
            <li>
              <p>
                Notwithstanding anything to the contrary herein, Company hereby agrees that with respect to any Personal
                Information that Company collects from Subscriber (or its Authorized Users) or processes on behalf of a
                Subscriber, Company is a service provider and a data processor acting on behalf of Subscriber, and
                Company agrees that it will: (i) not retain, use, disclose and otherwise process such Personal
                Information other than for the purpose of performing the Services; (ii) not sell or otherwise disclose
                such Personal Information for a commercial purpose other than providing the Services (in the case of
                each of (i) and (ii) except as permitted under applicable law); and (iii) cooperate as reasonably
                requested by Subscriber (at Subscriber's expense) to enable Subscriber to comply with its obligations
                under applicable law (including the CCPA (defined below)) to respond to consumer requests regarding
                Personal Information processed by the Company in providing the Services. For purposes of the California
                Consumer Privacy Act of 2018 (Cal. Civ. Code §§ 1798.100 - 1798.199) (
                <q>
                  <b>CCPA</b>
                </q>
                ), references in this Section 9(d) to “service provider,” “sell” and “commercial purpose” have the
                meanings specified in the CCPA, and Subscriber is the “Business” as such term is defined in the CCPA.
              </p>
            </li>
            <li>
              <p>
                Where in connection with the Platform we process Subscriber Data that constitutes “personal data” as
                defined under the GDPR, we are doing so as a “data processor” (as defined in the GDPR) on Your behalf.
                We shall: (i) only process such personal data in accordance with Your instructions which include use of
                such personal data as part of providing the Platform; and (ii) take appropriate technical and
                organization measures to prevent unauthorized or unlawful processing of such personal data and against
                accidental loss or destruction of, or damage to, such personal data. You acknowledge that We may
                transfer any such personal data outside of Your jurisdiction and, in any event, from jurisdiction to
                jurisdiction, but any such transfer shall not relieve Us from Our obligations under this clause, and You
                and We will enter into any required data processing agreements or separate data transfer agreements as
                required to effectuate such transfer per applicable data protection law. You hereby acknowledge and
                agree that We may appoint sub-contractors who may act as sub-processor(s) of the personal data, and that
                You shall have an opportunity to object to such sub-processor(s) if required by applicable data privacy
                law. We maintain an up-to-date list of such sub-processors on the
                <a
                  href="https://commandalkon.com/wp-content/uploads/2019/10/Command-Alkon-Incorporated-Data-Sub.pdf "
                  target="_blank"
                >
                  Legal Section of Our website
                </a>{' '}
                and we will provide a notification on the Platform in the event of a change to this list.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <p>Connectivity Between the Platform and Other Systems.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                Company may establish, from time to time, connectivity between the Platform and a software application,
                service or platform provided by a third party (a{' '}
                <q>
                  <b>Third-Party System</b>
                </q>
                ). If You are a Subscriber to both the Platform and such Third-Party System, then You may be able to
                utilize such connectivity to exchange certain information between the Platform and the Third-Party
                System. This connectivity is made available to You as a convenience only, and You acknowledge and agree
                that, notwithstanding this connectivity, Company is not responsible for any of the services or content
                provided by the Third-Party System, or for any data held by or transferred to such Third-Party System,
                and Your use of such Third-Party System is subject to any agreement between You and the provider of such
                Third-Party System.
              </p>
            </li>
            <li>
              <p>
                Company may, from time to time, make available to You certain information or means to allow You to
                develop connectivity between the Platform and a Third-Party System. Such connectivity information and
                means may include an Application Programming Interface (
                <q>
                  <b>API</b>
                </q>
                ), a Software Development Kit (
                <q>
                  <b>SDK</b>
                </q>
                ) and associated software, an export or integration, or other technical means or information. Such API,
                SDK or other connectivity information and means typically will be made available pursuant to the terms
                of a separate license agreement, which will permit You to establish such connectivity solely pursuant to
                the terms of that agreement. You agree that you will use such connectivity only for Your own business,
                and only for the particular purpose expressly permitted in that connectivity agreement, which may
                include exchanging data with other platforms and third parties as necessary for Your own business
                purposes, but You will not otherwise use or exploit or make available such connectivity, or any data
                transferred using such connectivity, for any other purpose, including to develop or support a commercial
                product or service competitive with the Company’s products or services. You are solely responsible for
                any such software or hardware You develop or use for such connectivity, and for any such software,
                system or platform used to connect to the Platform as permitted in the applicable connectivity
                agreement.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <p>Suspension of Services.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                We have the right to take appropriate action to address risks to the Platform, including any data or
                other content therein. Without limiting the foregoing, in the event of a severe or imminent threat to
                the Platform, We may immediately suspend Your right to access or use any portion or all of the Platform
                and related services if: (i) We reasonably believe that the Platform is being used in violation of the
                associated agreements or applicable law; (ii) in Our determination, Your use of the Platform interferes
                with the normal operations of the Platform or other Participants, or creates any threat to the security
                of the Platform or the content of any other Subscriber; (iii) We become aware of what We, in our sole
                discretion, deem a credible claim that the Platform infringes upon the intellectual property rights of a
                third party; or (iv) We are required to do so by law. We will not be liable for any claims or damages of
                any kind arising out of a suspension under this Section 11(a). We may maintain a suspension for as long
                as reasonably necessary to address severe, imminent risks to the Platform or any Subscriber content. Our
                right to suspend under this Section 11(a) is in addition to Our right to terminate pursuant to Section
                14 herein.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <p>Updates.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                Company may from time to time in its sole discretion develop and provide Platform updates, which may
                include bug fixes, patches and other error corrections, upgrades and/or new features (collectively,
                including related documentation,{' '}
                <q>
                  <b>Updates</b>
                </q>
                ). Updates also may modify or delete in their entirety certain features and functionality. You agree
                that Company has no obligation to provide any Updates or to continue to provide or enable any particular
                features or functionality. You further agree that all Updates will be deemed part of the Platform and be
                subject to all terms and conditions of this Agreement.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <p>Third Party Materials.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                The Platform may display, include or make available third-party content (including data, information,
                software applications and other products, services and/or materials) or provide links to third-party
                websites or services, including through third-party advertising (collectively,
                <q>
                  <b>Third-Party Materials</b>
                </q>
                ). You acknowledge and agree that Company is not responsible for Third-Party Materials, including their
                accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any
                other aspect thereof. Company does not assume and will not have any liability or responsibility to You
                or any other person or entity for any Third-Party Materials. Third-Party Materials and links thereto are
                provided solely as a convenience to You and You access and use them entirely at Your own risk and
                subject to such third parties' terms and conditions.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <p>Termination.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                Without prejudice to any other rights or remedies at law or in equity, Company may terminate this
                Agreement and Your right to access and use the Platform, without notice and without any obligations to
                You, if: (i) You fail to comply with the terms and conditions of this Agreement; or (ii) if Company
                ceases to provide or operate the Platform.
              </p>
            </li>
            <li>
              <p>You may terminate this Agreement at any time by closing Your account.</p>
            </li>
            <li>
              <p>
                Either party may terminate this Agreement immediately with written notice to the other party in the
                event that the other party becomes subject to any bankruptcy or insolvency proceeding under federal or
                state statute.
              </p>
            </li>
            <li>
              <p>
                Upon termination or expiration of this Agreement: (i) all rights granted to You under this Agreement
                will also terminate; (ii) You must cease all access to and use of the Platform (including by all of Your
                Authorized Users); and (iii) You must uninstall and delete all copies of any Applications installed on
                your Device(s) or otherwise held by You. The following provisions will survive termination or expiration
                of this Agreement: 3 (Use Restrictions), 5 (Ownership of Platform), 7 (Subscriber Data), 8 (Collection
                and Use of Personal Information), 14 (Termination), 15 (Disclaimers of Warranties; Limitation of
                Liability), 16 (Indemnification), 19 (Governing Law and Venue) and 20 (General Provisions).
              </p>
            </li>
            <li>
              <p>
                Upon Your written request to Company made within sixty (60) days of expiration or termination of this
                Agreement, Company will deliver to You (or provide You a means to download) a copy of Your Subscriber
                Data, in a commercially available file format. Company has no obligation to maintain any data on Your
                behalf after such sixty (60) day period.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <p>DISCLAIMERS OF WARRANTIES; LIMITATIONS OF LIABILITY.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                THE PLATFORM, AND THE CONTENT AND SERVICES PROVIDED THEREON, ARE PROVIDED “AS IS” AND COMPANY (ON BEHALF
                OF ITSELF AND ITS AFFILIATES, VENDORS, LICENSORS AND SERVICE PROVIDERS) HEREBY EXPRESSLY DISCLAIMS TO
                THE FULLEST EXTENT PERMITTED BY LAW ALL WARRANTIES, EXPRESS OR IMPLIED, ON THE PLATFORM AND CONTENT
                FURNISHED IN CONNECTION THEREWITH, INCLUDING ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, TITLE, NONINFRINGEMENT, SUITABILITY, ACCURACY OF DATA OR SYSTEM INTEGRATION,
                INTEGRITY, UPTIME AND/OR AVAILABILITY, OR ANY WARRANTIES ARISING BY STATUTE OR OTHERWISE IN LAW, OR FROM
                A COURSE OF DEALING, PERFORMANCE, USAGE OR TRADE PRACTICE. COMPANY (ON BEHALF OF ITSELF AND ITS
                AFFILIATES, VENDORS, LICENSORS AND SERVICE PROVIDERS) DOES NOT WARRANT THAT THE FUNCTIONS CONTAINED IN
                THE PLATFORM, CONTENT AND SERVICES WILL MEET YOUR REQUIREMENTS OR ACHIEVE ANY INTENDED RESULTS, BE
                COMPATIBLE OR WORK WITH ANY OTHER SOFTWARE, APPLICATIONS, SYSTEMS OR SERVICES, MEET ANY PERFORMANCE OR
                RELIABILITY STANDARDS OR BE ERROR FREE OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED, OR THAT
                THE OPERATION THEREOF WILL BE SECURE, UNINTERRUPTED, FREE FROM BUGS, VIRUSES OR ERRORS OR OTHER PROGRAM
                LIMITATIONS. YOU ACKNOWLEDGE AND AGREE THAT YOUR USE AND ANY RELIANCE UPON THE PLATFORM, CONTENT AND
                SERVICES IS AT YOUR OWN RISK.
              </p>
            </li>
            <li>
              <p>
                REGARDLESS OF WHETHER ANY REMEDY SET FORTH HEREIN FAILS OF ITS ESSENTIAL PURPOSE OR OTHERWISE, YOU AGREE
                THAT COMPANY, ITS AFFILIATES, VENDORS, LICENSORS AND SERVICE PROVIDERS, SHALL NOT BE LIABLE TO YOU OR
                ANY THIRD PARTY FOR ANY LIABILITY FOR: (i) PERSONAL INJURY OR PROPERTY DAMAGE; (ii) ANY CONSEQUENTIAL,
                INCIDENTAL, INDIRECT, PUNITIVE, OR SPECIAL DAMAGES OF ANY NATURE, WHETHER FORESEEABLE OR NOT, INCLUDING
                DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS, LOSS OR DESTRUCTION OF DATA, COMPUTER FAILURE OR
                MALFUNCTION, BUSINESS INTERRUPTION, COSTS OF COVER, LOSS OF USE, LOSS OF GOODWILL OR ANY OTHER LOSS, OR
                FOR EXEMPLARY DAMAGES; (iii) OR DIRECT DAMAGES IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT, IF
                ANY, ACTUALLY PAID BY YOU FOR THE PLATFORM; IN EACH CASE WHETHER RESULTING FROM YOUR USE OR INABILITY TO
                USE THE PLATFORM, CONTENT OR SERVICES, OR FOR DAMAGES RESULTING FROM OR RELATING TO CLAIMS BROUGHT
                AGAINST YOU BY THIRD PARTIES OR OTHERWISE, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, COMPANY (ON BEHALF OF ITSELF AND ITS
                AFFILIATES, VENDORS, LICENSORS AND SERVICE PROVIDERS) DISCLAIMS ANY LIABILITY FOR PRODUCT LIABILITY AS A
                CONSEQUENCE OF LOSS OR DAMAGE TO PROPERTY WHICH, IN VIEW OF ITS NATURE, IS NORMALLY INTENDED FOR
                COMMERCIAL USE. THESE LIMITATIONS OF COMPANY’S (AND ITS AFFILIATES’, VENDORS’, LICENSORS’ AND SERVICE
                PROVIDERS’) LIABILITY WILL APPLY REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT OR
                OTHERWISE. ANY ACTION AGAINST COMPANY MUST BE BROUGHT WITHIN TWELVE (12) MONTHS AFTER THE CAUSE OF
                ACTION ACCRUES. SOME JURISDICTIONS DO NOT ALLOW CERTAIN LIMITATIONS OF LIABILITY SO SOME OR ALL OF THE
                ABOVE LIMITATIONS OF LIABILITY MAY NOT APPLY TO YOU. IN NO EVENT SHALL OUR TOTAL LIABILITY TO YOU OR ANY
                THIRD PARTY FOR ANY REASON IN CONNECTION WITH THIS AGREEMENT EXCEED THE AMOUNT ACTUALLY PAID BY YOU TO
                US FOR USE OF THE PLATFORM IN THE TWELVE (12) MONTH PERIOD IMMEDIATELY PRIOR TO THE EVENT GIVING RISE TO
                THE CLAIM.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <p>Indemnification.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                You agree to indemnify, defend and hold harmless Company and its officers, directors, employees, agents,
                affiliates, successors and assigns from and against any and all losses, damages, liabilities,
                deficiencies, claims, actions, judgments, settlements, interest, awards, penalties, fines, costs, or
                expenses of whatever kind, including reasonable attorneys' fees, arising from or relating to Your misuse
                of the Platform or Your breach of this Agreement. Furthermore, You agree that Company assumes no
                responsibility for the Platform Data You submit or make available through the Platform.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <p>Compliance with Law.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                You shall use the Platform and Platform Data in full compliance with all applicable laws and
                regulations, including all laws and regulations applying to privacy and personal information.
              </p>
            </li>
            <li>
              <p>
                The Platform may be subject to U.S. export control laws, including the U.S. Export Administration Act
                and its associated regulations. You shall not, directly or indirectly, export, re-export or release the
                Platform to, or make the Platform accessible from, any jurisdiction or country to which export,
                re-export or release is prohibited by law, rule or regulation. You shall comply with all applicable
                federal laws, regulations and rules, and complete all required undertakings (including obtaining any
                necessary export license or other governmental approval), prior to exporting, re-exporting, releasing or
                otherwise making the Platform or any Application available outside the U.S.
              </p>
            </li>
            <li>
              <p>
                You represent and warrant that You and/or Your Authorized Users: (i) are not located in a country that
                is subject to a U.S. government embargo, or that has been designated by the U.S. government as a
                “terrorist supporting” country; and (ii) are not listed on any U.S. government list of prohibited or
                restricted parties.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <p>U.S. Government Rights.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                The Platform and Platform Data: (i) include commercial technical data and/or computer databases and/or
                commercial computer software and/or commercial computer software documentation, as applicable, which
                were developed exclusively at private expense by Command Alkon Incorporated, 1800 International Park
                Drive, Suite 400, Birmingham, Alabama 35243; (ii) are Company trade secrets for all purposes of the
                Freedom of Information Act; and (iii) are in all respects Company proprietary data and all rights are
                reserved under the copyright laws of the United States. The U.S. government’s rights to use, modify,
                reproduce, release, perform, display, or disclose these technical data and/or computer databases and/or
                computer software and/or computer software documentation are subject to the limited rights restrictions
                of DFARS 252.227-7015(b)(2) and/or subject to the restrictions of DFARS 227.7202-1(a) and DFARS
                227.7202-3(a) (June 2013), as applicable for U.S, Department of Defense procurements and the limited
                rights restrictions of FAR 52.227-14 (May 2014) and/or restricted rights provisions of FAR 52.227-14
                (May 2014) and FAR 52.227-19 (December 2007), as applicable, and any applicable agency FAR Supplements,
                for non-Department of Defense Federal procurements.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <p>Governing Law and Venue.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                (a) The interpretation of this Agreement shall be governed by any applicable federal law, and the laws
                of the State of Alabama, without giving effect to any choice or conflict of law provision or rule
                (whether in Alabama or any other jurisdiction) that would cause the application of the laws of any other
                jurisdiction. To the extent that the United Nations Convention on Contracts for the International Sale
                of Goods (the{' '}
                <q>
                  <b>Convention</b>
                </q>
                ) would be applicable to this Agreement or any other dealings or transactions between the parties that
                are the subject matter of the foregoing, the parties hereby expressly “opt out” of the Convention and
                expressly state that the Convention is inapplicable to this Agreement and the transactions between the
                parties that are the subject matter of the foregoing. Each of the parties hereto irrevocably submits to
                the exclusive jurisdiction of the state and federal courts located in Birmingham, Alabama for the
                purpose of any suit, action, proceeding or judgment relating to or arising out of this Agreement and the
                transactions contemplated hereby.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <b>
            <p>General Provisions.</p>
          </b>
          <ol type="a">
            <li>
              <p>
                If any provision of this Agreement shall be found to be illegal or unenforceable, then, notwithstanding
                such illegality or un-enforceability, this Agreement shall remain in full force and effect and such
                provision shall be deemed to be deleted. Furthermore, if possible to ascertain the intent of the
                parties, there shall be added a substitute provision as similar in substance as legally possible and the
                remainder of this Agreement shall not be affected.
              </p>
            </li>
            <li>
              <p>
                This Agreement and the policies referenced herein, along with any MLSA that exists between you and
                Company, if any, constitute the entire agreement between You and Company with respect to the Platform
                and supersede all prior or contemporaneous understandings and agreements, whether written or oral, with
                respect to the subject matter of this Agreement. For the avoidance of doubt, this Agreement is not
                intended to terminate or supersede any MLSA in place between You and Company. With respect to Your use
                of the Platform, in the event of a conflict between any provision of this Agreement and such MLSA, the
                provision of this Agreement shall prevail.
              </p>
            </li>
            <li>
              <p>
                Any failure or delay by either party in exercising any right or remedy will not constitute a waiver of
                such right or remedy.
              </p>
            </li>
            <li>
              <p>
                This Agreement shall be binding upon and inure to the benefit of the parties and their respective
                successors and assigns. You may not assign or transfer this Agreement, in whole or part, or any rights
                or obligations thereunder, without the prior written consent of Company. In the event of any attempted
                assignment or transfer by You in violation of this Section 20(d), such purported assignment or transfer
                shall be void and without force or effect. Company may assign its rights and obligations under this
                Agreement to any of its affiliates, or to an entity other than an affiliate, that acquires all or
                substantially all of the assets or stock of, merges or consolidates with or into, or acquires a
                controlling interest in it or any of its affiliates.
              </p>
            </li>
            <li>
              <p>
                Company shall not be liable to You for any delay, failure or inability to perform its obligations under
                this Agreement, or any losses arising in connection with the foregoing, due to any cause beyond its
                reasonable control, including utility failures, equipment breakdowns, fires, storms, accidents, acts of
                God, acts of war, acts of terrorism, labor shortages, telecommunications or Internet failures, or any
                act or omission by You. You acknowledge that there are risks inherent in maintaining the Subscriber Data
                in a remote server and software network (
                <q>
                  <b>Cloud-Based</b>
                </q>
                ) environment, including the risk of hacking or other unauthorized third-party access to the Subscriber
                Data, and We are not responsible to You for any unauthorized access to Subscriber Data or the
                unauthorized use of the Platform. You acknowledge that, in order to provide the Platform, Company will
                use networking and communication infrastructure and services that are not under Company’s control
                (collectively,{' '}
                <q>
                  <b>Infrastructure</b>
                </q>
                ) and will, from time to time, use the services of third-party providers and licensors (
                <q>
                  <b>Third-Party Providers</b>
                </q>
                ). Company’s ability to provide the Platform will depend on the provision and availability of such
                Infrastructure and/or services and support by a Third-Party Provider, and We are not responsible to You
                under this Agreement or any supplements thereto for any failure by Us that is directly or indirectly
                caused by the unavailability or performance of such Infrastructure or Thirty-Party Provider service or
                support. The services and licenses obtained by Us from any Third-Party Provider, and Your use thereof,
                may be restricted by the terms of such licenses and applicable agreements. We are not responsible to You
                hereunder for any failure arising from any actions or inactions of a Third-Party Provider.
              </p>
            </li>
            <li>
              <p>
                The section headings in this Agreement are for reference purposes only and shall not affect the meaning
                or interpretation of this Agreement. The words “include,” “includes,” and “including” shall be deemed to
                be followed by the phrase “without limitation.”
              </p>
            </li>
          </ol>
        </li>
      </ol>
      <b>
        <p>
          Should You have any questions concerning this Agreement, or if You desire to contact Company for any reason,
          please contact Company at:
        </p>
        <div>
          COMMAND ALKON INCORPORATED
          <br />
          Attn: Associate General Counsel
          <br />
          1800 International Park Drive, Suite 400
          <br />
          Birmingham, Alabama 35243
          <br />
          +1 (205) 879-3282
          <br />
          legal@commandalkon.com
          <br />
        </div>
      </b>
      <p>Last modified: August 04, 2020</p>
    </Styled>
  );
};

export default Privacy;
