import React, { useEffect } from 'react';
import { Localization } from 'connex-cds';
import { useNavigate } from 'react-router-dom';

import { LOGIN } from '../../../../constants/routes';
import { CheckmarkAnimation } from '../../../../commons';

import { StepFourContainer } from './styles';
import { Heading1 } from '../styles';

const StepFour = () => {
  const navigate = useNavigate();
  const translateMessage = Localization.useTranslateMessage();

  useEffect(() => {
    setTimeout(() => {
      navigate(LOGIN);
    }, 1500);
  }, [navigate]);

  return (
    <StepFourContainer>
      <Heading1>{translateMessage('accountRecovery_passwordChanged')}</Heading1>
      <CheckmarkAnimation className="lottie-animation" />
    </StepFourContainer>
  );
};

export default StepFour;
