import { useState } from 'react';
import { Localization } from 'connex-cds';
import DialogContent from '@mui/material/DialogContent';
import styled from '@emotion/styled';
import { Field, Form, Formik } from 'formik';
import { pick } from 'lodash';
import Button from '@mui/material/Button';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useUserContext } from '../../../../../../providers/UserProvider';

import SaveDialogActions from '../SaveDialogActions/SaveDialogActions';
import { PasswordInputWithFormikField } from '../../../../../../commons';
import { initialValues, validationSchema } from './form-config';
import auth from '../../../../../../api/auth';

import styles from './style';

const Styled = styled(DialogContent)`
  ${styles}
`;

const Credentials = ({ closeModal, onForgetMeClick }) => {
  const { user } = useUserContext();
  const [errorReq, setErrorReq] = useState(null);

  const handleChangePassword = async values => {
    setErrorReq(null);
    try {
      await auth.updatePassword({ profileRef: user.profileRef, data: pick(values, ['oldPassword', 'newPassword']) });
      closeModal();
    } catch (error) {
      setErrorReq('manageProfile_credentials_wrongOldPassword');
    }
  };

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleChangePassword}>
      {({ handleSubmit, dirty, errors, isSubmitting, isValid }) => (
        <>
          <Styled className="profile-credentials" dividers>
            <h3>
              <Localization.Translate stringId="manageProfile_credentials" />
            </h3>
            <p className="credentials-description">
              <Localization.Translate stringId="manageProfile_credentials_description" />
            </p>
            <ul>
              <li>
                <Localization.Translate stringId="manageProfile_credentials_reqLength" />
              </li>
              <li>
                <Localization.Translate stringId="manageProfile_credentials_reqNumber" />
              </li>
              <li>
                <Localization.Translate stringId="manageProfile_credentials_reqLowerCase" />
              </li>
              <li>
                <Localization.Translate stringId="manageProfile_credentials_reqUpperCase" />
              </li>
              <li>
                <Localization.Translate stringId="manageProfile_credentials_reqSpecialCharacter" />
              </li>
            </ul>

            <Form className="credentials-form" onSubmit={e => e.preventDefault()}>
              <Field
                required
                component={PasswordInputWithFormikField}
                error={!!errors.oldPassword || errorReq}
                helperText={
                  !!errors.oldPassword || errorReq ? (
                    <Localization.Translate stringId={errors.oldPassword || errorReq} />
                  ) : null
                }
                testId="credentials-oldPassword"
                labelStringId="manageProfile_credentials_oldPassword"
                variant="outlined"
                name="oldPassword"
              />
              <Field
                required
                component={PasswordInputWithFormikField}
                testId="credentials-newPassword"
                labelStringId="manageProfile_credentials_newPassword"
                variant="outlined"
                name="newPassword"
              />
              <Field
                required
                component={PasswordInputWithFormikField}
                testId="credentials-confirmNewPassword"
                labelStringId="manageProfile_credentials_confirmNewPassword"
                variant="outlined"
                name="confirmNewPassword"
              />
            </Form>
            <Button variant="outlined" className="forget-me" onClick={onForgetMeClick}>
              <RemoveCircleOutlineIcon />
              <Localization.Translate stringId="forgetMe" />
            </Button>
          </Styled>
          <SaveDialogActions
            closeModal={closeModal}
            handleSubmit={handleSubmit}
            disabledSaveButton={!dirty || isSubmitting || !isValid}
          />
        </>
      )}
    </Formik>
  );
};

export default Credentials;
