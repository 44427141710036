import { css } from '@emotion/react';

export const GlobalStyle = () => css`
  html,
  body,
  #root {
    overflow: hidden;
    height: 100vh;
    width: 100vw;
  }

  * {
    font-family: 'Work Sans', serif;
  }
`;
