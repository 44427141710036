import { http } from 'connex-cds';

export default {
  updateUserProfile: ({ profileRef, profile }) =>
    http.patch({ apiName: 'authnz', path: `/profiles/${profileRef}`, data: profile }),
  getUserProfile: profileRef => http.get({ apiName: 'authnz', path: `/profiles/${profileRef}?expand=profile-image` }),
  uploadProfileImage: ({ profileRef, data }) =>
    http.post({ apiName: 'authnz', path: `/profiles/${profileRef}/image`, data }),
  deleteProfileImage: ({ profileRef, data }) =>
    http.delete({ apiName: 'authnz', path: `/profiles/${profileRef}/image` }),
  updateProfileImage: ({ profileRef, data }) =>
    http.patch({ apiName: 'authnz', path: `/profiles/${profileRef}/image`, data }),
};
