import { css } from '@emotion/react';

export default ({ theme }) => css`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .container {
    .landing-title {
      color: ${theme.colorsCA.neutral[100]};
      font-size: 32px;
      font-weight: 600;
      line-height: 36px;
      margin: 0;
    }

    .landing-description {
      color: ${theme.colorsCA.neutral[100]};
      font-size: 28px;
      font-weight: 400;
      line-height: 36px;
      margin: 0;
    }

    .apps-container {
      display: flex;
      flex-wrap: wrap;
      width: 470px;
      overflow: auto;
      justify-content: flex-start;
      align-items: center;
      gap: 30px;
      margin: 0;
      padding: 0 20px;
      list-style: none;

      ::-webkit-scrollbar-track {
        border-radius: 100px;
        background: rgba(28, 27, 31, 0.25);
        backdrop-filter: blur(40px);
      }
      ::-webkit-scrollbar {
        width: 7px;
        border-radius: 100px;
        background: rgba(28, 27, 31, 0.25);
        backdrop-filter: blur(40px);
      }
      ::-webkit-scrollbar-thumb {
        width: 7px;
        border-radius: 100px;
        background: rgba(110, 159, 189, 0.75);
        backdrop-filter: blur(40px);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
    }

    .app-icon {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
      cursor: pointer;
      user-select: none;

      color: ${theme.colorsCA.neutral[95]};

      width: 120px;
      height: 120px;

      border: none;
      background: none;
      box-shadow: none;
      border-radius: 0;

      &:hover {
        opacity: 0.6;
      }

      img {
        width: 120px;
      }

      .app-name-default {
        position: absolute;
        top: 75px;
        line-height: 16px;
        color: white;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
      }
    }

    .lottie-animation {
      height: 200px;
    }
  }
`;
