import { useState, useEffect, useCallback } from 'react';

export const useCountdownTimer = (initialSeconds, onCountdownEnd) => {
  const [seconds, setSeconds] = useState(initialSeconds);
  const [isActive, setIsActive] = useState(false);

  const startTimer = () => {
    setIsActive(true);
  };

  const resetTimer = useCallback(() => {
    setIsActive(false);
    setSeconds(initialSeconds);
  }, [initialSeconds]);

  useEffect(() => {
    let interval;

    if (isActive) {
      if (seconds > 0) {
        interval = setInterval(() => {
          setSeconds(prevSeconds => prevSeconds - 1);
        }, 1000);
      } else {
        setIsActive(false);
        resetTimer();
        if (onCountdownEnd) {
          onCountdownEnd();
        }
      }
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isActive, seconds, onCountdownEnd, resetTimer]);

  return {
    seconds,
    isActive,
    startTimer,
    resetTimer,
  };
};
