import * as yup from 'yup';

export const initialValues = {
  password: '',
};

export const validationSchema = yup.object().shape({
  password: yup
    .string()
    .password()
    .min(8, 'accountRecovery_errorPassword')
    .max(99, 'accountRecovery_errorPassword')
    .minLowercase(1, 'accountRecovery_errorPassword')
    .minUppercase(1, 'accountRecovery_errorPassword')
    .minNumbers(1, 'accountRecovery_errorPassword')
    .minSymbols(1, 'accountRecovery_errorPassword')
    .nullable()
    .required('accountRecovery_errorPassword'),
});
