import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styled from '@emotion/styled';

import iconNames from './iconList';

import IconStyles from './styles';

const Icon = ({ iconName, classNameProp, ...props }) => {
  const IconComponent = iconNames[iconName] || iconNames.default;

  return <IconComponent className={cn('icon', classNameProp)} {...props} />;
};

const Styled = styled(Icon)`
  ${IconStyles}
`;

Icon.propTypes = {
  iconName: PropTypes.string,
  classNameProp: PropTypes.string,
  onClick: PropTypes.func,
};

export default Styled;
