import cn from 'classnames';
import { Core, Drawer, Form, Localization, useAppContext } from 'connex-cds';
import { pick } from 'lodash';
import React from 'react';
import styled from 'styled-components';
import { useProfile, useUpdateProfile } from '../../../queries/user-profile';
import { ProfileForm } from './ProfileForm';
import style from './style';
import config from './form-config';

const Styled = styled.div`
  ${style}
`;

export const Profile = () => {
  const { setCurrentFeature } = useAppContext();

  const profile = useProfile();
  const { mutateAsync: updateProfile } = useUpdateProfile();

  React.useEffect(() => {
    setTimeout(() => {
      setCurrentFeature({
        id: 'settings',
        labelStringId: 'settings',
        path: '/settings',
        icon: 'fa-light fa-gear',
        label: <Localization.Translate stringId="settings" data-testid="settings-label" />,
      });
    }, 250);
  }, [setCurrentFeature]);

  const handleSubmit = React.useCallback(
    async values => {
      return await updateProfile(values);
    },
    [updateProfile]
  );

  const initialValues = React.useMemo(() => {
    if (profile.data) {
      return pick(profile.data, ['firstName', 'lastName', 'email', 'phone', 'settings']);
    }
  }, [profile.data]);

  return (
    <Styled className={cn('profile')}>
      <Core.Spinner spin={profile.isLoading}>
        <Drawer.DrawerProvider>
          <Form.FormProvider config={config} initialValues={initialValues} onSubmit={handleSubmit}>
            <ProfileForm />
          </Form.FormProvider>
        </Drawer.DrawerProvider>
      </Core.Spinner>
    </Styled>
  );
};
