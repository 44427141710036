export const PHONE_REGEX = /^(\(\d{3}\) \d{3}-\d{4})$/;

export const sanitizePhoneNumber = value => value.replace(/\D/g, '').slice(0, 10);

export const maskPhoneNumber = value => {
  const [areaCode, ...phoneNumber] = value.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/).slice(1);
  const phoneNumberGroups = phoneNumber.filter(Boolean);
  if (!phoneNumberGroups.length) return areaCode;
  return `(${areaCode}) ${phoneNumberGroups.join('-')}`;
};
