import React from 'react';
import cn from 'classnames';
import { Global } from '@emotion/react';
import { API, getCachedItem, Layout, Localization, PostMessageProvider, fullStoryInit } from 'connex-cds';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import styled from '@emotion/styled';

import { ToastContainer } from './components/Toast';
import messages from './i18n';
import MuiThemeProvider from './mui-theme/ThemeProvider';
import { AppProvider } from './providers/AppProvider';
import { AuthProvider } from './providers/AuthProvider';
import EntityProvider from './providers/entity-provider/EntityProvider';
import { ThemeProvider } from './providers/ThemeProvider';
import { UserProvider } from './providers/UserProvider';
import { BaseRoutes } from './routes/BaseRoutes';

import pkg from '../package.json';
import { GlobalStyle } from './util/GlobalStyle';

import styles from './styles';

API.setSource('connex');
API.setApiKey(getCachedItem('apiKey'), '');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => error?.status < 400 || error?.status >= 500,
      refetchOnWindowFocus: false,
    },
  },
});

const Styled = styled(Layout.Column)`
  ${styles}
`;

fullStoryInit({ runInIframe: false });

const App = () => {
  return (
    <Styled className={cn('cds-root-container', `v-${pkg.version}`)}>
      <Global styles={GlobalStyle} />
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <PostMessageProvider>
            <Localization.Localization
              messages={messages}
              initialLocale={getCachedItem('language')}
              initialTimeZone={getCachedItem('timeZone')}
              appId="connex"
            >
              <ThemeProvider>
                <UserProvider>
                  <AuthProvider>
                    <AppProvider>
                      <EntityProvider>
                        <MuiThemeProvider>
                          <BaseRoutes />
                          <ToastContainer duration={1000} />
                        </MuiThemeProvider>
                      </EntityProvider>
                    </AppProvider>
                  </AuthProvider>
                </UserProvider>
              </ThemeProvider>
            </Localization.Localization>
          </PostMessageProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </Styled>
  );
};

export default App;
