import * as yup from 'yup';

export const initialValues = {
  code: '',
};

export const validationSchema = yup.object().shape({
  code: yup
    .string()
    .matches(/^[0-9]{6}$/, {
      message: 'accountRecovery_errorCode',
      excludeEmptyString: true,
    })
    .nullable()
    .required('accountRecovery_errorCode'),
});
