import React, { useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Core } from 'connex-cds';
import styled from '@emotion/styled';

import { useAuthContext } from '../../../providers/AuthProvider';
import NewLoginForm from './NewLoginForm';
import styles from './styles';

const { Alert, SEVERITY } = Core;

const Styled = styled.div`
  ${styles}
`;

const Login = () => {
  const [hasFormError, setHasFormError] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { login } = useAuthContext();

  const handleLogin = useCallback(
    async (values, _actions) => {
      const response = await login({ credentials: { ...values } });

      if (response) {
        navigate('/', { replace: true, state: location.state });
      } else {
        setHasFormError(true);
      }
    },
    [location.state, login, navigate]
  );

  return (
    <Styled className="login-wrapper">
      {location?.state?.message?.stringId ? (
        <Alert
          severity={SEVERITY.SUCCESS}
          title={location.state.message?.noTitle ? undefined : 'success'}
          message={location.state.message.stringId}
          closable
          showIcon
        />
      ) : null}
      <NewLoginForm handleLogin={handleLogin} hasFormError={hasFormError} />
    </Styled>
  );
};

export default Login;
