import { css } from '@emotion/react';
import styled from '@emotion/styled';

const styles = ({ theme }) => css`
  .landing-title {
    margin-bottom: 50px;
  }

  .landing-description {
    margin-bottom: 60px;
  }

  .action-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 22px;
  }
`;

export const Styled = styled.div`
  ${styles}
`;
