import * as yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(yup);

yup.addMethod(yup.string, 'email', function validateEmail(message) {
  return this.matches(/^[\w-.]+([\w-.+])+@([\w-]+\.)+[a-zA-Z]{2,4}$/g, {
    message,
    name: 'email',
    excludeEmptyString: true,
  });
});

export const validationSchema = yup.object().shape({
  username: yup.string().email('usernameInvalid').nullable().required('required'),
  password: yup
    .string()
    .password()
    .min(8, 'passwordMinCharacters')
    .max(99, 'passwordMaxCharacters')
    .minLowercase(1, 'passwordLowerCase')
    .minUppercase(1, 'passwordUpperCase')
    .minNumbers(1, 'passwordNumber')
    .minSymbols(1, 'passwordSpecialCharacter')
    .nullable()
    .required('required'),
});

/**
 * In the meantime keep null values to avoid label shrink bug. More research needed
 * to dispatch input events when browser auto fills the input
 * Trade off: react-dom warning.
 */
export const initialValues = { username: '', password: '' };
