import { css } from '@emotion/react';

export default () => css`
  transition: all 0.3s;
  background-color: var(--color-background-page);
  color: var(--color-text-page);
  font-family: 'Work Sans', serif;
  height: 100vh;
  width: 100%;
  gap: 0;
`;
